import React from "react"
import {ThemeProvider} from '@emotion/react';
import {ThemeUnitag} from "../themes/unitagTheme";
import {SnackbarProvider} from "notistack";


export default function UIProvider(props: any) {

    return (
        <>
            <ThemeProvider theme={ThemeUnitag}>
                <SnackbarProvider>
                    {props.children}
                </SnackbarProvider>
            </ThemeProvider>
        </>
    )
}
const styles = {
    paper: {
        p: 2
    },
    buttonGrid: {
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center"
    }
}

export {styles}
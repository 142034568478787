import React from "react"
// MUI
import {Button, Paper, TextField, Grid, MenuItem} from "@mui/material";
import {Add, AddCircleOutlined, Clear, Delete, PersonAdd, PersonOff} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Components
import {InteractiveAdornment, Select} from "../../utils";
// Regexes
import {regexEmail, regexPhoneNumber} from "../../../functions/functions";

function PersonForm(props: any) {

    const {
        sections,
        setSections,
        t,
        handleDeletePerson,
        // INDEXES
        si, pi,
        // vcard products
        usingProducts,
    } = props

    const styles = {
        personPaper: {
            p: 2,
            my: 2
        }
    }

    const [person, setPerson] = React.useState(props.person)

    React.useEffect(() => {
        if (person && (person !== props.person)) {
            handlePersonChange(si, pi)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [person])

    React.useEffect(() => {
        if (props.person && (props.person !== person)) {
            setPerson(props.person)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handlePersonChange = (sectionIndex: number, personIndex: number) => {
        let _persons = sections[sectionIndex].profiles
        _persons.splice(personIndex, 1, person)

        let _sections = [...sections]
        _sections.splice(si, 1, { name: _sections[sectionIndex].name, profiles: _persons })
        setSections([..._sections])
    }

    return (
        <Paper variant={"outlined"} sx={styles.personPaper}>
            <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={12} textAlign={"right"}>
                    <Button
                        onClick={handleDeletePerson}
                        color={"error"}
                        size={"small"}
                        startIcon={usingProducts ? <Clear /> : <PersonOff />}
                    >
                        {usingProducts ? t("sub_section_delete") : t("helper_delete")}
                    </Button>
                </Grid>

                <Grid item xs={12} md={4} display={usingProducts ? "none" : "inline-flex"}>
                        <Select
                            value={person.title}
                            label={t("gender")}
                            onChange={(e: any) => setPerson({...person, title: e.target.value})}
                        >
                            <MenuItem value={"none"}>{t("no_display")}</MenuItem>
                            <MenuItem value={"mr"}>{t("mister")}</MenuItem>
                            <MenuItem value={"ms"}>{t("miss")}</MenuItem>
                        </Select>
                </Grid>
                <Grid item xs={12} md={usingProducts ? 6 : 4}>
                    <TextField
                        fullWidth
                        label={usingProducts ? t("common:title") : t("name")}
                        value={person.firstName}
                        onChange={(e) => setPerson({...person, firstName: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} md={4} display={usingProducts ? "none" : "inline-flex"}>
                    <TextField
                        fullWidth
                        label={t("last_name")}
                        value={person.lastName}
                        onChange={(e) => setPerson({...person, lastName: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} md={usingProducts ? 6 : 4}>
                    <TextField
                        fullWidth
                        label={usingProducts ? t("subtitle") : t("position")}
                        value={person.jobTitle}
                        onChange={(e) => setPerson({...person, jobTitle: e.target.value})}
                    />
                </Grid>

                {
                    usingProducts &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label={t("common:description")}
                            value={person.description}
                            onChange={(e) => setPerson({...person, description: e.target.value})}
                        />
                    </Grid>
                }

                <Grid item xs={12} md={usingProducts ? 6 : 4}>
                    <TextField
                        fullWidth
                        label={t("phone")}
                        value={person.phone}
                        onChange={(e) => setPerson({...person, phone: e.target.value})}
                        InputProps={{
                                endAdornment: <InteractiveAdornment />
                        }}
                        error={!regexPhoneNumber(person.phone)}
                    />
                </Grid>
                <Grid item xs={12} md={usingProducts ? 6 : 4}>
                    <TextField
                        fullWidth
                        label={t("email")}
                        value={person.email}
                        onChange={(e) => setPerson({...person, email: e.target.value})}
                        error={!regexEmail(person.email)}
                        InputProps={{
                                endAdornment: <InteractiveAdornment />
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={t("address_simple")}
                        value={person.address}
                        onChange={(e) => setPerson({...person, address: e.target.value})}
                        InputProps={{
                            endAdornment: <InteractiveAdornment />
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={t("website")}
                        value={person.website}
                        onChange={(e) => setPerson({...person, website: e.target.value})}
                        InputProps={{
                            endAdornment: <InteractiveAdornment />
                        }}
                    />
                </Grid>
                {
                    usingProducts &&
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={t("link")}
                                value={person.link}
                                onChange={(e) => setPerson({...person, link: e.target.value})}
                                InputProps={{
                                    endAdornment: <InteractiveAdornment />
                                }}
                            />
                        </Grid>
                }

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t("end_text")}
                        value={person.closingText}
                        onChange={(e) => setPerson({...person, closingText: e.target.value})}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default function CustomHelpersSection(props: any) {

    const {data, setData, usingProducts} = props
    const {t} = useTranslation(["templateEditor", "vcardEditor", "common"])

    const styles = {
        sectionPaper: {
            backgroundColor: "#efefef",
            p: 2,
            mt: 2
        }
    }

    const [sections, setSections] = React.useState<any[]>(data.sections)

    React.useEffect(() => {
        if (sections && (sections !== data.sections)) {
            setData({...data, sections: sections})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections])

    React.useEffect(() => {
        if (data.sections && (data.sections !== sections)) {
            setSections(data.sections)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleAddSection = () => {
        if (!sections){
            setSections([{ name: "", profiles: [] }])
        } else {
            setSections((current: any[]) => [...current, { name: "", profiles: [] }])
        }
    }

    const handleDeleteSection = (section: number) => {
        let _sections = sections
        _sections.splice(section, 1)
        setSections([..._sections])
    }

    const handleSectionName = (index: number, event: any) => {
        let _sections = sections
        _sections.splice(index, 1, { name: event.target.value, profiles: _sections[index].profiles })
        setSections([..._sections])
    }

    const handleAddPerson = (index: number) => {
        let _sections = sections
        _sections.splice(index, 1, {
            name: _sections[index].name,
            profiles: [..._sections[index].profiles, {
                firstName: "",
                lastName: "",
                title: "none",
                phone: "",
                email: "",
                jobTitle: "",
                description: "",
                link: "",
                closingText: "",
                address: "",
                website: ""
            }]
        })
        setSections([..._sections])
    }

    const handleDeletePerson = (si: number, pi: number) => {
        let _persons = sections[si].profiles
        _persons.splice(pi, 1)

        let _sections = sections
        _sections.splice(si, 1, {
            name: _sections[si].name,
            profiles: [..._persons]
        })

        setSections([..._sections])
    }

    return (
        <>
            <Button
                onClick={() => handleAddSection()}
                variant={"contained"}
                size={"small"}
                color={"secondary"}
                disableElevation
                startIcon={<Add />}
            >
                {t("section_add")}
            </Button>

            {
                sections && sections.map((section: any, si: number) => {

                    return (
                        <Paper variant={"outlined"} key={si} sx={styles.sectionPaper}>
                            <Grid container justifyContent={{ xs: "center", md: "space-between" }} spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label={t("section_name")}
                                        required
                                        error={(!section.name && !usingProducts)}
                                        value={section.name}
                                        onChange={(e) => handleSectionName(si, e)}
                                    />
                                </Grid>
                                <Grid item flexDirection={"column"} justifyContent={"center"} alignContent={"center"} display={"flex"}>
                                    <div>
                                        <Button
                                            onClick={() => handleAddPerson(si)}
                                            size={"small"}
                                            variant={"contained"}
                                            disableElevation
                                            color={"secondary"}
                                            startIcon={usingProducts ? <AddCircleOutlined /> : <PersonAdd />}
                                        >
                                            {usingProducts ? t("sub_section_add") : t("helper_add")}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button
                                            onClick={() => handleDeleteSection(si)}
                                            size={"small"}
                                            color={"error"}
                                            variant={"contained"}
                                            disableElevation
                                            startIcon={<Delete />}
                                        >
                                            {t("section_delete")}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                            {
                                section.profiles && section.profiles.map((person: any, pi: number) => (
                                    <PersonForm
                                        key={pi}
                                        {...{sections, setSections, si, pi, person, usingProducts, t}}
                                        handleDeletePerson={() => handleDeletePerson(si, pi)}
                                    />
                                ))
                            }

                        </Paper>
                    )
                })
            }
        </>
    )
}
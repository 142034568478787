export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function iso8601ToHumanString(dateRaw: string, gmt?: boolean): string {
    const date: Date = new Date(dateRaw);

    let days: number = date.getDate(),
        months: number = date.getMonth() + 1;

    let strDays: string = String(days);
    if (days < 10) {
        strDays = "0" + days;
    }

    let strMonths: string = String(months);
    if (months < 10) {
        strMonths = "0" + months;
    }

    if(gmt) {
        return `${strDays}/${strMonths}/${date.getFullYear()}, 00:00`;
    }

    return `${strDays}/${strMonths}/${date.getFullYear()}`;
}

export function regexEmail(text: any) {
    if (text && text !== "") {
        // includes non-utf8 characters suppor
        // eslint-disable-next-line no-useless-escape
        let reg = new RegExp(/(.+)@(.+){2,}\.(.+){2,}/)
        return reg.test(text);
    }
    return true
}

export function regexValidUrl(text: any) {
    if (text && text !== "") {
        let reg = new RegExp("^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\.-]+)+[\\w\\-\._~:/?%#[\\]@!\$&'\(\)\*\+,;=]+$")
        return reg.test(text)
    }
    return true
}

export function regexPhoneNumber(number: any) {
    if (number && number !== "") {
        let reg = new RegExp("^[+]?[(]?[0-9]{3}[)]?[-\\s\.]?[0-9]{3}[-\\s\.]?[0-9]{4,6}$")
        return reg.test(number)
    }
    return true
}

export function isYoutubeUrl(url: any) {
    const youtubePublic: string = "/watch?"
    const youtubeEmbed: string = "https://youtube.com/embed/"
    const youtubeShare: string = "https://youtu.be/"

    if (url && url !== "") {
        return !!(url.includes(youtubePublic) || url.includes(youtubeEmbed) || url.includes(youtubeShare));
    }

    return false
}

export function parseYoutubeVideoUrl(url: any) {

    let youtubeRawUrl: any = null
    let youtubeEmbeddingCode: any = null

    const youtubePublic: string = "/watch?"
    const youtubeEmbed: string = "https://youtube.com/embed/"
    const youtubeShare: string = "https://youtu.be/"

    if (url && url !== "") {
        youtubeRawUrl = url

        if (url.includes(youtubePublic)) {
            youtubeEmbeddingCode = youtubeRawUrl.split("/watch?v=").pop().split("&")[0]
        } else if (url.includes(youtubeEmbed)) {
            youtubeEmbeddingCode = youtubeRawUrl.split(youtubeEmbed).pop().split("&")[0]
        } else if (url.includes(youtubeShare)) {
            youtubeEmbeddingCode = youtubeRawUrl.split(youtubeShare).pop().split("&")[0]
        }
    }

    return youtubeEmbeddingCode
}
import React from "react"
import {useModule} from "../../../provider/ClientModuleProvider";
import {IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import {ViewModuleRounded} from "@mui/icons-material";
import {useUser} from "../../../provider/UserProvider";

export default function DevMenu() {

    const {usingProducts, setUsingProducts} = useModule()
    const {credits, setCredits} = useUser()

    const [devMenuAnchor, setDevMenuAnchor] = React.useState<null | HTMLElement>(null);

    const allowed = process.env.REACT_APP_ENV !== "production"

    if (!allowed) return <></>

    const getStyles = (active: any) => {
        return {
            backgroundColor: active ? "rgba(175,201,40,0.47)" : "initial",
            "&:hover": { backgroundColor: active ? "rgba(175,201,40,0.71)" : "rgba(0,0,0,0.04)" }
        }
    }

    const handleBusinessCreditsSwitch = () => {
        if (credits.credits_left > 0) {
            setCredits({
                credits_left: 99,
                credits_bought: 99,
                credits_used: 99,
                service_name: "vcards"
            })
        } else {
            setCredits({
                credits_left: 0,
                credits_bought: 0,
                credits_used: 0,
                service_name: "vcards"
            })
        }
    }

    const moduleDevMenu = () => (
        <Menu
            anchorEl={devMenuAnchor}
            open={Boolean(devMenuAnchor)}
            onClose={() => setDevMenuAnchor(null)}
            MenuListProps={{
                dense: true
            }}
        >
            <MenuItem onClick={() => console.log(credits)}>
            log
            </MenuItem>
            <MenuItem onClick={() => handleBusinessCreditsSwitch()}>
                Toggle business credits
            </MenuItem>
            <MenuItem sx={() => getStyles(usingProducts)} onClick={() => setUsingProducts(!usingProducts)}>
                vCard Products
            </MenuItem>
        </Menu>
    )

    return (
        <>
            <Tooltip title={"Manage modules (dev)"} placement={"top"} arrow>
                <IconButton sx={{ color: "black" }} onClick={(e) => setDevMenuAnchor(e.currentTarget)}>
                    <ViewModuleRounded />
                </IconButton>
            </Tooltip>
            {moduleDevMenu()}
        </>
    )
}
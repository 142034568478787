import React from 'react'
import {Button, Menu} from "@mui/material"
import {ChromePicker, ColorResult} from "react-color"

const ColorPicker = (props: any) =>  {

    const { label, initialColor, disabled, onChangeComplete, enableAlpha } = props;

    const [color, setColor] = React.useState<any>(initialColor);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    // Used to make PrimaryColor match in both Accordion and avoiding false color displays
    React.useEffect(() => {
        setColor(initialColor);
        return;
    }, [initialColor])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        onChangeComplete(color);
    };

    return (
        <>
            <Button
                fullWidth
                variant="contained"
                size="small"
                disableElevation
                onClick={handleClick}
                disabled={disabled}
                sx={{
                    backgroundColor: disabled ? "#c8c8c8" : color,
                    color: color === "#ffffff" ? "#000000" : "#ffffff",
                    border: "1px solid",
                    borderColor: color === "#ffffff" ? "#000000" : "transparent",
                    transition: "0.15s ease-in-out",
                    "&:hover": {
                        backgroundColor: disabled ? "#c8c8c8" : color,
                        filter: "brightness(0.95)"
                    }
                }}
            >
                {color ? color : label}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: { backgroundColor: "transparent", boxShadow: "none" }
                    }
                }}
            >
                <ChromePicker
                    disableAlpha={!enableAlpha}
                    color={color}
                    onChange={(color: ColorResult) => setColor(color.hex)}
                />
            </Menu>
        </>
    )
}

export default ColorPicker
import React, {createContext, useContext, useEffect, useState} from "react"
import {useUser} from "./UserProvider";

const TourContext = createContext<any>(null)

export const useTour = () => useContext(TourContext)

export function TourProvider({ children }: any) {

    const {credits} = useUser()

    const [onboardingTour, setOnboardingTour] = useState<number | null>(null)

    useEffect(() => {
        if (!!credits && credits.credits_used === 0) {
            setOnboardingTour(1)
        } else if (!!credits && credits.credits_used !== 0) setOnboardingTour(null)
    }, [credits]);

    return (
        <TourContext.Provider value={{
            onboardingTour
        }}>
            {children}
        </TourContext.Provider>
    )
}
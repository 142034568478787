import React from "react"
// mui
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {QrCode2} from "@mui/icons-material";

export default function MyQrCodeSection(props: any) {

    const {styles, t, noMargin, bottomButtons, template, qrcodeText} = props;

    if (bottomButtons) {
        return (
            <Paper sx={{...styles.paper, mt: noMargin ? 0 : 2 }}>
                <Grid container columnSpacing={0} rowSpacing={2} justifyContent={"center"}>
                    <Grid
                        item
                        xs={(template.buttons.displaySendContactButton || template.buttons.displaySendContactButton === null) ? 6 : 12}
                        display={(template.buttons.displayAddMe || template.buttons.displayAddMe === null) ? "inline-flex" : "none"}
                    >
                        <Button sx={{...styles.bottomButtons, ...styles.addMeButton}}>
                            {t("vcard_add_me")}
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={(template.buttons.displayAddMe || template.buttons.displayAddMe === null) ? 6 : 12}
                        display={(template.buttons.displaySendContactButton || template.buttons.displaySendContactButton === null) ? "inline-flex" : "none"}
                    >
                        <Button sx={{...styles.bottomButtons, ...styles.contactButton}}>
                            {t("vcard_contact_me")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button sx={{...styles.bottomButtons, ...styles.qrcodeButton}} startIcon={<QrCode2 />}>
                            {qrcodeText ? qrcodeText : t("vcard_my_qrcode")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    } else {
        return (
            <Paper sx={{...styles.paper, mt: noMargin ? 0 : 2 }}>
                <Typography component={"h3"} align={"center"} sx={styles.nameBottom} gutterBottom>
                    {qrcodeText ? qrcodeText : t("vcard_my_qrcode")}
                </Typography>
                <Box sx={styles.alignButtonCenter}>
                    <Button sx={styles.buttons}>
                        {t("vcard_show_qrcode")}
                    </Button>
                </Box>
            </Paper>
        )
    }


}
import React from "react"
import {AttachFile, BorderColor, BusinessCenter, LaptopMac, People, Person, YouTube, Info} from "@mui/icons-material";
import {Button, Collapse, Divider, Grid, Typography} from "@mui/material";

export default function SectionCollapse(props: any) {

    const {styles, t, plain, defaultExpanded, templateType} = props;
    const [expanded, setExpanded] = React.useState<boolean>(!!defaultExpanded)

    const returnSectionIcon = () => {

        const iconProps = {
            sx: styles.sectionIcons
        }

        switch (props.type) {
            case "biography":
                if (templateType === "PRODUCT") return null
                return <BorderColor {...iconProps} />;
            case "personal":
                if (templateType === "PRODUCT") return <Info {...iconProps} />
                return <Person {...iconProps} />;
            case "office": return <LaptopMac {...iconProps} />;
            case "business": return <BusinessCenter {...iconProps} />;
            case "media": return <AttachFile {...iconProps} />;
            case "video": return <YouTube {...iconProps} />;
            case "helper":
                if (templateType === "PRODUCT") return null
                return <People {...iconProps} />;
            default: return null
        }
    }

    if (plain) {
        return (
            <>
                <Grid container justifyContent={"center"} rowSpacing={1}>
                    <Grid item xs={12} sm={12} sx={styles.centeredDisplay}>
                        <>
                            <Typography component={"h3"} sx={styles.sectionTitles} align={"center"}>
                                {returnSectionIcon()}
                                {props.title}
                            </Typography>
                        </>
                    </Grid>
                </Grid>
                <Collapse in>
                    <Divider sx={styles.sectionCollapseDivider} />
                    {props.children}
                </Collapse>
            </>
        )
    }

    return (
        <>
            <Grid container justifyContent={"center"} rowSpacing={1}>
                <Grid item xs={12} sm={12} sx={styles.centeredDisplay}>
                    <>
                        <Typography component={"h3"} sx={styles.sectionTitles} align={"center"}>
                            {returnSectionIcon()}
                            {props.title}
                        </Typography>
                    </>
                </Grid>
                <Grid item xs={"auto"} sx={styles.centeredDisplay}>
                    <Button
                        onClick={() => setExpanded(!expanded)}
                        sx={styles.buttons}
                    >
                        {expanded ? t("close_section") : t("expand")}
                    </Button>
                </Grid>
            </Grid>
            <Collapse in={expanded}>
                <Divider sx={styles.sectionCollapseDivider} />
                {props.children}
            </Collapse>
        </>
    )
}
import React from "react"
import {useLocation} from "react-router-dom";
// Translation
import {useTranslation} from "react-i18next";
// MUI
import {Alert, AlertColor, Snackbar as MaterialSnack} from "@mui/material";

export default function Snackbar() {

    const {search} = useLocation()
    const {t} = useTranslation(['common'])
    const [snackbarState, setSnackbarState] = React.useState<{
        open: boolean,
        message: any,
        severity: AlertColor | undefined
    }>({
        open: false,
        message: "",
        severity: "success"
    })

    React.useEffect(() => {
        checkUrlQuery()
    }, [search])

    const checkUrlQuery = () => {
        if (search) {
            let query = new URLSearchParams(search)
            let _message = query.get('snackbar')
            if (_message) {
                switch(_message) {
                    // Template
                    case "template-created": setSnackbarState({ open: true, severity: "success", message: t("sb_template_created")}); break;
                    case "template-saved": setSnackbarState({ open: true, severity: "info", message: t("sb_template_saved")}); break;
                    // case "template-deleted": setSnackbarState({ open: true, severity: "error", message: t("sb_template_deleted")}); break;
                    // Vcard
                    case "vcard-created": setSnackbarState({ open: true, severity: "success", message: t("sb_vcard_created")}); break;
                    case "vcard-saved": setSnackbarState({ open: true, severity: "info", message: t("sb_vcard_saved")}); break;
                    // case "vcard-deleted": setSnackbarState({ open: true, severity: "error", message: t("sb_vcard_deleted")}); break;
                    // Contact
                    case "contact-completed": setSnackbarState({ open: true, severity: "success", message: t("sb_contact_completed")}); break;
                    case "contact-saved": setSnackbarState({ open: true, severity: "success", message: t("sb_contact_saved")}); break;
                    // case "contact-removed": setSnackbarState({ open: true, severity: "error", message: t("sb_contact_removed")}); break;
                    default: return;
                }
            }
        }
    }

    return (
        <>
            <MaterialSnack
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={() => setSnackbarState({ ...snackbarState, open: false })}
            >
                <Alert
                    onClose={() => setSnackbarState({ ...snackbarState, open: false })}
                    severity={snackbarState.severity}
                >
                    {snackbarState.message}
                </Alert>
            </MaterialSnack>
        </>
    )
}
import React from "react"
import {InputAdornment, Tooltip} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function InteractiveAdornment() {

    const {t} = useTranslation(["common"])

    return (
        <InputAdornment position={"end"}>
            <Tooltip title={t("interactive")} placement={"top"} arrow>
                <InfoOutlined sx={{ cursor: "default" }} />
            </Tooltip>
        </InputAdornment>
    )
}
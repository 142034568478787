import React from "react"
// MUI
import {
    Box, Divider,
    Grid,
    Typography,
} from "@mui/material";
import {AttachFile, Language} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Css
import {styles} from "./styles"
// components
import {Accordion, TextField} from "../../utils";
import {LegacyCustomMediasSection, LegacySocialMediaSection} from "../../Forms";

export default function SocialMediaPanel(props: any) {

    const {vcard, setVcard, permissions, userType, userRoles} = props;
    const {t} = useTranslation(["vcardEditor", "common"])
    // Accordions management
    const [accordionFocus, setAccordionFocus] = React.useState<number | boolean>(0)
    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }

    const renderCustomMediasSection = () => {
        return (
            <Accordion
                title={t("custom_medias")}
                expanded={accordionFocus === 0}
                onChange={handleAccordionFocus(0)}
                icon={<AttachFile />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                            {t("embedded_youtube_video")}
                        </Typography>

                        <Typography variant={"body2"}>
                            {t("embedded_youtube_video_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={t("video_url")}
                            value={vcard.user.information.medias.youtubeUrl}
                            onChange={(e: any) => setVcard({...vcard, user: { ...vcard.user, information: {
                                ...vcard.user.information, medias: {
                                    ...vcard.user.information.medias, youtubeUrl: e.target.value
                                }
                            }}})}
                            disabled={!permissions.youtube && userType === userRoles.member}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {
                        !(!permissions.media && userType === userRoles.member) &&
                        <Grid item xs={12}>
                            <LegacyCustomMediasSection
                                data={vcard.user.information.medias}
                                setData={(data: any) => {
                                    setVcard({
                                        ...vcard, user: {
                                            ...vcard.user, information: {
                                                ...vcard.user.information, medias: data
                                            }
                                        }
                                    })
                                }}
                            />
                        </Grid>
                    }


                </Grid>
            </Accordion>
        )
    }

    const renderSocialMediasSection = () => {
        if (!permissions.socialNetwork && userType === userRoles.member) return <></>
        return (
            <Accordion
                title={t("external_links")}
                expanded={accordionFocus === 1}
                onChange={handleAccordionFocus(1)}
                icon={<Language />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <LegacySocialMediaSection
                            data={vcard.user.information}
                            setData={(data: any) => {
                                setVcard({
                                    ...vcard, user: {
                                        ...vcard.user, information: data
                                    }
                                })
                            }}
                        />
                    </Grid>

                </Grid>
            </Accordion>
        )
    }

    return (
        <>
            <Box sx={styles.base}>
                {renderCustomMediasSection()}
                {renderSocialMediasSection()}
            </Box>
        </>
    )
}

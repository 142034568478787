const Bouygues = () => {

    function isAdmin(uuid: any) {
        const adminList = [
            // "482948f9-58d0-4363-8bac-e87351cec341",
            "ca7c4147-c12e-40eb-a648-e47b591631b5",
            "0e402160-26f9-4de6-86cf-7c2e2a7fbdcc",
            "d6d5a15b-d5a4-4a4e-9435-009eb93db6cf",
            // TODO: dev testing for bouygues admin system
            // "77594bb9-eed9-4740-b8a8-66dd9725c613", // email.unitag.io for org e1aaa926-68a5-47ae-a51c-66004e393970
            "81bcec9b-f979-4134-be0f-4f7b98e51be8", // ??
            // JC
            "372ac958-c767-496f-904d-8d908ed130f9"
        ]

        return adminList.includes(uuid)
    }

    return {
        isAdmin
    }

}

export default Bouygues

import React from "react"
// MUI
import {Box, Tabs, Tab, Grid, Button} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Components
import {
    AdminAPISettingsPanel,
    AdminDepartmentsSettingsPanel,
    AdminPermissionsSettingsPanel,
    AdminVcardsSettingsPanel
} from "../../AdminPanel";
import {TabPanel, a11yProps} from "../../utils"
// User
import {useUser} from "../../../provider/UserProvider";
// Styles
import styles from "./styles"

const {REACT_APP_BUY_CREDITS_URL} = process.env

export default function AdminPanel() {

    const {t} = useTranslation(["administration", "common"])
    const {credits} = useUser()
    // Tabs
    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const renderCreditsPanel = () => {
        return (
            <Box sx={styles.creditsWrapper}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button disabled variant={"outlined"} size={"small"} sx={styles.creditsDisplay}>
                            {t("credits_used")} : {credits && credits.credits_used}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled variant={"outlined"} size={"small"} sx={styles.creditsDisplay}>
                            {t("credits_left")} : {credits && credits.credits_left}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            size={"small"}
                            startIcon={<AddCircle />}
                            href={`${REACT_APP_BUY_CREDITS_URL}`}
                            target={"_parent"}
                            disableElevation
                        >
                            {t("buy_credits")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderTabs = () => {
        return (
            <Box sx={styles.tabsWrapper}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Settings options"
                    sx={styles.tabsMain}
                    textColor={"secondary"}
                    TabIndicatorProps={{
                        sx: { display: "none" },
                    }}
                >
                    <Tab label={t("vcards_settings")} {...a11yProps(0, t("vcards_settings"))} />
                    <Tab label={t("permissions_settings")} {...a11yProps(1, t("permissions_settings"))} />
                    <Tab label={t("departments_settings")} {...a11yProps(2, t("departments_settings"))} />
                    <Tab label={t("api_settings")} {...a11yProps(3, t("api_settings"))} />
                </Tabs>


                <TabPanel value={tabValue} index={0}>
                    <AdminVcardsSettingsPanel />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <AdminPermissionsSettingsPanel />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <AdminDepartmentsSettingsPanel />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <AdminAPISettingsPanel />
                </TabPanel>
            </Box>
        )
    }

    return (
        <>
            {renderCreditsPanel()}
            {renderTabs()}
        </>
    )
}
import React from "react";
// MUI
import {
    Accordion as MUIAccordion,
    AccordionDetails,
    AccordionSummary,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
// CSS
import "./Accordion.css"

interface AccordionProps {
    expanded: boolean,
    onChange: any,
    icon?: any,
    title: string,
    children: any
}


export default function Accordion(props: AccordionProps) {
    return (
        <MUIAccordion
            variant={"outlined"}
            className={"designAccordions"}
            expanded={props.expanded}
            onChange={props.onChange}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <ListItem dense>
                    {
                        props.icon &&
                        <ListItemIcon>
                            {props.icon}
                        </ListItemIcon>
                    }
                    <ListItemText primary={props.title} primaryTypographyProps={{ fontWeight: 700, fontSize: 16 }} />
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </MUIAccordion>
    )
}
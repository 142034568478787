import React from "react"
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {ChevronRight, Close, CopyAll} from "@mui/icons-material";
import {Link} from "react-router-dom";

export default function NameTemplateDialog(props: any) {

    const {open, onClose, copy, onCopy, name, loading, setName, usingProducts, t} = props

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"sm"}
                fullWidth
                PaperProps={{
                    sx: { position: "absolute", top: 80 }
                }}
            >
                <DialogTitle>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            { copy ? t("duplicate_template") : t("create_template")}
                        </Grid>
                        <Grid item>
                            <IconButton onClick={onClose} disabled={loading}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        { copy ? t("duplicate_template_intro") : t("create_new_template_intro") }
                    </Typography>
                    <br/>
                    <TextField
                        autoFocus
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label={t("create_new_template_label")}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        disabled={loading}
                        sx={{ mr: 1, color: "black" }}
                    >
                        {t("back")}
                    </Button>
                    {
                        copy ?
                            <Button
                                variant={"contained"}
                                disableElevation
                                disabled={!name || name === "" || loading}
                                endIcon={<CopyAll />}
                                onClick={onCopy}
                            >
                                {t("duplicate_template")}
                            </Button>
                        :
                            <Button
                                variant={"contained"}
                                disableElevation
                                component={Link}
                                to={usingProducts ? `/template/create?name=${name}&type=product` : `/template/create?name=${name}`}
                                disabled={!name || name === ""}
                                endIcon={<ChevronRight />}
                            >
                                {t("create_new_template_proceed")}
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
import React, {useState} from "react"
// MUI
import {
    Box,
    Grid,
    Typography,
} from "@mui/material";
import {MeetingRoom, People, Window} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Css
import {styles} from "./styles"
// Components
import {Accordion, InteractiveAdornment, TextField} from "../../utils";
import {CustomHelpersSection} from "../../Forms";
// misc
import {regexEmail, regexPhoneNumber, regexValidUrl} from "../../../functions/functions";

export default function OfficePanel(props: any) {

    const {vcard, setVcard, permissions, userType, userRoles, usingProducts} = props;
    const {t} = useTranslation(["vcardEditor", "common"])
    // Accordions management
    const [accordionFocus, setAccordionFocus] = useState<number | boolean>(0)
    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }

    const renderCustomHelpersSection = () => {
        if (!permissions.customSection && userType === userRoles.member) return <></>
        return (
            <>
                <Accordion
                    title={usingProducts ? t("sections_section") : t("office_helpers_section")}
                    expanded={accordionFocus === 1}
                    onChange={handleAccordionFocus(1)}
                    icon={usingProducts ? <Window /> : <People />}
                >
                    <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}>
                                {usingProducts ? t("sections_section_intro") : t("helpers_section_intro")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomHelpersSection
                                data={vcard.user.information}
                                setData={(data: any) => setVcard({
                                    ...vcard, user: { ...vcard.user, information: data}
                                })}
                                {...{usingProducts}}
                            />
                        </Grid>
                    </Grid>

                </Accordion>

            </>
        )
    }

    const renderOfficeSection = () => {
        if (!permissions.office && userType === userRoles.member || usingProducts) return <></>
        return (
            <Accordion
                title={t("office")}
                expanded={accordionFocus === 0}
                onChange={handleAccordionFocus(0)}
                icon={<MeetingRoom/>}
            >
                <Grid container justifyContent={{xs: "center", md: "flex-start"}} spacing={2}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address")}
                            value={vcard.user.information.office.address}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                ...vcard.user.information, office: {
                                    ...vcard.user.information.office, address: e.target.value
                                    }
                                }
                            }})}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address_secondary")}
                            value={vcard.user.information.office.address2}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, address2: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("city")}
                            value={vcard.user.information.office.city}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, city: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("post_code")}
                            value={vcard.user.information.office.postCode}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, postCode: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("state")}
                            value={vcard.user.information.office.state}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, state: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("country")}
                            value={vcard.user.information.office.country}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, country: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("phone")}
                            value={vcard.user.information.office.phone}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, phone: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                            error={!regexPhoneNumber(vcard.user.information.office.phone)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("email")}
                            value={vcard.user.information.office.email}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, email: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            error={!regexEmail(vcard.user.information.office.email)}
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("website")}
                            value={vcard.user.information.office.website}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, website: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            error={!regexValidUrl(vcard.user.information.office.website)}
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    return (
        <>
            <Box sx={styles.base}>
                {renderOfficeSection()}
                {renderCustomHelpersSection()}
            </Box>
        </>
    )
}

import React from "react"
import {Box, Button, Divider, Grid, Paper, TextField, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";

export default function BouyguesAdditionalInfo(props: any) {

    const {
        sap, setSap,
        infoCI, setInfoCI,
        totalPrice,
        step, setStep,
        bouyguesBillingSap, t
    } = props

    const handleNext = () => {
        bouyguesBillingSap().then((res: any) => { if (res) setStep(step+1) })
    }

    return (
        <Box>
            <Grid container justifyContent={"flex-start"} spacing={3}>
                <Grid item xs={12} lg={"auto"}>
                    <Paper variant={"outlined"} sx={{ width: "100%", p: 3, backgroundColor: "#efefef", textAlign: "center"}}>
                        <Typography>
                            {t("checkout")}
                        </Typography>
                        <Typography variant={"h5"} component={"p"}>
                            {totalPrice}€
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                        required
                        error={!sap}
                        label={t("sap_number")}
                        fullWidth
                        value={sap}
                        onChange={(e) => setSap(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                        label={t("ci_info")}
                        fullWidth
                        value={infoCI}
                        onChange={(e) => setInfoCI(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                    <Button
                        size={"large"}
                        variant={"contained"}
                        color={"primary"}
                        disabled={!sap}
                        endIcon={<ChevronRight />}
                        onClick={() => handleNext()}
                    >
                        {t("next")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
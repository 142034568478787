import React, {useEffect, useState} from "react"
import {
    Box,
    Button,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank, ChevronRight} from "@mui/icons-material";

export default function BouyguesBuyVcards(props: any) {

    const {
        price,
        assets, setAssets,
        totalPrice, setTotalPrice,
        step, setStep, t
    } = props

    const [readDisclaimer, setReadDisclaimer] = useState<boolean>(false)

    useEffect(() => {
        let total = price*assets
        setTotalPrice(total.toFixed(2))
    }, [assets])

    const handleNext = () => {
        setStep(step + 1)
    }

    return (
        <Box>
            <Grid container justifyContent={"flex-start"} spacing={3}>
                <Grid item xs={12} sm={4} lg={4}>
                    <Paper variant={"outlined"} sx={{ width: "100%", p: 3}}>
                        <Typography align={"center"}>
                            {t("price_per_unit")}
                        </Typography>
                        <Typography variant={"h5"} component={"p"} align={"center"}>
                            {price}€
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                        label={t("vcard_number")}
                        value={assets}
                        onChange={(e) => setAssets(e.target.value)}
                        type={"number"}
                        fullWidth
                        inputProps={{
                            min: 1,
                            max: 50
                        }}
                        sx={{ my: 3 }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <Paper variant={"outlined"} sx={{ width: "100%", p: 3}}>
                        <Typography align={"center"}>
                            {t("total_price")}
                        </Typography>
                        <Typography variant={"h5"} component={"p"} align={"center"}>
                            {totalPrice}€
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant={"outlined"}>
                        <List>
                            <ListItemButton onClick={() => setReadDisclaimer(!readDisclaimer)}>
                                <ListItemIcon>{readDisclaimer ? <CheckBox /> : <CheckBoxOutlineBlank />}</ListItemIcon>
                                <ListItemText secondary={t("bouygues_buy_disclaimer")}/>
                            </ListItemButton>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size={"large"}
                        variant={"contained"}
                        color={"primary"}
                        disabled={assets < 1 || !readDisclaimer}
                        endIcon={<ChevronRight />}
                        onClick={() => handleNext()}
                    >
                        {t("command")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
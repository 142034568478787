import React from 'react';
import {Link} from "react-router-dom";
// MUI
import {Box, Breadcrumbs, Grid, Link as MaterialLink, Paper, Typography, Button} from '@mui/material';
// TRANSLATION
import {useTranslation} from "react-i18next";
import DevMenu from "./DevMenu";
import {useUser} from "../../../provider/UserProvider";
const {REACT_APP_DASHBOARD_URL} = process.env

type MyPaths = {
    name: string;
    path: string;
}

type MyProps = {
    // exemple :
    // [{name: "Unitag_console", path: "/"}, {name: "Applications", path: "/applications"}]
    paths: MyPaths[];
    positionName: string; // exemple : Packaging_control_panel
    title: string;
    subTitle?: string;
    docLabel?: string;
    docLink?: string;
    docComingSoon?: boolean;
    disableCreditsSummary?: boolean
}

export default function AppHeader(props: MyProps) {

    const {t} = useTranslation(['common']);
    const { title, subTitle, paths, disableCreditsSummary } = props;
    const {credits} = useUser()

    const styles = {
        headerWrapper: {
            justifyContent: {
                xs: 'center',
                md: 'space-between'
            }
        },
        titleWrapper: { my: 3 },
        docButtonWrapper: { mt: 3 },
        docPaper: { p: 3, textAlign: "center" }
    };

    return (
        <>
            <Grid container sx={styles.headerWrapper} spacing={1}>
                <Grid item xs={12} sm={8} md={6}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography component={"a"} href={`${REACT_APP_DASHBOARD_URL}`} target={"_parent"} color={"inherit"}>
                            {t("unitag_console")}
                        </Typography>
                        <Typography component={"a"} href={`${REACT_APP_DASHBOARD_URL}/applications`} target={"_parent"} color={"inherit"}>
                            {t("apps")}
                        </Typography>
                        {paths && paths.map((path, index) => {
                            return (
                                <MaterialLink key={index} color="inherit" component={Link} to={path.path}>
                                    {t(path.name)}
                                </MaterialLink>
                            );
                        })}
                        <Typography color="textPrimary">
                            {t(props.positionName)}
                        </Typography>
                    </Breadcrumbs>

                    <Box sx={styles.titleWrapper}>
                        <Typography component="h1" variant={"h1"}>
                            {t(title)}
                            <DevMenu />
                        </Typography>
                        {
                            subTitle &&
                            <Typography
                                component="h2"
                                variant={"h5"}
                                color={"textSecondary"}
                                fontWeight={"400"}
                            >
                                {t(subTitle)}
                            </Typography>
                        }
                    </Box>

                </Grid>

                {
                    (!!credits && !disableCreditsSummary) &&
                    <Grid item xs={11} sm={4} md={3}>
                        <Paper variant={"outlined"} sx={styles.docPaper}>
                            <Typography variant="body2">
                                {t("credits_bought")} {credits.credits_bought}<br/>
                                {t("credits_used")} {credits.credits_used}<br/>
                                {t("credits_left")} {credits.credits_left}<br/>
                            </Typography>
                            <Box sx={styles.docButtonWrapper}>
                                <Button
                                    variant={"contained"}
                                    color={"secondary"}
                                    size={"small"}
                                    href={"http://console.unitag.io/alacarte"}
                                    target={"_top"}

                                >
                                    {t("buy_more_credits")}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                }

                {/*{ (docLabel && docLink) &&*/}
                {/*    <Grid item xs={11} sm={4} md={3}>*/}
                {/*        <Paper variant={"outlined"} sx={styles.docPaper}>*/}
                {/*            <Typography variant="body2">*/}
                {/*                {t(docLabel)}*/}
                {/*            </Typography>*/}
                {/*            <Box sx={styles.docButtonWrapper}>*/}
                {/*                <Button*/}
                {/*                    variant={"contained"}*/}
                {/*                    size={"small"}*/}
                {/*                    href={docLink}*/}
                {/*                    target="_blank"*/}
                {/*                    rel="noopener noreferrer"*/}
                {/*                    disabled={docComingSoon}*/}
                {/*                >*/}
                {/*                    {docComingSoon ? t("coming_soon") : t("Documentation")}*/}
                {/*                </Button>*/}
                {/*            </Box>*/}
                {/*        </Paper>*/}
                {/*    </Grid>*/}
                {/*}*/}
            </Grid>
        </>
    )
}
import React from "react"
import {Link, useLocation, useNavigate} from "react-router-dom";
// MUI
import {Alert, Button, Container, Grid, Skeleton} from "@mui/material";
import {ArrowLeft, Check} from "@mui/icons-material";
// Components
import AppHeader from "../../components/DashboardComponents/Header/Header";
import ContactEditorPanel from "../../components/ContactEditor/ContactEditorPanel";
// Translation
import {useTranslation} from "react-i18next";
// API
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_CONTACT, MODIFY_CONTACT} from "../../api/calls";
// Types
import {ContactTypes} from "../../types";

export default function ContactEditor() {

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id")
    const redirect = useNavigate()
    // translation
    const {t} = useTranslation(["contactEditor", "common"])
    // contact
    const [contact, setContact] = React.useState<ContactTypes.Contact>()

    const [getContact, {
        data: contactData,
        loading: contactLoading,
        error: contactError
    }] = useLazyQuery(GET_CONTACT, { fetchPolicy: "no-cache" })

    const [modifyContact, {
        loading: updateLoading,
        error: updateError
    }] = useMutation(MODIFY_CONTACT, { fetchPolicy: "no-cache" })

    React.useEffect(() => {
        if (contactData) {
            setContact(contactData.getContact)
        }
    }, [contactData])

    React.useEffect(() => {
        if (!id) {
            redirect("/?tab=contacts")
        }
        // If ID is detected within the URL, switch to editing
        if (id && id !== "") {
            getContact({ variables: { contactUuid: id }}).then()
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const updateContact = (id: string | undefined, completed?: boolean) => {
        if (completed) {
            modifyContact({ variables: {
                contact: {
                    uuid: contact?.uuid,
                    vcardUuid: contact?.vcardUuid,
                    information: contact?.information,
                    completed: true
                }
            }}).then(() => redirect("/?tab=contacts"))
        } else {
            modifyContact({ variables: {
                    contact: {
                        uuid: contact?.uuid,
                        vcardUuid: contact?.vcardUuid,
                        information: contact?.information,
                        completed: false
                    }
            }}).then(() => redirect("/?tab=contacts"))
        }
    }

    const renderActions = () => {
        return (
            <>
                <Grid container justifyContent={{ xs: "center", md: "space-between" }}>
                    <Grid item>
                        <Button
                            component={Link}
                            to={"/?tab=contacts"}
                            variant={"outlined"}
                            color={"secondary"}
                            startIcon={<ArrowLeft />}
                        >
                            {t("common:back")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            disableElevation
                            className={"saveButton"}
                            color={"secondary"}
                            onClick={() => updateContact(contact?.uuid)}
                            disabled={!!contactError || updateLoading}
                        >
                            {t("update_contact")}
                        </Button>
                        {
                            (contact && !contact.completed) &&
                            <>
                                &nbsp;&nbsp;
                                <Button
                                    variant={"contained"}
                                    disableElevation
                                    className={"saveButton"}
                                    onClick={() => updateContact(contact.uuid, true)}
                                    startIcon={<Check />}
                                    disabled={!!contactError || updateLoading}
                                >
                                    {t("update_and_complete_contact")}
                                </Button>
                            </>
                        }
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Container maxWidth={"xl"} className={"wrapper"}>
            <AppHeader
                paths={[{name: "common:Vcards", path: "/"}]}
                positionName={"contact_editor"}
                title={"contact_editor"}
                disableCreditsSummary
            />

            <Grid container justifyContent={"center"} spacing={3}>

                <Grid item xs={12}>
                    {renderActions()}
                </Grid>

                {
                    (contactError || updateError) &&
                    <Grid item xs={12}>
                        <Alert severity={"error"}>
                            {contactError && t("contact_loading_error")}
                            {updateError && t("contact_update_error")}
                        </Alert>
                    </Grid>
                }

                {
                    (!contact || contactLoading) ?
                        <Skeleton width={"100%"} height={800} />
                        :
                        <Grid item xs={12}>
                            <ContactEditorPanel {...{contact, setContact}} />
                        </Grid>
                }

            </Grid>
        </Container>
    )
}

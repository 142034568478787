import React from "react"
// mui
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
// translation
import {useTranslation} from "react-i18next";

export default function VCardQRCodeDialog(props: any) {

    const {open, onClose, src} = props
    const {t} = useTranslation(["common"])

    const [imageUrl, setImageUrl] = React.useState<string>("")

    React.useEffect(() => {
        setImageUrl("/assets/qrcodes/qrcode-placeholder.png")
        if (src) setImageUrl(src)
    }, [])

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>
                    <Box display={"flex"} alignContent={"center"}>
                        <Box flexGrow={1}>
                            {t("vcard_my_qrcode")}
                        </Box>
                        <IconButton onClick={onClose} aria-label={t("back")}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container justifyContent={"center"} spacing={5}>
                        <Grid item xs={12} textAlign={"center"}>
                            <img
                                src={imageUrl}
                                width={250}
                                height={250}
                                alt={"Vcard QR Code"}
                                onError={() => setImageUrl("/assets/qrcodes/qrcode-placeholder.png")}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <Button
                                onClick={onClose}
                                color={"primary"}
                                variant={"contained"}
                                disableElevation
                            >
                                {t("back")}
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </>
    )
}
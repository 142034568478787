import React, {useEffect} from "react"
import {useLocation} from "react-router-dom";
import withRouter from "../components/utils/WithRouter";

const {REACT_APP_DASHBOARD_URL} = process.env

function IframeManager({ children }: { children: React.ReactNode }) {

    const location = useLocation()

    useEffect(() => {
        scrollTop()
    }, [location])


    const scrollTop = () => {
        let payload = JSON.stringify(["scrollTop"])
        window.top?.postMessage(payload, `${REACT_APP_DASHBOARD_URL}`)
    }

    return (
        <>
            {children}
        </>
    )
}

export default withRouter(IframeManager)
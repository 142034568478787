import React from "react"
import {Box} from "@mui/material";

export default function CompanyLogo(props: any) {

    const {styles, templateData} = props;

    let srcError = false

    if (templateData.companyLogo !== "" && !srcError) {
        return (
            <Box sx={styles.companyLogoWrapper}>
                <img
                    src={templateData.companyLogo}
                    style={styles.companyLogo}
                    alt={"Company logo"}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        srcError = true
                    }}
                />
            </Box>
        )
    }

    return <></>
}
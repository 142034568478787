const styles = {
    creditsWrapper: {
        mx: 3,
        my: 2
    },
    creditsPaper: {
        p: 1,
    },
    creditsButtonBase: {
        borderRadius: "4px"
    },
    creditsDisplay: {
        color: "black!important"
    },
    creditsAddIcon: {
        fontSize: 19,
    },
    tabsWrapper: {
        p: 2,
        flexGrow: 1,
        display: "flex"
    },
    tabsMain: {
        borderRight: 1,
        borderColor: 'divider',
        mr: 2,
        minWidth: 230
    }
}

export default styles
import React, {useState, useEffect} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom";
// MUI
import {
    Alert,
    Container,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Tab,
    Tabs
} from "@mui/material";
import {ArrowLeft} from "@mui/icons-material";
// Components
import AppHeader from "../../components/DashboardComponents/Header/Header";
import {
    GeneralPanel,
    OfficePanel,
    SocialMediaPanel,
    ProfilePanel
} from "../../components/VcardEditor";
import {Loading, TabPanel, a11yProps} from "../../components/utils";
// Translation
import {useTranslation} from "react-i18next";
// api
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    ADD_VCARD_TO_DEPARTMENT,
    CREATE_VCARD, GET_COMPANY,
    GET_DEPARTMENTS, GET_TEMPLATE,
    GET_VCARD,
    MODIFY_VCARD,
    REMOVE_VCARD_FROM__DEPARTMENT
} from "../../api/calls";
// context
import {useUser} from "../../provider/UserProvider";
// Types
import {VcardTypes, GenericTypes, MiscellaneousTypes} from "../../types";
import {useModule} from "../../provider/ClientModuleProvider";
import VcardsProvider from "../../components/Vcards/Provider/VcardsProvider";

export default function VcardEditor() {

    // Pathname & queries
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id")
    const url = new URLSearchParams(location.pathname).toString()
    const vcardType = new URLSearchParams(location.search).get("type")
    // Router
    const redirect = useNavigate()
    // User
    const {userRoles} = useUser()
    const userType = useUser().user.organisation_role
    // Bouygues
    const {usingModule} = useModule()
    // Preview
    const displayPreview = (!!userRoles && userType !== userRoles.member)
    // translation
    const {t} = useTranslation(["vcardEditor", "common"])

    let usingProducts = vcardType === "product"

    // Tab management
    const [tabValue, setTabValue] = useState<number>(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => { setTabValue(newValue) };
    // Dismount dialog
    const [openDismountDialog, setOpenDismountDialog] = useState<boolean>(false)
    // hot save
    const [hotSave, setHotSave] = React.useState<boolean>(false)
    // Editing
    const [editing, setEditing] = useState<boolean>(false)
    // Other data
    const [departments, setDepartments] = useState<GenericTypes.DepartmentsList>()
    const [previousDepartment, setPreviousDepartment] = useState<string>("")
    // Permissions
    const [permissions, setPermissions] = useState<MiscellaneousTypes.Permissions>({
        name: true,
        position: true,
        photo: true,
        telephone: true,
        customContact: true,
        biography: true,
        youtube: true,
        media: true,
        office: true,
        customSection: true,
        socialNetwork: true,
    })
    // Vcard content

    const [vcard, setVcard] = useState<VcardTypes.Vcard>(
        {
            uuid: "",
            templateUuid: "",
            ownerUuid: "",
            user: {
                departmentHeadUuid: "",
                departmentUuid: "",
                businessEntity: "",
                title: "",
                lastName: "",
                firstName: "",
                jobTitle: "",
                jobTitleAdditional: "",
                picture: "",
                email: "",
                information: {
                    contact: {
                        phone: "",
                        secondaryPhone: "",
                    },
                    biography: "",
                    medias: {
                        youtubeUrl: "",
                        custom: [
                            {
                                title: "",
                                fileName: "",
                                customUrl: "",
                                mediaGroupName: ""
                            }
                        ],
                    },
                    office: {
                        address: "",
                        address2: "",
                        city: "",
                        postCode: "",
                        state: "",
                        country: "",
                        phone: "",
                        email: "",
                        website: ""
                    },
                    sections: [],
                    socialNetworks: []
                }
            }
        }
    )
    // Specific state to debug primereact editor dumb-a** speed
    const [biography, setBiography] = useState<string>("")
    // Preview
    const [templatePreview, setTemplatePreview] = useState<any>(undefined)

    // Queries
    const [saveNewVcard,{
        loading: saveNewLoading,
        error: saveNewError,
        data: saveData
    }] = useMutation(CREATE_VCARD)

    const [fetchVcard, {
        loading: fetchVcardLoading,
        error: fetchVcardError
    }] = useLazyQuery((GET_VCARD), { fetchPolicy: "no-cache" })

    const [updateVcard, {
        loading: updateLoading,
        error: updateError,
        data: updateData
    }] = useMutation(MODIFY_VCARD)

    const [getDepartments, {
        loading: departmentsLoading,
        error: departmentsError,
        data: departmentsData
    }] = useLazyQuery(GET_DEPARTMENTS, { fetchPolicy: "no-cache" })

    const [addVcardToDepartment, {
        error: addError
    }] = useMutation(ADD_VCARD_TO_DEPARTMENT, { fetchPolicy: "no-cache" })

    const [removeVcardFromDepartment, {
        error: removeError
    }] = useMutation(REMOVE_VCARD_FROM__DEPARTMENT, { fetchPolicy: "no-cache" })

    const [getPermissions, {
        loading: permLoading,
        error: permError,
        data: permData
    }] = useLazyQuery(GET_COMPANY, { fetchPolicy: "no-cache" })

    const [getTemplate] = useLazyQuery(GET_TEMPLATE, {
        variables: {
            templateUuid: id
        },
        fetchPolicy: "no-cache"
    })

    // Check if editing a template based on route
    useEffect(() => {
        if (!id && !url.includes("create")) {
            redirect("/")
        }
        // fetch other data
        fetchDepartments()
        fetchPermissions()
        // If ID is detected within the URL, switch to editing
        if (id && id !== "") {
            setEditing(true);
            fetchVcard({ variables: { vcardUuid: id }}).then((res) => {
                setVcard(res.data.getVcard)
                if (res.data.getVcard.user.departmentUuid !== "") {
                    setPreviousDepartment(res.data.getVcard.user.departmentUuid)
                }
            })
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    // Hydrate Departments when ready
    useEffect(() => {
        if (departmentsData) setDepartments(departmentsData.getDepartments.values)
    }, [departmentsData])

    useEffect(() => {
        if (permData && permData.getCompany.options.permissions !== null) setPermissions(permData.getCompany.options.permissions)
    }, [permData])

    // Save Vcard to its corresponding department
    useEffect(() => {
        if (saveData || updateData) {
            let _vcard: any
            let _department: any = ""
            let _uuid: any

            if (saveData) _vcard = saveData.createVCard
            if (updateData) _vcard = updateData.modifyVCard

            if (hotSave && vcard.uuid === "") setVcard({...vcard, uuid: _vcard.uuid})

            _uuid = _vcard.uuid
            _department = _vcard.user?.departmentUuid

            if (!editing) {
                if (!_department || _department === "") {
                    if (!hotSave) redirect("/?tab=vcards")
                    else hotRefresh()
                } else {
                    addVcardToDepartment({
                        variables: {
                            newVcardUuids: [_uuid],
                            departementUuid: _department
                        }
                    }).then(() => {
                        if (!hotSave) redirect("/?tab=vcards")
                        else hotRefresh()
                    })
                }
                return
            } else {

                if (previousDepartment === _department) {
                    if (!hotSave) redirect("/?tab=vcards")
                    else hotRefresh()
                    return
                }

                if (!previousDepartment && _department) {
                    addVcardToDepartment({
                        variables: {
                            newVcardUuids: [_uuid],
                            departementUuid: _department
                        }
                    }).then(() => {
                        if (!hotSave) redirect("/?tab=vcards")
                        else hotRefresh()
                    })
                } else if (previousDepartment && (_department || _department === null)) {
                    removeVcardFromDepartment({
                        variables: {
                            newVcardUuids: [_uuid],
                            departementUuid: previousDepartment
                        }
                    }).then(() => {
                        if (_department && _department !== "") {
                            addVcardToDepartment({
                                variables: {
                                    newVcardUuids: [_uuid],
                                    departementUuid: _department
                                }
                            }).then(() => {
                                if (!hotSave) redirect("/?tab=vcards")
                                else hotRefresh()
                            })
                        }
                    })
                }
            }
            if (!hotSave) redirect("/?tab=vcards")
            else hotRefresh()
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveData, updateData])

    // Debug biography
    useEffect(() => {
        if (!!vcard.user.information.biography) {
            let _biography = vcard.user.information.biography
            if (_biography.trim().length === 0) _biography = ""
            setBiography(_biography)
        }
    }, [vcard.user.information.biography])

    // Preview setting
    useEffect(() => {
        if (displayPreview && !!vcard.templateUuid) {
            getTemplate({
                variables: { templateUuid: vcard.templateUuid },
            }).then((data: any) => {
                let res = data.data.getTemplate
                setTemplatePreview(res)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vcard.templateUuid])

    const hotRefresh = () => {
        let _department = !!vcard.user?.departmentUuid ? vcard.user?.departmentUuid : ""
        setEditing(true)
        setPreviousDepartment(_department)
    }

    const fetchDepartments = () => {
        getDepartments({
            variables: {
                page: 1,
                perPage: 10
            }
        }).then()
    }

    const fetchPermissions = () => {
        getPermissions().then()
    }

    const saveVcard = (quit?: string) => {

        if (quit) setHotSave(false)
        else setHotSave(true)

        let _vcard = vcard
        _vcard.user.information.biography = !!biography ? biography : ""

        if (editing) {
            updateVcard({ variables: { vcard: {
                ..._vcard,
                uuid: (!quit && !!saveData) ? saveData.createVCard.uuid :  _vcard.uuid,
                canMemberByPass: usingModule.module === "bouygues"
            } } }).then()
        } else {
            saveNewVcard({ variables: { newVCard: {
                templateUuid: _vcard.templateUuid,
                ownerUuid: _vcard.ownerUuid,
                user: _vcard.user
            }}}).then()
        }
    }

    const renderDismountConfirmationDialog = () => {
        return (
            <Dialog open={openDismountDialog} maxWidth={"md"} fullWidth>
                <DialogTitle>
                    {t("dismount_dialog_title")}
                </DialogTitle>
                <DialogContent>
                    {t("dismount_dialog_content")}
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: "black", mr: 1 }} onClick={() => setOpenDismountDialog(false)}>
                        {t("dismount_continue_creation")}
                    </Button>
                    <Button variant={"contained"} color={"error"} disableElevation component={Link} to={"/"}>
                        {t("dismount_give_up_creation")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    if (fetchVcardLoading || departmentsLoading || permLoading) return <Loading />

    return (
        <>
            <Container maxWidth={"xl"} className={"wrapper"}>
                <AppHeader
                    paths={[{name: "common:Vcards", path: "/"}]}
                    positionName={editing ? "vcard_editor" : "vcard_creation"}
                    title={editing ? "vcard_editor" : "vcard_creation"}
                    disableCreditsSummary
                />

                <Grid container justifyContent={"center"} spacing={3}>
                    {/* ACTIONS */}
                    <Grid item xs={12}>
                        <Grid container justifyContent={{ xs: "center", md: "space-between" }}>
                            <Grid item>
                                <Button
                                    onClick={() => setOpenDismountDialog(true)}
                                    variant={"outlined"}
                                    color={"secondary"}
                                    startIcon={<ArrowLeft />}
                                >
                                    {t("common:back")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    disableElevation
                                    className={"saveButton"}
                                    onClick={() => saveVcard()}
                                    disabled={
                                        vcard.templateUuid === "" ||
                                        vcard.ownerUuid === "" ||
                                        saveNewLoading ||
                                        updateLoading
                                    }
                                >
                                    {t("common:save")}
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant={"outlined"}
                                    disableElevation
                                    className={"saveButton"}
                                    onClick={() => saveVcard("quit")}
                                    disabled={
                                        vcard.templateUuid === "" ||
                                        vcard.ownerUuid === "" ||
                                        saveNewLoading ||
                                        updateLoading
                                    }
                                >
                                    {t("common:save_and_quit")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        (saveNewError || updateError || permError || addError || departmentsError || fetchVcardError || removeError) &&
                        <Grid item xs={12}>
                            <Alert severity={"error"}>
                                {
                                    saveNewError ? t("error_while_saving_vcard") :
                                    updateError ? t("vcard_update_error") :
                                    permError ? t("vcard_perm_error") :
                                    (addError || removeError) ? t("vcard_add_error") :
                                    departmentsError ? t("vcard_departments_error") :
                                    fetchVcardError ? t("vcard_fetch_error") : ""
                                }
                            </Alert>
                        </Grid>
                    }

                    <Grid item xs={displayPreview ? 8 : 12}>
                        <Paper variant={"outlined"} className={"editorWrapper"}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                                <Tab label={t("vcard_general")} {...a11yProps(0, t("vcard_general"))} />
                                <Tab label={usingProducts ? t("vcard_product_sections") : t("vcard_informations")} {...a11yProps(1, t("vcard_informations"))} />
                                <Tab label={t("vcard_links")} {...a11yProps(2, t("vcard_links"))} />
                            </Tabs>

                            <TabPanel label={t("vcard_general")} value={tabValue} index={0}>
                                <GeneralPanel {...{vcard, setVcard, editing, permissions, userRoles, userType, departments, usingProducts, usingModule}} />
                                <ProfilePanel {...{vcard, setVcard, permissions, userRoles, userType, usingProducts, biography, setBiography}} />
                            </TabPanel>
                            <TabPanel label={t("vcard_informations")} value={tabValue} index={1}>
                                <OfficePanel {...{vcard, setVcard, permissions, userRoles, userType, usingProducts}} />
                            </TabPanel>
                            <TabPanel label={t("vcard_links")} value={tabValue} index={2}>
                                <SocialMediaPanel {...{vcard, setVcard, editing, permissions, userRoles, userType}} />
                            </TabPanel>
                        </Paper>
                    </Grid>

                    {
                        displayPreview &&
                        <Grid item xs={4}>
                            <VcardsProvider
                                {...{vcard, biography, t}}
                                preview
                                product={usingProducts}
                                template={!!templatePreview ? templatePreview : null}
                                templateBase={!!templatePreview ? templatePreview.templateBase : "modern"}
                            />
                        </Grid>
                    }

                </Grid>
            </Container>

            {renderDismountConfirmationDialog()}
        </>
    )
}

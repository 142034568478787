import React from "react"
// Translation
import {useTranslation} from "react-i18next";
// MUI
import {Alert, Grid, Paper, Typography} from "@mui/material";

export default function AdminAPISettingsPanel() {

    const {t} = useTranslation(["administration", "common"])

    const styles = {
        paper: {
            p: 2
        },
        info: {
            width: "100%"
        }
    }

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "start" }} spacing={2}>
                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={styles.paper}>
                        <Typography variant={"h6"} component={"p"}>
                            {t("api_settings")}
                        </Typography>
                        <Alert severity={"info"} sx={styles.info}>
                            <Typography>
                                {t("api_settings_intro")}
                            </Typography>
                        </Alert>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
import React from "react"
// mui
import {Box, CircularProgress, Container, Stack, Typography} from "@mui/material";
// translation
import {useTranslation} from "react-i18next";

export default function Loading(props: any) {

    const {t} = useTranslation(["common"])

    return (
        <Container maxWidth={false} sx={{ mt: 15 }}>
            <Box mx={"auto"} textAlign={"center"}>
                <Stack spacing={2} justifyContent={"center"}>
                    <Box mx={"auto"}>
                        <CircularProgress sx={{ mx: 'auto' }} />
                    </Box>
                    <Typography fontWeight={600}>
                        {
                            props.vcard ? t("loading_vcard") : t("loading")
                        }
                    </Typography>
                </Stack>
            </Box>
        </Container>
    )
}
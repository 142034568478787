import React from "react"
// MUI
import {
    Alert,
    Button, Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Switch,
    Typography
} from "@mui/material";
// CSS
import {styles} from "./styles"
// Translation
import {useTranslation} from "react-i18next";
// API
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_COMPANY, MODIFY_COMPANY} from "../../../api/calls";
// Types
import {MiscellaneousTypes} from "../../../types";

export default function AdminPermissionsSettingsPanel() {

    const {t} = useTranslation(["administration", "common"])
    const [permissions, setPermissions] = React.useState<MiscellaneousTypes.Permissions>({
        name: true,
        position: true,
        photo: true,
        telephone: true,
        customContact: true,
        biography: true,
        youtube: true,
        media: true,
        office: true,
        customSection: true,
        socialNetwork: true,
    })

    const [getPermissions, {
        loading: permLoading,
        error: permError,
        data: permData
    }] = useLazyQuery(GET_COMPANY, { fetchPolicy: "no-cache" })

    const [savePermissions, {
        loading: saveLoading,
        error: saveError,
        data: saveData
    }] = useMutation(MODIFY_COMPANY, { fetchPolicy: "no-cache" })

    React.useEffect(() => {
        fetchPermissions()
    }, [])

    React.useEffect(() => {
        if (permData && permData.getCompany.options.permissions !== null) setPermissions(permData.getCompany.options.permissions)
    }, [permData])

    const fetchPermissions = () => {
        getPermissions().then()
    }

    const handleSavePermissions = () => {
        savePermissions({
            variables: {
                company: {
                    name: permData.getCompany.name,
                    options: {
                        contactFormat: permData.getCompany.options.contactFormat,
                        permissions: permissions
                    }
                }
            }
        }).then()
    }

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "start" }} spacing={2}>
                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={styles.paper}>
                        <Grid container justifyContent={"flex-start"} spacing={2}>

                            <Grid item xs={12} md={8}>
                                <Typography variant={"h6"} component={"p"}>
                                    {t("permissions_settings")}
                                </Typography>
                                <Typography variant={"body2"}>
                                    {t("permissions_settings_intro")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4} sx={styles.buttonGrid}>
                                <Button
                                    onClick={() => handleSavePermissions()}
                                    variant={"contained"}
                                    disableElevation
                                    disabled={saveLoading || permLoading}
                                >
                                    {t("save_permissions")}
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Collapse in={saveData}>
                                    <Alert severity={(saveError || permError) ? "error" : "success"}>
                                        {
                                            saveError ? t("permissions_save_error") :
                                            permError ? t("permissions_get_error") :
                                            t("permissions_saved")
                                        }
                                    </Alert>
                                </Collapse>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl component={"fieldset"} variant={"standard"}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.name}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, name: event.target.checked })}
                                            />}
                                            label={t("gender_name_lastName")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.position}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, position: event.target.checked })}
                                            />}
                                            label={t("title")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.photo}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, photo: event.target.checked })}
                                            />}
                                            label={t("picture")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.telephone}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, telephone: event.target.checked })}
                                            />}
                                            label={t("phone_numbers")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.customContact}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, customContact: event.target.checked })}
                                            />}
                                            label={t("contacts_medias")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.biography}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, biography: event.target.checked })}
                                            />}
                                            label={t("biography")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.youtube}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, youtube: event.target.checked })}
                                            />}
                                            label={t("youtube_video")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.media}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, media: event.target.checked })}
                                            />}
                                            label={t("custom_medias")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.office}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, office: event.target.checked })}
                                            />}
                                            label={t("office_informations")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.customSection}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, customSection: event.target.checked })}
                                            />}
                                            label={t("custom_helpers_sections")}
                                        />
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={permLoading}
                                                checked={permissions.socialNetwork}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPermissions({...permissions, socialNetwork: event.target.checked })}
                                            />}
                                            label={t("social_medias_and_links")}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

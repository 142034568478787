import React from "react"
import {
    Avatar,
    Box,
    Button,
    Divider,
    FormControl,
    Grid, InputAdornment,
    InputLabel, LinearProgress,
    MenuItem, Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {
    Add,
    AlternateEmail,
    LocalPhone, MeetingRoom, People,
    Person,
    Phone,
    PhoneAndroid,
    RememberMeOutlined
} from "@mui/icons-material";
import {Accordion, ImageDropzone, InteractiveAdornment} from "../../../utils";
import {styles} from "../../../VcardEditor/ProfilePanel/styles";
import {regexEmail, regexPhoneNumber, regexValidUrl} from "../../../../functions/functions";
import {ContactMediasSection, CustomHelpersSection} from "../../../Forms";
import {useTranslation} from "react-i18next";


export default function BouyguesCreateVcard(props: any) {

    const {
        vcard, setVcard,
        assets,
        createVcard,
        createdVcards,
        handleDropProfilePicture,
        saveNewLoading,
        apiLoading
    } = props
    const {t} = useTranslation(["vcardEditor", "bouygues", "common"])

    const [accordionFocus, setAccordionFocus] = React.useState<number | boolean>(0)
    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }

    const renderProfileSection = () => {

        const clearProfilePicture = () => {
            setVcard({...vcard, user: { ...vcard.user, picture: "" }})
        }

        return (
            <Accordion
                title={t("user_profile")}
                expanded={accordionFocus === 0}
                onChange={handleAccordionFocus(0)}
                icon={<Person />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_general")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id={"gender"}>{t("displayed_gender")}</InputLabel>
                            <Select
                                value={vcard.user.title}
                                onChange={(e) => setVcard({...vcard, user: {...vcard.user, title: e.target.value}})}
                                label={t("displayed_gender")}
                                labelId={"gender"}
                            >
                                <MenuItem value={""}>{t("no_display")}</MenuItem>
                                <MenuItem value={"mr"}>{t("gender_mister")}</MenuItem>
                                <MenuItem value={"ms"}>{t("gender_miss")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label={t("firstName")}
                            value={vcard.user.firstName}
                            onChange={(e) => setVcard({...vcard, user: {...vcard.user, firstName: e.target.value}})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label={t("lastName")}
                            value={vcard.user.lastName}
                            onChange={(e) => setVcard({...vcard, user: {...vcard.user, lastName: e.target.value}})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label={t("title")}
                            value={vcard.user.jobTitle}
                            onChange={(e) => setVcard({...vcard, user: {...vcard.user, jobTitle: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_picture")}
                        </Typography>
                        <Typography variant={"body2"}>
                            {t("profile_picture_helper_text")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent={{ xs: "center", md: "flex-start" }}>
                            <Grid item xs={8} sm={6} md={1} alignContent={"center"} alignItems={"center"}>
                                <Box sx={styles.avatarWrapper}>
                                    <Avatar src={vcard.user.picture} sx={styles.avatar}>
                                        <RememberMeOutlined sx={{ fill: "#909090" }} />
                                    </Avatar>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                                <ImageDropzone
                                    onDrop={handleDropProfilePicture}
                                    label={t("common:import_your_files")}
                                    success={vcard.user.picture}
                                    onClear={() => clearProfilePicture()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderContactSection = () => {
        return (
            <Accordion
                title={t("user_contact")}
                expanded={accordionFocus === 1}
                onChange={handleAccordionFocus(1)}
                icon={<Phone />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_contacts")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label={t("phone")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <LocalPhone />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InteractiveAdornment />
                                )
                            }}
                            value={vcard.user.information.contact.phone}
                            onChange={(e) =>
                                setVcard({...vcard, user: { ...vcard.user, information: {
                                            ...vcard.user.information, contact: {
                                                ...vcard.user.information.contact, phone: e.target.value
                                            }
                                        }}})
                            }
                            error={!regexPhoneNumber(vcard.user.information.contact.phone)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label={t("mobile_phone")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <PhoneAndroid />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InteractiveAdornment />
                                )
                            }}
                            value={vcard.user.information.contact.secondaryPhone}
                            onChange={(e) =>
                                setVcard({...vcard, user: { ...vcard.user, information: {
                                            ...vcard.user.information, contact: {
                                                ...vcard.user.information.contact, secondaryPhone: e.target.value
                                            }
                                        }}})
                            }
                            error={!regexPhoneNumber(vcard.user.information.contact.secondaryPhone)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label={t("email")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <AlternateEmail />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InteractiveAdornment />
                                )
                            }}
                            value={vcard.user.email}
                            onChange={(e) =>
                                setVcard({...vcard, user: { ...vcard.user, email: e.target.value }})
                            }
                            error={!regexEmail(vcard.user.email)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_custom_contacts")}
                        </Typography>
                        <Typography variant={"body2"}>
                            {t("profile_custom_contacts_helper_text")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <ContactMediasSection
                            data={vcard.user.information.contact}
                            setData={(data: any) => setVcard({
                                ...vcard, user: { ...vcard.user, information: { ...vcard.user.information, contact: data }}
                            })}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderOfficeSection = () => {
        return (
            <Accordion
                title={t("office")}
                expanded={accordionFocus === 2}
                onChange={handleAccordionFocus(2)}
                icon={<MeetingRoom/>}
            >
                <Grid container justifyContent={{xs: "center", md: "flex-start"}} spacing={2}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address")}
                            value={vcard.user.information.office.address}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, address: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address_secondary")}
                            value={vcard.user.information.office.address2}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, address2: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("city")}
                            value={vcard.user.information.office.city}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, city: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("post_code")}
                            value={vcard.user.information.office.postCode}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, postCode: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("state")}
                            value={vcard.user.information.office.state}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, state: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("country")}
                            value={vcard.user.information.office.country}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, country: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("phone")}
                            value={vcard.user.information.office.phone}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, phone: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                            error={!regexPhoneNumber(vcard.user.information.office.phone)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("email")}
                            value={vcard.user.information.office.email}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, email: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            error={!regexEmail(vcard.user.information.office.email)}
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t("website")}
                            value={vcard.user.information.office.website}
                            onChange={(e: any) => setVcard({...vcard, user: {...vcard.user, information: {
                                        ...vcard.user.information, office: {
                                            ...vcard.user.information.office, website: e.target.value
                                        }
                                    }
                                }})}
                            fullWidth
                            error={!regexValidUrl(vcard.user.information.office.website)}
                            InputProps={{
                                endAdornment: (
                                    <InteractiveAdornment/>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderCustomHelpersSection = () => {
        return (
            <>
                <Accordion
                    title={t("office_helpers_section")}
                    expanded={accordionFocus === 3}
                    onChange={handleAccordionFocus(3)}
                    icon={<People />}
                >
                    <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"body2"}>
                                {t("helpers_section_intro")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <CustomHelpersSection
                                data={vcard.user.information}
                                setData={(data: any) => setVcard({
                                    ...vcard, user: { ...vcard.user, information: data}
                                })}
                            />
                        </Grid>
                    </Grid>

                </Accordion>

            </>
        )
    }

    let progress = (createdVcards/Number(assets))*100

    return (
        <Box>
            <Grid container justifyContent={"flex-start"} spacing={3}>
                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={{ p: 2}}>
                        <Typography variant={"subtitle2"}>{t("bouygues:created_vcards")} {createdVcards} / {assets}</Typography>
                        <br/>
                        <LinearProgress variant={"determinate"} value={progress} sx={{ height: 10, borderRadius: 2 }} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    {renderProfileSection()}
                    {renderContactSection()}
                    {renderOfficeSection()}
                    {renderCustomHelpersSection()}
                </Grid>

                {(!vcard.user.email || !regexEmail(vcard.user.email)) &&
                    <Grid item xs={12}>
                        <Typography align={"center"} sx={{ color: "red" }}>
                            {t("bouygues:email_mandatory")}
                        </Typography>
                    </Grid>
                }

                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        startIcon={<Add />}
                        disabled={!vcard.user.email || saveNewLoading || !regexEmail(vcard.user.email) || apiLoading}
                        onClick={() => createVcard()}
                    >
                        {(saveNewLoading || apiLoading) ? t("loading") : !vcard.user.email ? t("bouygues:missing_fields") : t("bouygues:create_vcard")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

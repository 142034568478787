import React, {useState, useEffect} from "react"
import BouyguesEntitiesAndPerimeters
    from "../../../components/Modules/Bouygues/BuyForBouygues/BouyguesEntitiesAndPerimeters";
import BouyguesBuyVcards from "../../../components/Modules/Bouygues/BuyForBouygues/BouyguesBuyVcards";
import {useMutation} from "@apollo/client";
import {APIPost, APIPostFile, CREATE_VCARD} from "../../../api/calls";
import BouyguesAdditionalInfo from "../../../components/Modules/Bouygues/BuyForBouygues/BouyguesAdditionalInfo";
import {useUser} from "../../../provider/UserProvider";
import {useClient} from "../../../provider/APIProvider";
import BouyguesCreateVcard from "../../../components/Modules/Bouygues/BuyForBouygues/BouyguesCreateVcard";
import {enqueueSnackbar} from "notistack";
import {Button, Grid, Typography, Divider, Box} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const {REACT_APP_DASHBOARD_API_URL} = process.env

export default function BouyguesMain() {

    const {user} = useUser()
    const {token} = useClient()
    const {t} = useTranslation(["bouygues", "common"])

    const handleSuccess = (message: any) => enqueueSnackbar(message, { variant: "success", anchorOrigin: { horizontal: "center", vertical: "top" } })
    const handleError = (message: any) => enqueueSnackbar(message, { variant: "error" })

    // Queries
    const [saveNewVcard,{
        loading: saveNewLoading,
    }] = useMutation(CREATE_VCARD)

    const [step, setStep] = useState<number>(0)
    // step 1
    const [entity, setEntity] = useState<any>("")
    const [perimeter, setPerimeter] = useState<any>("")
    const [template, setTemplate] = useState<any>("")
    // step 2
    const [price, setPrice] = useState<number>(15.47)
    const [assets, setAssets] = useState<number>(1)
    const [totalPrice, setTotalPrice] = useState<number>(28.08)
    // step 3
    const [sap, setSap] = useState<string>("")
    const [infoCI, setInfoCI] = useState<string>("")
    // step 4
    const [vcard, setVcard] = useState<any>({
        uuid: "",
        templateUuid: "",
        ownerUuid: "",
        user: {
            departmentHeadUuid: "",
            departmentUuid: "",
            title: "",
            lastName: "",
            firstName: "",
            jobTitle: "",
            picture: "",
            email: "",
            information: {
                contact: {
                    phone: "",
                    secondaryPhone: "",
                },
                biography: "",
                medias: {
                    youtubeUrl: "",
                    custom: [
                        {
                            title: "",
                            fileName: "",
                            customUrl: "",
                            mediaGroupName: ""
                        }
                    ],
                },
                office: {
                    address: "",
                    address2: "",
                    city: "",
                    postCode: "",
                    state: "",
                    country: "",
                    phone: "",
                    email: "",
                    website: ""
                },
                sections: [],
                socialNetworks: []
            }
        }
    })
    const [createdVcards, setCreatedVcards] = useState<number>(0)
    const [apiLoading, setApiLoading] = useState<boolean>(false)

    useEffect(() => {
        if (Number(assets) === 1 && price !== 15.47) setPrice(15.47)
        if (Number(assets) > 1 && Number(assets) < 10) setPrice(15.47)
        if (Number(assets) > 10 && Number(assets) < 50) setPrice(15.47)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets])

    // select presets

    // select assets to buy
    const bouyguesBillingSap = async () => {

        let response: any

        const body = {
            price_per_item: "15.47",
            quantity: Number(assets),
            service: "vcard",
            sap: sap,
            ci: infoCI,
            entity: entity,
            perimeter: perimeter,
        }

        await APIPost<any>(REACT_APP_DASHBOARD_API_URL + "/billing/sap", body, token).then((res: any) => {
            if (res.ok) response = true
        })

        return response
    }

    // create one or multiple vcards
    const handleDropProfilePicture = (imageDrop: any) => {
        APIPostFile<any>(REACT_APP_DASHBOARD_API_URL + "/asset/upload/image?vcard=true", imageDrop[0], token)
            .then((data) => {
                if (data.status === 200) setVcard({...vcard, user: { ...vcard.user, picture: data.parsedBody.asset_url } })
            })
            .catch(() => {
                handleError(t("common:error_upload_image"))
            })
    }

    const resetVcard = () => {
        setVcard({
            uuid: "",
            templateUuid: "",
            ownerUuid: "",
            user: {
                departmentHeadUuid: "",
                departmentUuid: "",
                title: "",
                lastName: "",
                firstName: "",
                jobTitle: "",
                picture: "",
                email: "",
                information: {
                    contact: {
                        phone: "",
                        secondaryPhone: "",
                    },
                    biography: "",
                    medias: {
                        youtubeUrl: "",
                        custom: [
                            {
                                title: "",
                                fileName: "",
                                customUrl: "",
                                mediaGroupName: ""
                            }
                        ],
                    },
                    office: {
                        address: "",
                        address2: "",
                        city: "",
                        postCode: "",
                        state: "",
                        country: "",
                        phone: "",
                        email: "",
                        website: ""
                    },
                    sections: [],
                    socialNetworks: []
                }
            }
        })
    }

    const createVcard = () => {

        const saveBouyguesVCard = async (url: any) => {
            setApiLoading(true)
            let response: any

            const body = {
                sap_vcards: [{ vcard_url: url,  email_holder: vcard.user.email }]
            }

            await APIPost<any>(REACT_APP_DASHBOARD_API_URL + "/vcards/sap", body, token).then((res: any) => {
                if (res.ok) response = true
            }).catch(() => handleError(t("error_post_sap"))).finally(() => setApiLoading(false))

            return response
        }

        let body = {
            ownerUuid: user.uuid,
            templateUuid: process.env.NODE_ENV === "development" ? "e057bd83-0148-4b92-ad68-1ef9bc13e295" : template,
            user: vcard.user,
            canMemberByPass: true
        }

        saveNewVcard({ variables: { newVCard: body } }).then((res: any) => {
            saveBouyguesVCard(res.data.createVCard.url).then((ris: any) => {
                if (ris) {
                    resetVcard()
                    if (createdVcards+1 !== assets) {
                        // reset form and do it again!
                        setCreatedVcards(createdVcards+1)
                        handleSuccess(`${t("vcard_creation_you_have")} ${assets - (createdVcards+1)} ${t("vcard_left_to_create")}.`)
                    } else {
                        // proceed to last step
                        setStep(step+1)
                        handleSuccess(t("vcard_all_created"))
                    }
                } else {
                    handleError(t("error_create_vcard"))
                }
            })
        })
    }

    // Reset app when creation is done
    const resetApp = () => {
        setStep(0)
        setEntity("")
        setPerimeter("")
        setTemplate("")
        setSap("")
        setInfoCI("")
        setAssets(1)
        setTotalPrice(price)
        setCreatedVcards(0)
    }

    // Render main content
    const renderStepper = () => {
        switch(step) {
            case 0: return <BouyguesEntitiesAndPerimeters {...{
                entity, setEntity,
                perimeter, setPerimeter,
                template, setTemplate,
                step, setStep, t,}} />
            case 1: return <BouyguesBuyVcards {...{
                price,
                assets, setAssets,
                totalPrice, setTotalPrice,
                step, setStep, t,}} />
            case 2: return <BouyguesAdditionalInfo {...{
                price, totalPrice,
                step, setStep, t,
                sap, setSap,
                infoCI, setInfoCI,
                bouyguesBillingSap}} />
            case 3: return <BouyguesCreateVcard {...{
                assets,
                vcard, setVcard, createVcard, saveNewLoading,
                createdVcards, setCreatedVcards,
                step, setStep,
                apiLoading,
                handleDropProfilePicture, t }} />
            default: return <BouyguesEntitiesAndPerimeters {...{
                entity, setEntity,
                perimeter, setPerimeter,
                template, setTemplate,
                step, setStep, t }}/>
        }
    }

    if ((step === 3 ||step === 4) && (createdVcards === Number(assets))) return (
        <Grid container justifyContent={"center"} spacing={2} style={{ marginTop: 32 }}>
            <Grid item xs={8}>
                <Typography align={"center"}>
                    {t("vcard_creation_conclusion_text")}
                </Typography>
            </Grid>
            <Grid item xs={8} sx={{ textAlign: "center" }}>
                <Button variant={"contained"} size={"large"} onClick={() => resetApp()} startIcon={<Refresh />}>
                    {t("start_again")}
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <Box sx={{ p: 2 }}>
            <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={2}>
                <Grid xs={12}>
                    <Typography variant={"h4"} component={"p"} sx={{ mt: 3, ml: 2 }}>
                        {t("command_and_create_vcards")}
                    </Typography>
                    <img
                        src={"/assets/modules/bouygues.png"}
                        alt={"bouygues construction logo"}
                        height={200} width={450}
                        style={{ height: 110, width: "auto", marginLeft: 24, marginTop: 16 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    {renderStepper()}
                </Grid>
            </Grid>
        </Box>
    )
}

import React from "react"
// MUI
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {Close, Delete} from "@mui/icons-material";

export default function DeleteTemplateDialog(props: any) {

    const {open, onClose, onDelete, error, loading, t} = props

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            {t("delete_template_dialog_title")}
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={onClose}
                                disabled={loading}
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {t("delete_template_dialog_content")}
                    { error &&
                        <Alert severity={"error"} sx={{ mt: 2}}>
                            {t("delete_template_error_used_template")}
                        </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        className={"defaultButton"}
                        onClick={onClose}
                        disabled={loading}

                    >
                        {t("back")}
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"error"}
                        startIcon={<Delete />}
                        disableElevation
                        onClick={onDelete}
                        disabled={loading || error}
                    >
                        {t("delete_template")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
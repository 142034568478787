import React from "react"
// MUI
import {Divider, Grid, Paper} from "@mui/material";
// Css
import {styles} from "./styles"
// Translation
import {useTranslation} from "react-i18next";
// Regexes
import {regexEmail, regexPhoneNumber} from "../../functions/functions";
// Components
import {TextField, Button} from "../utils";

export default function ContactEditorPanel(props: any) {

    const {contact, setContact} = props;
    const {t} = useTranslation(["contactEditor", "common"])

    const renderGeneralInformations = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.firstName}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, firstName: e.target.value
                            }
                        })}
                        label={t("firstName")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.lastName}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, lastName: e.target.value
                            }
                        })}
                        label={t("lastName")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.phone}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, phone: e.target.value
                            }
                        })}
                        label={t("phone")}
                        error={!regexPhoneNumber(contact.information.phone)}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.email}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, email: e.target.value
                            }
                        })}
                        label={t("email")}
                        error={!regexEmail(contact.information.email)}
                        fullWidth
                    />
                </Grid>
            </>
        )
    }

    const renderBusinessInformations = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        value={contact.information.companyName}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyName: e.target.value
                            }
                        })}
                        label={t("business_name")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        value={contact.information.companyAddress}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyAddress: e.target.value
                            }
                        })}
                        label={t("business_address")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        value={contact.information.companyAddress2}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyAddress2: e.target.value
                            }
                        })}
                        label={t("business_address2")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.companyCity}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyCity: e.target.value
                            }
                        })}
                        label={t("business_city")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.companyPostalCode}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyPostalCode: e.target.value
                            }
                        })}
                        label={t("business_postal_code")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.companyState}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyState: e.target.value
                            }
                        })}
                        label={t("business_state")}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        value={contact.information.companyCountry}
                        onChange={(e: any) => setContact({
                            ...contact, information: {
                                ...contact.information, companyCountry: e.target.value
                            }
                        })}
                        label={t("business_country")}
                        fullWidth
                    />
                </Grid>
            </>
        )
    }

    const renderCommercialEmailsSection = () => {
            return (
                <>
                    <Grid item xs={10}>
                        <i>
                            {contact.information.allowCommercialEmails ?
                                t("user_allowed_commercial_emails")
                                : t("user_disallowed_commercial_emails")
                            }
                        </i>
                    </Grid>
                    {
                        contact.information.allowCommercialEmails &&
                        <Grid item xs={2}>
                            <Button color={"secondary"} onClick={() => {
                                setContact({...contact, information: {
                                        ...contact.information, allowCommercialEmails: false
                                    }})
                            }}>
                                {t("disable_commercial_emails")}
                            </Button>
                        </Grid>
                    }

                </>
            )
    }

    return (
        <Paper variant={"outlined"} sx={styles.paper}>
            <Grid container justifyContent={"flex-start"} spacing={2}>
                {renderGeneralInformations()}
                {renderBusinessInformations()}
                {renderCommercialEmailsSection()}
            </Grid>
        </Paper>
    )
}
import React from "react"
// MUI
import {Box, Grid, Typography, MenuItem, FormHelperText, Alert} from "@mui/material";
// Translation
import {useTranslation} from "react-i18next";
// components
import {Select} from "../../utils"
// API
import {APIGet} from "../../../api/calls";
// context
import {useUser} from "../../../provider/UserProvider";

const {REACT_APP_DASHBOARD_API_URL} = process.env

export default function TemplateQRCodePanel(props: any) {

    const {template, setTemplate, editing} = props
    const {t} = useTranslation(["templateEditor", "common"])
    // User
    const {token} = useUser()

    const [qrcodesTemplates, setQRCodesTemplates] = React.useState<any>([])
    const [domains, setDomains] = React.useState<any>([])
    const [error, setError] = React.useState<any>("")

    React.useEffect(() => {
        fetchTemplates()
        fetchDomains()
    }, [])

    const fetchTemplates = () => {
        if (error === t("qrcodes_templates_error")) setError("")

        APIGet(REACT_APP_DASHBOARD_API_URL + "/qrcodes/templates", token).then((data) => {
            if (data.parsedBody !== undefined) {
                setQRCodesTemplates(data.parsedBody)

            }
        }, () => {
            setError(t("qrcodes_templates_error"))
        });
    }

    const fetchDomains = () => {
        if (error === t("qrcodes_domain_error")) setError("")

        APIGet(REACT_APP_DASHBOARD_API_URL + "/domains", token).then((data) => {
            if (data.status === 200) {
                if (data.parsedBody !== undefined) {
                    setDomains(data.parsedBody)
                }
            }
        }, () => {
            setError(t("qrcodes_domain_error"))
        })
    }

    const qrcodeTemplateSection = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                        {t("qrcode_template")}
                    </Typography>

                    <Typography variant={"body2"}>
                        {t("qrcode_template_intro")}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Select
                        label={t("qrcode_template")}
                        value={template.qrcodeTemplateUuid}
                        onChange={(e: any) => setTemplate({...template, qrcodeTemplateUuid: e.target.value})}
                    >
                        <MenuItem value={""}>{t("default_qrcode_template")}</MenuItem>
                        {
                            qrcodesTemplates && qrcodesTemplates.map((template: any, index: number) => (
                                <MenuItem key={index} value={template.uuid}>
                                    {template.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
            </>
        )
    }

    const domainSection = () => {
        if (domains && domains.length > 0) return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                        {t("qrcode_domain")}
                    </Typography>

                    <Typography variant={"body2"}>
                        {t("qrcode_domain_intro")}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                        <Select
                            label={t("qrcode_domain")}
                            value={template.qrcodeDomainUuid}
                            disabled={editing}
                            onChange={(e: any) => setTemplate({...template, qrcodeDomainUuid: e.target.value})}
                        >
                            <MenuItem value={""}>{t("no_domain")}</MenuItem>
                            {
                                domains && domains.map((domain: any, index: number) => (
                                    <MenuItem key={index} value={domain.domain_id}>
                                        {domain.domain_name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText sx={{ color: editing ? "red" : "grey" }}>
                            {editing ? t("domains_editing_helper") : t("domains_helper_text")}
                        </FormHelperText>
                </Grid>
            </>
        )
        return <></>
    }

    return (
        <>
            <Box className={"designBaseWrapper"}>
                <Grid container justifyContent={"flex-start"} spacing={2}>
                    {
                        error &&
                        <Alert severity={"error"}>
                            {error}
                        </Alert>
                    }
                    {qrcodeTemplateSection()}
                    {domainSection()}
                </Grid>
            </Box>
        </>
    )
}

import React, {createContext, useContext, useState} from "react"
// Error management
import Error from "../views/Error/Error";
import Loading from "../components/utils/Loading/Loading";
// API
import {APIGet} from "../api/calls";
import {useClient} from "./APIProvider";
// Types
import {GenericTypes} from "../types";

const {REACT_APP_DASHBOARD_API_URL} = process.env

const UserContext = createContext<any>(null)

export const useUser = () => useContext(UserContext)

export function UserProvider({ children }: any) {

    const [user, setUser] = React.useState<GenericTypes.SimpleUser | null>(null)
    const [org, setOrg] = React.useState<GenericTypes.Organisation | null>(null)
    const [orgMembers, setOrgMembers] = React.useState<GenericTypes.OrganisationMembers | null>(null)
    const [canCreate, setCanCreate] = useState<boolean>(false)
    // default vcard credits
    const [credits, setCredits] = useState<any>(0)
    // product vCard credits
    // TODO: set to 0 for production
    const [productCredits, setProductCredits] = useState<any>({
        credits_used: 0,
        credits_left: 0,
        credits_bought: 0
    })

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)

    const token = useClient().token

    const userRoles = {
        admin: "ADMIN",
        owner: "OWNER",
        member: "MEMBER"
    }

    React.useEffect(() => {
        if (!!token) fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    React.useEffect(() => {
        if (org && org.organisation_id) fetchOrganisationMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org])


    const fetchUser = () => {
        APIGet<GenericTypes.SimpleUser>(REACT_APP_DASHBOARD_API_URL + "/user", token).then((data) => {
            if (data.parsedBody) {
                setUser(data.parsedBody)
                setLoading(false)
                // Trigger subsequent calls
                fetchOrganisation()
                fetchCredits()
            }
        }, () => {
            setError(true)
        })
    }

    const fetchOrganisation = () => {
        if (token) {
            APIGet(REACT_APP_DASHBOARD_API_URL + "/org", token).then((data: any) => {
                if (data.parsedBody) {
                    setOrg(data.parsedBody)
                    if (data.parsedBody.role === userRoles.owner) setCanCreate(true)
                    else setCanCreate(data.parsedBody.can_user_consume_credits)
                }
            }, () => {
                setError(true)
            })
        }

        return
    }

    const fetchOrganisationMembers = () => {
        if (token) {
            APIGet<GenericTypes.Organisation>(REACT_APP_DASHBOARD_API_URL + "/org/"+ org?.organisation_id + "/users", token).then((data: any) => {
                setOrgMembers(data.parsedBody)
            }, () => {
                setError(true)
            })
        }

        return
    }


    const fetchCredits = () => {
        APIGet<GenericTypes.Credits>(REACT_APP_DASHBOARD_API_URL + "/billing/credits", token).then((data: any) => {
            if (data.parsedBody) {
                let vcardCredits = data.parsedBody.find((app: any) => app.service_name === "vcards")
                setCredits(vcardCredits)
                let _productCredits = data.parsedBody.find((app: any) => app.service_name === "vcard_product")
                if (!!_productCredits) setProductCredits(_productCredits)
            }
        }, () => {
            setError(true)
        })
    }

    return (
        <UserContext.Provider
            value={{
                user,
                org,
                orgMembers,
                credits, setCredits,
                productCredits,
                userRoles,
                token,
                canCreate,
            }}
        >
            {
                loading ?
                    <Loading />
                : error ?
                    <Error contextError />
                : children
            }
        </UserContext.Provider>
    )
}
import React from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    IconButton,
    TextField,
    useTheme, useMediaQuery, Typography, FormControlLabel, Checkbox
} from "@mui/material";
import {Close} from "@mui/icons-material";
// translation
import {useTranslation} from "react-i18next";
// api
import {useMutation} from "@apollo/client";
import {CREATE_CONTACT} from "../../../../api/calls";


export default function VCardContactForm(props: any) {

    const {open, onClose, isSimple, vcardUuid} = props
    const {t} = useTranslation(["common"])
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // Call
    const [contactMe, {data, loading, error}] = useMutation(CREATE_CONTACT, {
        fetchPolicy: "no-cache"
    })

    const [userContact, setUserContact] = React.useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        allowCommercialEmails: false,
    })

    const [businessContact, setBusinessContact] = React.useState({
        companyName: "",
        companyAddress: "",
        companyAddress2: "",
        companyCity: "",
        companyPostalCode: "",
        companyCountry: "",
        companyState: ""
    })

    const onSubmit = () => {

        let _data = isSimple ? userContact : {...userContact, ...businessContact}

        contactMe({
            variables: {
                newContact: {
                    vcardUuid: vcardUuid,
                    completed: false,
                    information: _data
                }
            },
        }).then(() => {
            return
        })
    }

    const renderDialogContent = () => {

        if (data && !error && !loading) {
            return (
                <Typography align={"center"} variant={"h6"} color={"primary"}>
                    {t("vcard_contact_successfully_sent")}
                </Typography>
            )
        }

        return (
            <>
                <Grid container justifyContent={"center"} spacing={3} mt={1}>

                    <Grid item xs={12}>
                        <Divider>
                            {t("my_details")}
                        </Divider>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={loading}
                            fullWidth
                            value={userContact.firstName}
                            required
                            label={t("first_name")}
                            onChange={(e) => setUserContact({...userContact, firstName: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={loading}
                            fullWidth
                            value={userContact.lastName}
                            required
                            label={t("last_name")}
                            onChange={(e) => setUserContact({...userContact, lastName: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={loading}
                            fullWidth
                            value={userContact.email}
                            required
                            label={t("email")}
                            onChange={(e) => setUserContact({...userContact, email: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={loading}
                            fullWidth
                            value={userContact.phone}
                            label={t("phone")}
                            onChange={(e) => setUserContact({...userContact, phone: e.target.value})}
                        />
                    </Grid>

                    {
                        !isSimple &&
                        <>
                            <Grid item xs={12}>
                                <Divider>
                                    {t("my_company_details")}
                                </Divider>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    value={businessContact.companyName}
                                    label={t("company_name")}
                                    onChange={(e) => setBusinessContact({...businessContact, companyName: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    value={businessContact.companyAddress}
                                    label={t("address")}
                                    onChange={(e) => setBusinessContact({...businessContact, companyAddress: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    value={businessContact.companyAddress2}
                                    label={t("address_secondary")}
                                    onChange={(e) => setBusinessContact({...businessContact, companyAddress2: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    value={businessContact.companyCity}
                                    label={t("city")}
                                    onChange={(e) => setBusinessContact({...businessContact, companyCity: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    value={businessContact.companyPostalCode}
                                    label={t("postal_code")}
                                    onChange={(e) => setBusinessContact({...businessContact, companyPostalCode: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    label={t("state")}
                                    value={businessContact.companyState}
                                    onChange={(e) => setBusinessContact({...businessContact, companyState: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={loading}
                                    fullWidth
                                    label={t("country")}
                                    value={businessContact.companyCountry}
                                    onChange={(e) => setBusinessContact({...businessContact, companyCountry: e.target.value})}
                                />
                            </Grid>
                        </>
                    }

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={t("accept_commercial_emails")}
                            checked={userContact.allowCommercialEmails}
                            onChange={() => setUserContact({...userContact, allowCommercialEmails: !userContact.allowCommercialEmails})}
                        />
                    </Grid>

                    {
                        (error && !loading) &&
                        <Typography align={"center"} variant={"h6"} color={"error"}>
                            {t("vcard_contact_error")}
                        </Typography>
                    }
                </Grid>
            </>
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"sm"}
                fullScreen={fullScreen}
                fullWidth
            >
                <DialogTitle>
                    <Box display={"flex"} alignContent={"center"}>
                        <Box flexGrow={1}>
                            {t("vcard_contact_me")}
                        </Box>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    {renderDialogContent()}
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => onSubmit()}
                        variant={"contained"}
                        disableElevation
                        disabled={
                            (data && !error) ||
                            (loading) ||
                            (userContact.firstName === "" || userContact.lastName === "" || userContact.email === "")
                        }
                    >
                        {t("send")}
                    </Button>
                    <Button
                        onClick={onClose}
                        className={"defaultButton"}
                    >
                        {t("back")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
import React from "react"
// MUI
import {
    Autocomplete,
    Box, createFilterOptions,
    Divider,
    Grid,
    MenuItem, TextField,
    Typography
} from "@mui/material";
// coimponents
import {Select} from "../../utils"
// Translation
import {useTranslation} from "react-i18next";
// Css
import {styles} from "./styles"
// Queries
import {useLazyQuery} from "@apollo/client";
import {GET_TEMPLATES} from "../../../api/calls";
// context
import {useUser} from "../../../provider/UserProvider";

export default function GeneralPanel(props: any) {

    const {vcard, setVcard, editing, departments, usingProducts, usingModule} = props;
    const {t} = useTranslation(["vcardEditor", "common"])
    // User data
    const {user, userRoles, orgMembers} = useUser()
    const userType = user.organisation_role
    // Data retrieved
    const [templates, setTemplates] = React.useState<any>([])
    const [displayedTemplates, setDisplayedTemplates] = React.useState<any>([])
    const [displayedOrgMembers, setDisplayedOrgMembers] = React.useState<any>([])
    // Users autocomplete
    const [autocompleteInput, setAutocompleteInput] = React.useState<any>("")
    // Queries & Mutations
    const [getTemplatesCreated, {
        loading: templatesLoading,
        error: templatesError,
    }] = useLazyQuery(GET_TEMPLATES)

    React.useEffect(() => {
        if (user) {
            fetchTemplates()
            // Set Vcard user uuid to yourself first
            if (!editing || vcard.ownerUuid === "") {
                setVcard({...vcard, ownerUuid: user.uuid })
            }
        }
    }, [user])

    React.useEffect(() => {
        if (!!templates && templates.length > 0) {
            if (usingProducts) {
                let _productTemplates = templates.filter((template: any) => template.templateType === "PRODUCT")
                if (!!_productTemplates) setDisplayedTemplates(_productTemplates)
                return
            } else {
                let _displayedTemplates = templates.filter((template: any) => template.templateType === "BUSINESS")
                if (!!_displayedTemplates) setDisplayedTemplates(_displayedTemplates)
            }
        }
    }, [templates])

    React.useEffect(() => {
        if (!!orgMembers && !!user) {
            let _members = [{ email: user.email, uuid: user.uuid }]
            if (userType === userRoles.admin || userType === userRoles.owner) {
                for (const member of orgMembers) {
                    if (member.user_id !== user.uuid)
                        _members.push({ email: member.email, uuid: member.user_id })
                }
            }
            setDisplayedOrgMembers(_members)
        }
    }, [orgMembers, user])

    // Autocomplete specific
    const userFilterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option: any) => option.email
    })

    const fetchTemplates = () => {
        getTemplatesCreated({
            variables:
                {
                    page: 1,
                    perPage: 100
                },
            fetchPolicy: "no-cache"
        }).then((res) => setTemplates(res.data.getTemplates.values))
    }

    if (userType !== userRoles.admin && userType !== userRoles.owner) return <></>

    return (
        <>
            <Box sx={styles.base}>
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("general_title")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant={"body2"}>
                            {t("general_intro")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Select
                            label={t("vcard_template")}
                            value={vcard.templateUuid}
                            disabled={(templatesLoading || templatesError !== undefined || (!!usingModule && usingModule.module === "bouygues"))}
                            error={vcard.templateUuid === ""}
                            onChange={(e: any) => setVcard({ ...vcard, templateUuid: e.target.value})}
                            helperText={t("vcard_template_helper_text")}
                        >
                            <MenuItem value={""} disabled>{t("select_template")}</MenuItem>
                            {
                                displayedTemplates && displayedTemplates.map((template: any, index: number) => (
                                    <MenuItem value={template.uuid} key={index}>
                                        {template.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {
                            (orgMembers && orgMembers.length > 0 && (!!displayedOrgMembers && displayedOrgMembers.length > 0)) &&
                            <Autocomplete
                                value={vcard.ownerUuid}
                                onChange={(_e, value) => {
                                    if (!value?.uuid || !value) setVcard({...vcard, ownerUuid: ""})
                                    else setVcard({...vcard, ownerUuid: value.uuid})
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("vcard_holder")}
                                    helperText={t("vcard_holder_helper_text")}
                                    error={!vcard.ownerUuid}
                                />}
                                renderOption={(props, option) => (<Box component={"li"} {...props}>{option.email}</Box>)}
                                getOptionLabel={(option) => {
                                    if (!option || !vcard.ownerUuid) return ""
                                    if (typeof option === "string") {
                                        let selectedMember = displayedOrgMembers.filter((member: any) => member.uuid === option)
                                        if (!!selectedMember) return selectedMember[0].email
                                        else return ""
                                    }
                                    return option.email
                                }}
                                noOptionsText={t("no_member_found")}
                                inputValue={autocompleteInput}
                                onInputChange={(_e, value) => {
                                    if (!value) setAutocompleteInput("")
                                    setAutocompleteInput(value)
                                }}
                                filterOptions={userFilterOptions}
                                options={displayedOrgMembers}
                                ListboxProps={{sx: { maxHeight: 200 }}}
                                autoHighlight
                            />
                        }
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Select
                            label={t("vcard_department")}
                            value={!!vcard?.user?.departmentUuid ? vcard.user.departmentUuid : undefined}
                            onChange={(e: any) => setVcard({...vcard, user: { ...vcard.user, departmentUuid: e.target.value }})}
                            helperText={t("vcard_department_helper_text")}
                        >
                            <MenuItem value={""}>{t("no_department")}</MenuItem>
                            {
                                departments && departments.map((department: any, index: number) => (
                                    <MenuItem value={department.uuid} key={index}>{department.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

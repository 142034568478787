import React from "react"
// mui
import {Paper, Typography} from "@mui/material";
// components
import {SectionAction, SectionCollapse} from "../../index";

export default function OfficeSection(props: any) {

    const {styles, t, templateData, vcardData, plain} = props;

    let office: any = null

    if (vcardData.information.office.address !== "") {
        office = vcardData.information.office
    } else if (templateData.information.office.address !== "") {
        office = templateData.information.office
    } else return null

    if (office === undefined || office === null) return null

    if ((office.address == null || office.address.trim() === "") &&
        (office.address2 == null || office.address2.trim() === "") &&
        (office.postCode == null || office.postCode.trim() === "") &&
        (office.city == null || office.city.trim() === "") &&
        (office.country == null || office.country.trim() === "") &&
        (office.phone == null || office.phone.trim() === "") &&
        (office.email == null || office.email.trim() === "") &&
        (office.website == null || office.website.trim() === ""))
        return null

    return (
        <Paper sx={styles.paper}>
            <SectionCollapse
                type={"office"}
                title={t("office")}
                {...{styles, plain, t}}
            >
                <SectionAction
                    styles={styles}
                    dataName={t("address")}
                    action={() => {return}}
                    dataContent={(
                        <Typography sx={styles.text}>
                            <b>
                                {(office.address && office.address !== "") && <>{office.address}<br/></>}
                                {(office.address2 && office.address2 !== "") && <>{office.address2}<br/></>}
                                {(office.postCode && office.postCode !== "") && <>{office.postCode}&nbsp;</>}
                                {(office.city && office.city !== "") && <>{office.city}&nbsp;</>}
                                {(office.state && office.state !== "") && <>, {office.state}</>}
                                {(office.country && office.country !== "") && <>, {office.country}</>}
                            </b>
                        </Typography>
                    )}
                    disabled={office.address === ""}
                />
                <SectionAction
                    styles={styles}
                    dataName={t("phone")}
                    action={() => {return}}
                    dataContent={office.phone}
                />
                <SectionAction
                    styles={styles}
                    dataName={t("email")}
                    action={() => {return}}
                    dataContent={office.email}
                />
                <SectionAction
                    styles={styles}
                    dataName={t("website")}
                    action={() => {return}}
                    dataContent={office.website}
                />
            </SectionCollapse>
        </Paper>
    )
}

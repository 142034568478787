import React from "react"
// MUI
import {
    Alert,
    Avatar,
    Box,
    Button, Collapse, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper,
    Typography
} from "@mui/material"
import {Apple, CheckBox, CheckBoxOutlineBlank, Close, Google, Image, Person} from "@mui/icons-material";
// translation
import {useTranslation} from "react-i18next";
// Utils
import Accordion from "../../utils/Accordion/Accordion";
import ColorPicker from "../../utils/ColorPicker/ColorPicker";
import {ImageDropzone} from "../../utils";
import {APIPostFile} from "../../../api/calls";
import {useUser} from "../../../provider/UserProvider";

const {REACT_APP_DASHBOARD_API_URL} = process.env

export default function TemplateWalletsPanel(props: any) {

    const token = useUser().token

    const {template, templateDesign, setTemplateDesign} = props
    const {t} = useTranslation(["templateEditor", "common"])
    // Accordions management
    const [accordionFocus, setAccordionFocus] = React.useState<number | boolean>(0)
    // dialog
    const [openPreview, setOpenPreview] = React.useState<boolean>(false)
    const [previewType, setPreviewType] = React.useState<string>("ios")
    // Image upload state
    const [faviconError, setFaviconError] = React.useState<boolean>(false)

    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }

    // styles
    const styles = {
        previewButton: {
            mt: 1
        }
    }

    const previewStyle = {
        logoPlaceholder: {
            p: 1
        },
        appleBackground: {
            backgroundColor: templateDesign.applePassBackgroundColor,
            p: 3,
            borderRadius: 2
        },
        appleTitle: {
            color: templateDesign.applePassForegroundColor
        },
        appleAvatar: {
            width: 70,
            height: 70,
            border: "4px solid white"
        },
        appleQrcodeSeparator: {
            height: 60
        },
        appleQrcodeWrapper: {
            textAlign: "center"
        },
        appleForceAlignRight: {
            textAlign: "right!important",
            justifyContent: "flex-end!important"
        },
        googleQrCodeWrapper: {
            textAlign: "center",
            borderRadius: "16px !important",
            "& img": {
                borderRadius: "16px !important"
            }
        },
        googleBackground: {
            backgroundColor: templateDesign.googlePassBackgroundColor,
            p: 3,
            borderRadius: 6
        },
        googleTitle: {
            color: "black"
        },
    }

    // Submit image
    const handleDropProfilePicture = (imageDrop: any) => {
        setFaviconError(false)
        APIPostFile<any>(REACT_APP_DASHBOARD_API_URL + "/asset/upload/image?vcard=true", imageDrop[0], token)
            .then((data) => {
                if (!data || data.status !== 200) setFaviconError(true)
                else setTemplateDesign({...templateDesign, googlePassFavIcon: data.parsedBody.asset_url })
            })
            .catch(() => {
                setFaviconError(true)
            })
    }

    const clearFavicon = () => {
        setTemplateDesign({...templateDesign, googlePassFavIcon: "" })
    }

    // Previews
    const walletPreviewDialog = () => {

        const walletPreview = () => {
            switch (previewType) {
                case "ios": return (
                    <>
                        <Paper elevation={0} sx={previewStyle.appleBackground}>
                            <Grid container spacing={2} rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item xs={8}>
                                    <Paper variant={"outlined"} sx={previewStyle.logoPlaceholder}>
                                        <Typography variant={"h5"} component={"p"} fontWeight={700}>
                                            LOGO
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} sx={previewStyle.appleForceAlignRight}>
                                    <Typography sx={previewStyle.appleTitle}>
                                        vCardPro
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant={"h5"} component={"p"} sx={previewStyle.appleTitle}>
                                        James
                                    </Typography>
                                    <Typography variant={"h5"} component={"p"} sx={previewStyle.appleTitle}>
                                        Smith
                                    </Typography>
                                </Grid>
                                <Grid item xs={"auto"} sx={previewStyle.appleForceAlignRight}>
                                    <Avatar variant={"square"} sx={previewStyle.appleAvatar}>
                                        <Person />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={previewStyle.appleTitle}>
                                        {t("wallet_preview_title_placeholder")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={previewStyle.appleQrcodeWrapper}>
                                    <div style={previewStyle.appleQrcodeSeparator} />
                                    <img src={"/assets/qrcodes/unitag-qrcode-example.png"} width={200} height={200} alt={"example"} />
                                </Grid>

                            </Grid>
                        </Paper>
                    </>
                )
                case "android": return (
                    <>
                        <Paper elevation={0} sx={previewStyle.googleBackground}>
                            <Grid container spacing={1} justifyContent={"space-between"}>
                                <Grid item>
                                    <Avatar src={templateDesign.googlePassFavIcon}>
                                        <Image />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography sx={{ ...previewStyle.googleTitle, pt: 1 }}>
                                        vCard Pro - {t("company_name")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <Typography variant={"body1"} component={"p"} sx={previewStyle.googleTitle}>
                                        {t("job_title")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Typography variant={"h4"} component={"p"} sx={previewStyle.googleTitle}>
                                        James Smith
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={previewStyle.googleQrCodeWrapper}>
                                    <img src={"/assets/qrcodes/unitag-qrcode-example.png"} width={200} height={200} alt={"example"} />
                                </Grid>

                                <Grid item xs={12}>
                                    <img
                                        src={template.coverPicture}
                                        width={"100%"} height={"220"} alt={""}
                                        style={{
                                            objectFit: "cover",
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </Paper>
                    </>
                )
                default: return <></>
            }
        }

        return (
            <Dialog
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            {t("wallet_preview")}
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setOpenPreview(false)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={"sm"}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} md={8}>
                                {walletPreview()}
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <Button
                                onClick={() => setPreviewType("ios")}
                                startIcon={<Apple />}
                                variant={previewType === "ios" ? "contained" : "outlined"}
                                color={"primary"}
                                disableElevation
                            >
                                Apple Wallet
                            </Button>
                            &nbsp;
                            <Button
                                onClick={() => setPreviewType("android")}
                                startIcon={<Google />}
                                variant={previewType === "android" ? "contained" : "outlined"}
                                color={"primary"}
                                disableElevation
                            >
                                Google Wallet
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={"defaultButton"} onClick={() => setOpenPreview(false)}>
                                {t("common:back")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Box className={"designBaseWrapper"}>
                <Grid container justifyContent={"flex-start"} spacing={2} alignContent={"center"}>
                    <Grid item xs={12} md={10}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                            {t("wallets_template")}
                        </Typography>

                        <Typography variant={"body2"}>
                            {t("wallets_template_intro")}
                        </Typography>
                    </Grid>



                    <Grid item xs={12} md={2}>
                        <Button
                            color={"secondary"}
                            variant={"contained"}
                            disableElevation
                            sx={styles.previewButton}
                            onClick={() => {
                                setOpenPreview(true)
                            }}
                        >
                            {t("preview")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper variant={"outlined"}>
                            <List>
                                <ListItemButton
                                    onClick={() => {
                                        setTemplateDesign({...templateDesign, buttons: {
                                                ...templateDesign.buttons, displayWallet: templateDesign.buttons.displayWallet === null ? false : !templateDesign.buttons.displayWallet
                                            }})
                                    }}
                                >
                                    <ListItemIcon>
                                        {(templateDesign.buttons.displayWallet === null || templateDesign.buttons.displayWallet === undefined || templateDesign.buttons.displayWallet) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t("composition_allow_wallets")}
                                        secondary={t("composition_allow_wallets_subtitle")}
                                    />
                                </ListItemButton>
                            </List>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion
                            title={t("apple_wallet")}
                            expanded={accordionFocus === 0}
                            onChange={handleAccordionFocus(0)}
                            icon={<Apple />}
                        >
                            <Grid container justifyContent={"flex-start"} spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant={"body1"} fontWeight={500}>{t("font_color")}</Typography>
                                    <ColorPicker
                                        initialColor={templateDesign.applePassForegroundColor}
                                        onChangeComplete={(color: any) =>
                                            setTemplateDesign({...templateDesign, applePassForegroundColor: color})
                                        }
                                        label={t("primary_color")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant={"body1"} fontWeight={500}>{t("background_color")}</Typography>
                                    <ColorPicker
                                        initialColor={templateDesign.applePassBackgroundColor}
                                        onChangeComplete={(color: any) =>
                                            setTemplateDesign({...templateDesign, applePassBackgroundColor: color})
                                        }
                                        label={t("primary_color")}
                                    />
                                </Grid>

                            </Grid>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion
                            title={t("google_wallet")}
                            expanded={accordionFocus === 1}
                            onChange={handleAccordionFocus(1)}
                            icon={<Google />}
                        >

                            <Grid container justifyContent={"flex-start"} spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant={"body1"} fontWeight={500}>{t("background_color")}</Typography>
                                    <ColorPicker
                                        initialColor={templateDesign.googlePassBackgroundColor}
                                        onChangeComplete={(color: any) =>
                                            setTemplateDesign({...templateDesign, googlePassBackgroundColor: color})
                                        }
                                        label={t("primary_color")}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant={"body1"} fontWeight={500}>Favicon</Typography>
                                    <ImageDropzone
                                        onDrop={handleDropProfilePicture}
                                        label={t("common:import_your_files")}
                                        success={templateDesign.googlePassFavIcon}
                                        onClear={() => clearFavicon()}
                                    />
                                    <br/>
                                    <Collapse in={faviconError}>
                                        <Alert severity={"error"}>
                                            {t("common:file_upload_error")}
                                        </Alert>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                </Grid>
            </Box>

            {walletPreviewDialog()}
        </>
    )
}
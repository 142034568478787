import React from "react"
// MUI
import {
    Alert,
    Avatar,
    Box, Collapse,
    Divider,
    FormControl,
    Grid, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {AlternateEmail, LocalPhone, Notes, Person, Phone, PhoneAndroid, RememberMeOutlined} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Css
import {styles} from "./styles"
// Components
import {InteractiveAdornment, ImageDropzone, Accordion} from "../../utils";
import {ContactMediasSection} from "../../Forms";
// api
import {APIPostFile} from "../../../api/calls";
// context
import {useUser} from "../../../provider/UserProvider"
// regexes
import {regexEmail, regexPhoneNumber} from "../../../functions/functions";
import {Editor} from "primereact/editor";

const {REACT_APP_DASHBOARD_API_URL} = process.env


export default function ProfilePanel(props: any) {

    const {vcard, setVcard, permissions, userType, userRoles, usingProducts, biography, setBiography} = props;
    const {t} = useTranslation(["vcardEditor", "common"])
    const token = useUser().token
    // Accordions management
    const [accordionFocus, setAccordionFocus] = React.useState<number | boolean>(0)
    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }
    // Upload error
    const [profileUploadError, setProfileUploadError] = React.useState<boolean>(false)

    // Image management
    const handleDropProfilePicture = (imageDrop: any) => {
        setProfileUploadError(false)
        APIPostFile<any>(REACT_APP_DASHBOARD_API_URL + "/asset/upload/image?vcard=true", imageDrop[0], token)
            .then((data) => {
                if (!data || data.status !== 200) {
                    setProfileUploadError(true)
                } else {
                    setVcard({...vcard, user: { ...vcard.user, picture: data.parsedBody.asset_url } })
                }
            })
            .catch(() => {
                setProfileUploadError(true)
            })
    }

    const renderProfileSection = () => {

        const clearProfilePicture = () => {
            setVcard({...vcard, user: { ...vcard.user, picture: "" }})
        }

        return (
            <Accordion
                title={t("user_profile")}
                expanded={accordionFocus === 0}
                onChange={handleAccordionFocus(0)}
                icon={<Person />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_general")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={3} display={usingProducts ? "none" : "inline-flex"}>
                        <FormControl fullWidth>
                            <InputLabel id={"gender"}>{t("displayed_gender")}</InputLabel>
                            <Select
                                value={vcard.user.title}
                                onChange={(e) => setVcard({...vcard, user: {...vcard.user, title: e.target.value}})}
                                label={t("displayed_gender")}
                                labelId={"gender"}
                                disabled={!permissions.name && (userType === userRoles.member)}
                            >
                                <MenuItem value={""}>{t("no_display")}</MenuItem>
                                <MenuItem value={"mr"}>{t("gender_mister")}</MenuItem>
                                <MenuItem value={"ms"}>{t("gender_miss")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label={usingProducts ? t("product_name") : t("firstName")}
                            value={vcard.user.firstName}
                            onChange={(e) => setVcard({...vcard, user: {...vcard.user, firstName: e.target.value}})}
                            fullWidth
                            disabled={!permissions.name && (userType === userRoles.member)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} display={usingProducts ? "none" : "inline-flex"}>
                        <TextField
                            label={t("lastName")}
                            value={vcard.user.lastName}
                            onChange={(e) => setVcard({...vcard, user: {...vcard.user, lastName: e.target.value}})}
                            fullWidth
                            disabled={!permissions.name && (userType === userRoles.member)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            label={usingProducts ? t("complementary_text") : t("title")}
                            value={vcard.user.jobTitle}
                            onChange={(e) => setVcard({...vcard, user: {...vcard.user, jobTitle: e.target.value}})}
                            fullWidth
                            disabled={!permissions.position && (userType === userRoles.member)}
                        />
                    </Grid>
                    {!usingProducts &&
                        <Grid item xs={12} lg={3}>
                            <TextField
                                label={usingProducts ? t("complementary_text") : t("title_alt")}
                                value={vcard.user.jobTitleAdditional}
                                onChange={(e) => setVcard({...vcard, user: {...vcard.user, jobTitleAdditional: e.target.value}})}
                                fullWidth
                                disabled={!permissions.position && (userType === userRoles.member)}
                            />
                        </Grid>
                    }
                    {!usingProducts &&
                        <Grid item xs={12} lg={3}>
                            <TextField
                                label={ t("business_entity")}
                                value={vcard.user.businessEntity}
                                onChange={(e) => setVcard({...vcard, user: {...vcard.user, businessEntity: e.target.value}})}
                                fullWidth
                                disabled={!permissions.position && (userType === userRoles.member)}
                            />
                        </Grid>
                    }

                    {
                        (!(!permissions.photo && (userType === userRoles.member))) &&
                        <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                                    {t("profile_picture")}
                                </Typography>
                                <Typography variant={"body2"}>
                                    {t("profile_picture_helper_text")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} justifyContent={{ xs: "center", md: "flex-start" }}>
                                    <Grid item xs={8} sm={6} md={2} alignContent={"center"} alignItems={"center"}>
                                        <Box sx={styles.avatarWrapper}>
                                            <Avatar src={vcard.user.picture} sx={styles.avatar}>
                                                <RememberMeOutlined sx={{ fill: "#909090" }} />
                                            </Avatar>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={10}>
                                        <ImageDropzone
                                            onDrop={handleDropProfilePicture}
                                            label={t("common:import_your_files")}
                                            success={vcard.user.picture}
                                            onClear={() => clearProfilePicture()}
                                        />
                                        <br/>
                                        <Collapse in={profileUploadError}>
                                            <Alert severity={"error"}>
                                                {t("common:file_upload_error")}
                                            </Alert>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
            </Accordion>
        )
    }

    const renderContactSection = () => {
        if (usingProducts) return <></>
        return (
            <Accordion
                title={t("user_contact")}
                expanded={accordionFocus === 1}
                onChange={handleAccordionFocus(1)}
                icon={<Phone />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_contacts")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label={t("mobile_phone")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <PhoneAndroid />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InteractiveAdornment />
                                )
                            }}
                            value={vcard.user.information.contact.secondaryPhone}
                            onChange={(e) =>
                                setVcard({...vcard, user: { ...vcard.user, information: {
                                            ...vcard.user.information, contact: {
                                                ...vcard.user.information.contact, secondaryPhone: e.target.value
                                            }
                                        }}})
                            }
                            disabled={!permissions.position && (userType === userRoles.member)}
                            error={!regexPhoneNumber(vcard.user.information.contact.secondaryPhone)}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label={t("phone")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <LocalPhone />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InteractiveAdornment />
                                )
                            }}
                            value={vcard.user.information.contact.phone}
                            onChange={(e) =>
                                setVcard({...vcard, user: { ...vcard.user, information: {
                                    ...vcard.user.information, contact: {
                                            ...vcard.user.information.contact, phone: e.target.value
                                        }
                                }}})
                            }
                            disabled={!permissions.telephone && (userType === userRoles.member)}
                            error={!regexPhoneNumber(vcard.user.information.contact.phone)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label={t("email")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <AlternateEmail />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InteractiveAdornment />
                                )
                            }}
                            value={vcard.user.email}
                            onChange={(e) =>
                                setVcard({...vcard, user: { ...vcard.user, email: e.target.value }})
                            }
                            error={!regexEmail(vcard.user.email)}
                            disabled={!permissions.position && (userType === userRoles.member)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("profile_custom_contacts")}
                        </Typography>
                        <Typography variant={"body2"}>
                            {t("profile_custom_contacts_helper_text")}
                        </Typography>
                    </Grid>

                    {
                        (!(!permissions.customContact && (userType === userRoles.member))) &&
                        <Grid item xs={12}>
                            <ContactMediasSection
                                data={vcard.user.information.contact}
                                setData={(data: any) => setVcard({
                                    ...vcard, user: { ...vcard.user, information: { ...vcard.user.information, contact: data }}
                                })}
                            />
                        </Grid>
                    }
                </Grid>
            </Accordion>
        )
    }

    const renderBiographySection = () => {

        // Rich text setup
        const renderHeader = () => {
            return (
                <span className="ql-formats">
                    <button className="ql-bold" aria-label="Bold"></button>
                    <button className="ql-italic" aria-label="Italic"></button>
                    <button className="ql-underline" aria-label="Underline"></button>
                    <button className="ql-align" aria-label="Align Left"></button>
                    <button className="ql-align" value={"center"} aria-label="Align Center"></button>
                    <button className="ql-align" value={"right"} aria-label="Align Right"></button>
                </span>
            );
        };

        const header = renderHeader();

        return (
            <Accordion
                title={usingProducts ? t("product_text") : t("user_biography")}
                expanded={accordionFocus === 2}
                onChange={handleAccordionFocus(2)}
                icon={<Notes />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                    <Grid item xs={12}>
                        <Editor
                            disabled={!vcard.templateUuid}
                            headerTemplate={header}
                            value={biography}
                            onTextChange={(e: any) => {
                                setBiography(e.htmlValue)
                            }}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    return (
        <>
            <Box sx={styles.base}>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {usingProducts ? t("product_profile_title") : t("profile_title")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant={"body2"}>
                            {usingProducts ? t("product_profile_intro") : t("profile_intro")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                {renderProfileSection()}
                {renderContactSection()}
                {renderBiographySection()}
            </Box>
        </>
    )
}
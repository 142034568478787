import React from "react"
// MUI
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close, Delete} from "@mui/icons-material";

export default function DeleteContactDialog(props: any) {

    const {open, onClose, onDelete, loading, t} = props

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>
                    <Box display={"flex"} alignContent={"center"}>
                        <Box flexGrow={1}>
                            {t("delete_contact")}
                        </Box>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {t("delete_contact_text")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        className={"defaultButton"}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={onDelete}
                        color={"error"}
                        variant={"contained"}
                        disableElevation
                        startIcon={<Delete />}
                        disabled={loading}
                    >
                        {t("delete")}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
import React from "react"
// MUI
import {Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
// API
import {useLazyQuery} from "@apollo/client";
import {GET_CONTACT} from "../../../api/calls";
// misc
import {iso8601ToHumanString} from "../../../functions/functions";

export default function ContactView(props: any) {

    const {open, onClose, contactId, vcardHolder, t} = props
    const [contact, setContact] = React.useState<any>()

    const [getContact, {
        data: contactData,
    }] = useLazyQuery(GET_CONTACT, { fetchPolicy: "no-cache" })

    React.useEffect(() => {
        if (contactId) {
            getContact({ variables: { contactUuid: contactId }}).then()
        }
    }, [props])

    React.useEffect(() => {
        if (contactData && contactData.getContact) {
            setContact(contactData.getContact)
        }
    }, [contactData])

    if (contact) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>
                    <Box display={"flex"} alignContent={"center"}>
                        <Box flexGrow={1}>
                            {contact.information.firstName}&nbsp;{contact.information.lastName}
                        </Box>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent={"flex-start"} spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                <i>{t("contacted_on")} {iso8601ToHumanString(contact.createdAt)} {vcardHolder && <>{t("by")} {vcardHolder}</>}</i>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <b>{contact.information.firstName}&nbsp;{contact.information.lastName}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant={"body2"} sx={{ mb: "-4px"}}>
                                <b>{t("common:phone")}</b>
                            </Typography>
                            <Typography variant={"body1"}>
                                {contact.information.phone ? contact.information.phone : t("no_contact_data")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant={"body2"} sx={{ mb: "-4px"}}>
                                <b>{t("common:email")}</b>
                            </Typography>
                            <Typography variant={"body1"}>
                                {contact.information.email}
                            </Typography>
                        </Grid>

                        {
                            (contact && contact.information.companyName) &&
                            <>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"body2"} sx={{ mb: "-4px"}}>
                                        <b>{t("common:company_name")}</b>
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {contact.information.companyName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"body2"} sx={{ mb: "-4px"}}>
                                        <b>{t("common:address")}</b>
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {contact.information.companyAddress}&nbsp;
                                        {
                                            (contact && contact.information.companyAddress2) &&
                                            <>, {contact.information.companyAddress2}</>
                                        }
                                        <br/>
                                        {contact.information.companyPostalCode}&nbsp;
                                        {contact.information.companyCity}
                                        {
                                            (contact && contact.information.companyState) &&
                                            <>,&nbsp;{contact.information.companyState}</>
                                        }
                                        {
                                            (contact && contact.information.companyCountry) &&
                                            <>,&nbsp;{contact.information.companyCountry}</>
                                        }
                                    </Typography>
                                </Grid>
                            </>
                        }

                        {
                            contact.information.commentSection &&
                            <Grid item md={10}>
                                <Typography variant={"body2"} sx={{ mb: "-4px"}}>
                                    <b>{t("common:comment")}</b>
                                </Typography>
                                <Typography variant={"body1"}>
                                    {contact.information.commentSection}
                                </Typography>
                            </Grid>
                        }

                        <Grid item md={10}>
                            <i>
                                {contact.allowCommercialEmails ?
                                    t("user_allowed_commercial_emails")
                                    : t("user_disallowed_commercial_emails")
                                }
                            </i>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    return <></>
}
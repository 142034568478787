import React from "react"
// MUI
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import {Close, Delete} from "@mui/icons-material";

export default function DeleteDepartmentDialog(props: any) {

    const {open, onClose, onDelete, loading, error, styles, t} = props

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={"sm"}
            fullWidth
        >
            <DialogTitle>
                <Box display={"flex"} alignContent={"center"}>
                    <Box flexGrow={1}>
                        {t("delete_department")}
                    </Box>
                    <IconButton
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t("delete_department_text")}
                </Typography>
                { (error && error.toString().includes("violates foreign key constraint")) &&
                    <Alert severity={"error"} sx={{ mt: 2 }}>
                        {t("department_delete_error_on_use")}
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    sx={styles.defaultButton}
                >
                    {t("cancel")}
                </Button>
                <Button
                    onClick={onDelete}
                    color={"error"}
                    variant={"contained"}
                    disableElevation
                    startIcon={<Delete />}
                    disabled={loading}
                >
                    {t("delete")}
                </Button>

            </DialogActions>
        </Dialog>
    )
}
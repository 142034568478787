import React from "react"
// MUI
import {
    Button,
    Typography,
    Grid,
    Box,
    Divider, Alert
} from "@mui/material";
import {Add} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Queries
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_TEMPLATE, DELETE_TEMPLATE, GET_TEMPLATE, GET_TEMPLATES, MODIFY_TEMPLATE} from "../../../api/calls";
// Components
import TemplateCard from "../../TemplatesPanel/TemplateCard/TemplateCard";
import {NameTemplateDialog, DeleteTemplateDialog} from "../../Dialogs";
import {Loading} from "../../utils";
import {useModule} from "../../../provider/ClientModuleProvider";

export default function TemplatesPanel() {

    const {t} = useTranslation(["common"])
    const {usingProducts} = useModule()
    // Templates list
    const [templates, setTemplates] = React.useState<any[]>([])
    // New template
    const [nameTemplateDialog, setNameTemplateDialog] = React.useState({ open: false, copy: false, name: "", uuid: "", product: false })
    // Delete Template
    const [deleteTemplateDialog, setDeleteTemplateDialog] = React.useState<any>({ open: false, template: "" })

    const [getTemplatesCreated, {
        data: listData,
        loading: listLoading,
        error: listError,
    }] = useLazyQuery(GET_TEMPLATES, {
        variables:
            {
                page: 1,
                perPage: 100
            },
        fetchPolicy: "no-cache"
    })

    const [deleteTemplate, {
        loading: deletionLoading,
        error: deletionError,
        reset: deletionReset
    }] = useMutation(DELETE_TEMPLATE)

    const [getTemplate, {
        loading: getTemplateLoading,
        error: getTemplateError
    }] = useLazyQuery(GET_TEMPLATE)

    const [createTemplate, {
        loading: createTemplateLoading,
        error: createTemplateError
    }] = useMutation(CREATE_TEMPLATE)

    const [renameTemplate, {
        loading: renameTemplateLoading,
        error: renameTemplateError
    }] = useMutation(MODIFY_TEMPLATE)

    React.useEffect(() => {
        fetchTemplates()
    }, [])

    React.useEffect(() => {
        if (listData) setTemplates(listData.getTemplates.values)
    }, [listData])

    const fetchTemplates = () => {
        getTemplatesCreated().then()
    }

    const copyTemplate = () => {
        getTemplate({
            variables: {
                templateUuid: nameTemplateDialog.uuid
            }
        }).then(data => {
            createTemplate({
                variables: {
                    newTemplate: {
                        ...data.data.getTemplate,
                        name: nameTemplateDialog.name
                    }
            }}).then(() => fetchTemplates())
        });

        setNameTemplateDialog({ open: false, copy: false, name: "", uuid: "", product: false })
    }

    const handleDeleteTemplate = () => {
        deleteTemplate({ variables: { templates_uuids: [deleteTemplateDialog.template] }})
            .then(() => {
                setDeleteTemplateDialog({ open: false, template: ""})
                fetchTemplates()
            }).catch(() => {
                return
        } )
    }

    const handleChangeTemplateName = (template: any, newName: string) => {
        getTemplate({
            variables: {
                templateUuid: template.uuid
            }
        }).then(data => {
            renameTemplate({
                variables: {
                    template: {
                        ...data.data.getTemplate,
                        uuid: template.uuid,
                        name: newName,
                    }
                }
            })
            .then(() => fetchTemplates())
        })
    }

    return (
        <Box sx={{ p: 2 }}>
            <Grid container justifyContent={{ xs: "center", md: "flex-start" }} rowSpacing={3} columnSpacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} component={"p"}>
                        {t("vcards_templates")}
                    </Typography>
                    <Typography>
                        {t("vcards_templates_intro")}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    <Button
                        variant={"contained"}
                        disableElevation
                        onClick={() => setNameTemplateDialog({ ...nameTemplateDialog, open: true, product: false })}
                        startIcon={<Add />}
                        sx={{ mr: 1 }}
                    >
                        {t("create_template")}
                    </Button>
                    <Button
                        variant={"contained"}
                        disableElevation
                        onClick={() => setNameTemplateDialog({ ...nameTemplateDialog, open: true, product: true })}
                        startIcon={<Add />}
                        disabled={!usingProducts}
                    >
                        {t("create_product_template")}
                    </Button>
                </Grid>
                {/*<Grid item xs={"auto"}>*/}
                {/*    /!*TODO: add documentation link*!/*/}
                {/*    <Button*/}
                {/*        variant={"outlined"}*/}
                {/*        disableElevation*/}
                {/*        component={"a"}*/}
                {/*        href={""}*/}
                {/*    >*/}
                {/*        {t("templates_documentation")}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {
                    (getTemplateError || renameTemplateError || createTemplateError) &&
                    <Grid item xs={12}>
                        <Alert severity={"error"}>
                            {
                                listError ? t("template_list_error") :
                                getTemplateError ? t("template_get_error") :
                                renameTemplateError ? t("template_rename_error") :
                                createTemplateError ? t("template_create_error") : ""
                            }
                        </Alert>
                    </Grid>
                }

                {
                    listLoading ?
                        <Grid item xs={12} textAlign={"center"}>
                            <Loading />
                        </Grid>
                    : ((templates && templates.length < 1) || !templates) ?
                        <Grid item xs={12}>
                            <Typography fontWeight={600} align={"center"}>
                                {t("no_templates_created")}
                            </Typography>
                        </Grid>
                    : templates ? templates.slice(0).reverse().map((template: any, index: number) => (
                        <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                            <TemplateCard
                                template={template}
                                onDelete={() => setDeleteTemplateDialog({ open: true, template: template.uuid })}
                                onDuplicate={() => setNameTemplateDialog({ open: true, copy: true, name: template.name, uuid: template.uuid, product: false })}
                                onRename={(newName: string) => handleChangeTemplateName(template, newName)}
                                loading={renameTemplateLoading || getTemplateLoading}
                                t={t}
                            />
                        </Grid>
                    ))
                    : null
                }
            </Grid>

            <NameTemplateDialog
                open={nameTemplateDialog.open}
                onClose={() => { setNameTemplateDialog({ open: false, copy: false, name: "", uuid: "", product: false }) }}
                name={nameTemplateDialog.name}
                setName={(e: any) => setNameTemplateDialog({ ...nameTemplateDialog, name: e })}
                copy={nameTemplateDialog.copy}
                onCopy={copyTemplate}
                loading={createTemplateLoading}
                usingProducts={nameTemplateDialog.product}
                {...{t}}
            />

            <DeleteTemplateDialog
                open={deleteTemplateDialog.open}
                onClose={() => {
                    setDeleteTemplateDialog({open: false, template: ""})
                    deletionReset()
                }}
                onDelete={handleDeleteTemplate}
                loading={deletionLoading}
                error={deletionError}
                t={t}
            />
        </Box>
    )
}

import React from "react"
// MUI
import {
    Grid,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Button
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
// components
import {Select} from "../../utils"
// Translation
import {useTranslation} from "react-i18next";
// styles
import {styles} from "./styles"
// regexes
import {regexValidUrl} from "../../../functions/functions";

export default function LegacySocialMediaSection(props: any) {

    const {data, setData} = props

    const {t} = useTranslation(["templateEditor", "common"])

    const [socialMedias, setSocialMedias] = React.useState(data.socialNetworks)

    const socialMediaTypes = [
        { name: "Other", index: 0 },
        { name: "Dailymotion", index: 1 },
        { name: "Facebook", index: 2 },
        { name: "Flickr", index: 3 },
        { name: "Github", index: 4 },
        { name: "Instagram", index: 5 },
        { name: "LinkedIn", index: 6 },
        { name: "Pinterest", index: 7 },
        { name: "Reddit", index: 8 },
        { name: "Snapchat", index: 9 },
        { name: "Soundcloud", index: 10 },
        { name: "Tiktok", index: 11 },
        { name: "Twitch", index: 12 },
        { name: "Twitter", index: 13 },
        { name: "Vimeo", index: 14 },
        { name: "Youtube", index: 15 },
    ]

    React.useEffect(() => {
        if (socialMedias !== data.socialNetworks) {
            setData({...data, socialNetworks: socialMedias})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socialMedias])

    const addSocialMedia = () => {
        if (!socialMedias) {
            setSocialMedias([{ name: "Other", externalUrl: "" }])
            return
        }
        setSocialMedias([...socialMedias, { name: "Other", externalUrl: "" }])
    }

    const handleSocialMediaType = (index: number, event: any) => {
        let _socialMedias = socialMedias
        _socialMedias.splice(index, 1, { name: event.target.value, externalUrl: _socialMedias[index].externalUrl})
        setSocialMedias([..._socialMedias])
    }

    const handleSocialMediaUrl = (index: number, event: any) => {
        let _socialMedias = socialMedias
        _socialMedias.splice(index, 1, { name: _socialMedias[index].name, externalUrl: event.target.value})
        setSocialMedias([..._socialMedias])
    }

    const deleteSocialMedia = (index: number) => {
        let _socialMedias = socialMedias
        _socialMedias.splice(index, 1)
        setSocialMedias([..._socialMedias])
    }

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                <Grid item xs={12}>
                    {t("social_media_intro")}
                </Grid>

                <Grid item xs={12}>
                    <Button
                        onClick={addSocialMedia}
                        variant={"contained"}
                        color={"secondary"}
                        size={"small"}
                        startIcon={<Add />}
                        disableElevation
                    >
                        {t("social_media_add")}
                    </Button>
                </Grid>

                {
                    socialMedias && socialMedias.map((socialMedia: any, index: number) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <Paper variant={"outlined"} sx={styles.paper}>
                                    <Grid container justifyContent={"center"} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                                <Select
                                                    label={t("social_media_type")}
                                                    labelId={`socialMediaLabel${index}`}
                                                    value={socialMedia.name}
                                                    size={"small"}
                                                    onChange={(e: any) => handleSocialMediaType(index, e)}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 250
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {
                                                        socialMediaTypes && socialMediaTypes.map((type: any) => (
                                                            <MenuItem value={type.name} key={type.index}>
                                                                {
                                                                    type.name === "Other" ? t("other") :
                                                                    type.name === "Twitter" ? "Twitter/X" :
                                                                    type.name
                                                                }
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                fullWidth
                                                size={"small"}
                                                label={t("link")}
                                                value={socialMedia.externalUrl}
                                                onChange={(e) => handleSocialMediaUrl(index, e)}
                                                error={!regexValidUrl(socialMedia.externalUrl)}
                                            />
                                        </Grid>
                                        <Grid item xs={'auto'} md={1} textAlign={"center"}>
                                            <Tooltip title={t("social_media_delete")} placement={"top"} arrow>
                                                <IconButton
                                                    size={"small"}
                                                    color={"error"}
                                                    onClick={() => deleteSocialMedia(index)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}
import {Link} from "react-router-dom";
// MUI
import {Button, Container, Divider, Grid, Stack, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
// TRANSLATION
import {useTranslation} from "react-i18next";

function Error(props: any) {
  const {t} = useTranslation(['common']);

  return (
    <Container maxWidth={"lg"} sx={{ mt: 10 }}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Stack spacing={3}>
            {
              props.contextError ?
                  <>
                    <Close sx={{ fontSize: 72, mx: 'auto' }} color={"error"} />
                    <Typography variant={"h4"} component={"h1"}>
                      {t("error_context")}
                    </Typography>
                    <Typography variant={"body1"}>
                        {t("error_context_text")}
                    </Typography>
                      <Button onClick={() => window.location.reload()} variant={"text"}>
                          {t("reload")}
                      </Button>
                  </>
                :
                  <>
                    <Typography variant={"h2"} component={"h1"}>
                      {t("error_404")}
                    </Typography>
                    <Typography variant={"body1"}>
                      {t("error_404_text")}
                    </Typography>
                    <Divider />
                    <Button variant={"contained"} component={Link} to={"/"}>
                      {t("back_to_homepage")}
                    </Button>
                  </>
            }
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Error;

import React from 'react';
import {Button as MaterialButton} from '@mui/material';

type Props = {
    id?: string,
    className?: string,
    // necessary props
    href?: string,
    rel?: string,
    target?: string,
    "aria-label"?: string,
    "aria-haspopup"?: string,
    "aria-controls"?: string,
    "aria-expanded"?: string | boolean,
    type?: any,
    onClick?: any,
    value?: any,
    disabled?: boolean,
    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
    color?: any,
    variant?: any,
    size?: any,
    disableElevation?: boolean,
    // component: 'Link' from 'react-router-dom' props
    component?: any,
    to?: any,
    // testing variables
    'data-testid'?: string,
    // children
    children: React.ReactNode[] | string[] | React.ReactNode | string,
    // customization & custom props
    primary?: boolean, // color: 'primary'
    secondary?: boolean, // color: 'secondary'
    delete?: boolean, // color: 'error'
    small?: boolean, // size
    large?: boolean, // size
    text?: boolean, // sets MUI variant to "text" (contained per default)
    outlined?: boolean, // sets MUI variant to "outlined" (contained per default)
    fullWidth?: boolean,
    spacing?: boolean, // sets margin-x to 8px
    dense?: boolean, // sets margin-y to 0 else 8px
    lowercase?: boolean,

    sx?: object,
}

const color = (props: any) => {
    if (props.primary) { return "primary" }
    if (props.secondary) { return "secondary" }
    if (props.delete) { return "error" }
    return "inherit";
}

const size = (props: any) => {
    if (props.small) { return "small" }
    if (props.large) { return "large" }
    return "medium";
}

const variant = (props: any) => {
    if (props.text) { return "text" }
    if (props.outlined) { return "outlined"}
    return "contained";
}

const style = (props?: any) => {
    return {
        my: props?.dense ? 0 : 1,
        mx: props?.spacing && 1,
        textTransform: props?.lowercase && "none",
        ...props?.sx
    }
}

export default function Button(props: Props) {

    const {
        id,
        className,
        href,
        onClick,
        disabled,
        startIcon,
        endIcon,
        fullWidth,
        component,
        type,
        children,
        to,
        rel,
        target,
        value,
        'aria-label': ariaLabel,
        'data-testid': dataTestId,
        'aria-haspopup': ariaHaspopup,
        'aria-expanded': ariaExpanded,
        'aria-controls': ariaControls,
    } = props;


    return (
        <MaterialButton
            id={id}
            className={className}
            // MUI
            size={size(props)}
            variant={variant(props)}
            color={color(props)}
            disableElevation
            fullWidth={fullWidth}
            // MUI icons
            startIcon={startIcon}
            endIcon={endIcon}
            // as <a>
            href={href}
            rel={rel}
            target={target}
            // as {Link}
            component={component}
            to={to}
            // classic button
            type={type}
            onClick={onClick}
            disabled={disabled}
            value={value}
            // other
            aria-label={ariaLabel}
            aria-haspopup={ariaHaspopup}
            aria-expanded={ariaExpanded}
            aria-controls={ariaControls}
            data-testid={dataTestId}
            sx={{...style(props)}}
        >
            {children}
        </MaterialButton>
    )
}
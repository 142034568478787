import React from "react"
import {TemplateClassic, TemplateService, TemplateMinimalist, TemplateModern, TemplateTower} from "../Templates";
// Types
import {VcardTypes} from "../../../types";



export default function VcardsProvider(props: VcardTypes.VcardProviderProps) {

    const {
        preview,
        template,
        product,
        vcard, biography,
        templateBase,
        t
    } = props;

    // vcard.user
    const placeholderVcardData = {
        title: "",
        lastName: product ? "" : "Smith",
        firstName: product ? t("preview_product_name") : "James",
        jobTitle: product ? t("preview_product_subtitle") : "Webdesigner",
        picture: product ? "/assets/templates/profilePictures/product.png" : "",
        email: "",
        information: {
            contact: {
                phone: "",
                secondaryPhone: "",
                other: [
                    { name: "", id: "" }
                ]
            },
            biography: t("vcard_biography_preview_placeholder"),
            medias: {
                youtubeUrl: "",
                custom: [
                    {
                        title: "",
                        fileName: "",
                        customUrl: "",
                        mediaGroupName: ""
                    }
                ],
            },
            office: {
                address: "",
                address2: "",
                city: "",
                postCode: "",
                country: "",
                phone: "",
                email: "",
                website: ""
            },
            sections: [
                {
                    name: product ? t("preview_product_section_title") : "",
                    profiles: [
                        {
                            firstName: product ? t("preview_product_section_headline") : "",
                            lastName: "",
                            phone: "",
                            email: "",
                            title: "",
                            jobTitle: product ? t("preview_product_section_subtitle") : "",
                            description: product ? t("preview_product_section_description") : "",
                            link: product ? "#" : "",
                            closingText: "",
                            address: ""
                        }
                    ]
                }
            ],
            socialNetworks: []
        }
    }

    // template placeholder
    const placeholderTemplateData = {
        name: "placeholder",
        qrcodeTemplateUuid: "",
        qrcodeDomainUuid: "",
        base: templateBase ? templateBase : "modern",
        customisation: {
            backgroundExt: {backgroundColor: "#eeeeee", isGradient: false, gradientColor1: "#afc928", gradientColor2: "#36bbed"},
            backgroundInt: {backgroundColor: "#ffffff", isGradient: false, gradientColor1: "", gradientColor2: ""},
            fontNameHolder: {fontName: "Fjalla One", fontColor: "#afc928"},
            fontCardTitles: {fontName: "Fjalla One", fontColor: "#000000"},
            fontCardTexts: {fontName: "Roboto", fontColor: "#000000"},
            buttons: {
                font: {fontName: "Roboto", fontColor: "#ffffff"},
                backgroundColor: "#afc928",
                backgroundOverColor: "#899e20",
                textOverColor: "#ffffff",
                displaySendContactButton: !product,
                displayAddMe: !product,
                displayWallet: true,
                displayFloatingAddMe: false,
            },
            icons: {
                socialNetworkColor: "#36bbed",
                sectionColor: "#afc928",
                actionIconColor: "#afc928",
                actionIconOverColor: "#899e20"
            },
            templateOption: {
                angles: "10",
                anglesColor: "#afc928",
                shadow: true,
                shadowColor: "#949494",
                displayProfilePicture: true,
            },
            applePassBackgroundColor: "#efefef",
            applePassForegroundColor: "#afc928",
            googlePassBackgroundColor: "#efefef",
            googlePassFavIcon: "",
            separator: 3
        },
        templateType: product ? "PRODUCT" : "BUSINESS",
        information: {
            office: {
                address: "",
                address2: "",
                city: "",
                postCode: "",
                state: "",
                country: "",
                phone: "",
                email: "",
                website: ""
            },
            company: {
                name: "",
                address: "",
                address2: "",
                city: "",
                postalCode: "",
                country: "",
                state: "",
                phone: "",
                website: "",
                email: ""
            },
            sections: [],
            medias: { youtubeUrl: "", custom: [] },
            socialNetworks: [],
        },
        companyLogo: "",
        coverPicture: "",
    }

    const returnVcardType = () => {

        if ((preview && !!template) || (preview && !!vcard)) {

            let _template
            let _vcard

            if (vcard) {
                _template = !!template ? template : placeholderTemplateData
                _vcard = vcard
            } else _template = template

            switch (_template.base) {
                case "modern": return <TemplateModern
                    {...{product}}
                    preview
                    template={_template.customisation}
                    templateData={_template}
                    vcardData={!!vcard ? _vcard.user : placeholderVcardData}
                    biography={biography}
                    uuid={""}
                />
                case "classic": return <TemplateClassic
                    {...{product}}
                    preview
                    template={_template.customisation}
                    templateData={_template}
                    vcardData={!!vcard ? _vcard.user : placeholderVcardData}
                    biography={biography}
                    uuid={""}
                />
                case "tower": return <TemplateTower
                    {...{product}}
                    preview
                    template={_template.customisation}
                    templateData={_template}
                    vcardData={!!vcard ? _vcard.user : placeholderVcardData}
                    biography={biography}
                    uuid={""}
                />
                case "minimalist": return <TemplateMinimalist
                    {...{product}}
                    preview
                    template={_template.customisation}
                    templateData={_template}
                    vcardData={!!vcard ? _vcard.user : placeholderVcardData}
                    biography={biography}
                    uuid={""}
                />
                case "service": return <TemplateService
                    {...{product}}
                    preview
                    template={_template.customisation}
                    templateData={_template}
                    vcardData={!!vcard ? _vcard.user : placeholderVcardData}
                    biography={biography}
                    uuid={""}
                />
                default: return
            }
        }

        return <></>
    }

    return (
        <div style={{ maxHeight: "100%"}}>
            {returnVcardType()}
        </div>
    )
}
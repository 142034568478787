import React, {useCallback, useMemo} from 'react';
// dropzone
import {useDropzone} from 'react-dropzone';
// mui
import {Button} from "@mui/material";
import {HideImage} from "@mui/icons-material";
// translation
import {useTranslation} from "react-i18next";

const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function ImageDropzone(props: any) {

    const {t} = useTranslation(["common"])

    const onDrop = useCallback((acceptedFiles: any) => {
        if (props.onDrop) {
            props.onDrop(acceptedFiles);
        }
    }, [props]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/webp': ['.webp'],
        },
        maxFiles: 1,
        maxSize: 2097152
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    if (props.success && props.success !== "") {
        return (
            <div className={"container"}>
                <div style={baseStyle}>
                    <p style={{ textAlign: "center", marginRight: "8px" }}>
                        {t("asset_success")}
                    </p>

                    <Button
                        onClick={props.onClear}
                        size={"small"}
                        variant={"outlined"}
                        color={"secondary"}
                        startIcon={<HideImage />}
                    >
                        {t("clear_asset")}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="container">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p style={{textAlign: "center"}}>{ props.label === undefined ? "Upload" : props.label}</p>
                </div>
            </div>
        </>
    );
}
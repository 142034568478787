import React from "react"
// MUI
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Tooltip,
    Button,
    Select
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// styles
import {styles} from "./styles"
// Components
import {TextField} from "../../utils";

export default function ContactMediasSection(props: any) {

    const {data, setData} = props

    const {t} = useTranslation(["vcardEditor", "common"])

    const [contactMedias, setContactMedias] = React.useState(data.other)

    const contactMediasType = [
        { name: "Discord", index: 0 },
        { name: "Facebook Messenger", index: 1 },
        { name: "Zoom", index: 2 },
        { name: "Skype", index: 3 },
        { name: "WeChat", index: 4 },
        { name: "Signal", index: 5 },
        { name: "Whatsapp", index: 6 },
    ]

    React.useEffect(() => {
        if (props) {
            setContactMedias(data.other)
        }
    }, [props])

    React.useEffect(() => {
        if (contactMedias !== data.other) {
            setData({...data, other: contactMedias})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactMedias])

    const addContactMedia = () => {
        if (!contactMedias) {
            setContactMedias([{ name: "Discord", id: "" }])
            return
        }
        setContactMedias([...contactMedias, { name: "Discord", id: "" }])
    }

    const handleContactMediaType = (index: number, event: any) => {
        let _contactMedias = contactMedias
        _contactMedias.splice(index, 1, { name: event.target.value, id: _contactMedias[index].id})
        setContactMedias([..._contactMedias])
    }

    const handleContactMediaId = (index: number, event: any) => {
        let _contactMedias = contactMedias
        _contactMedias.splice(index, 1, { name: _contactMedias[index].name, id: event.target.value})
        setContactMedias([..._contactMedias])
    }

    const deleteContactMedia = (index: number) => {
        let _contactMedias = contactMedias
        _contactMedias.splice(index, 1)
        setContactMedias([..._contactMedias])
    }

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                <Grid item xs={12}>
                    <Button
                        onClick={addContactMedia}
                        variant={"contained"}
                        color={"secondary"}
                        size={"small"}
                        startIcon={<Add />}
                        disableElevation
                    >
                        {t("contact_media_add")}
                    </Button>
                </Grid>

                {
                    contactMedias && contactMedias.map((socialMedia: any, index: number) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <Paper variant={"outlined"} sx={styles.paper}>
                                    <Grid container justifyContent={"center"} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth size={"small"}>
                                                <InputLabel id={`socialMediaLabel${index}`}>
                                                    {t("contact_media_type")}
                                                </InputLabel>
                                                <Select
                                                    label={t("contact_media_type")}
                                                    labelId={`socialMediaLabel${index}`}
                                                    value={socialMedia.name}
                                                    onChange={(e: any) => handleContactMediaType(index, e)}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 250
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {
                                                        contactMediasType && contactMediasType.map((type: any) => (
                                                            <MenuItem value={type.name} key={type.index}>
                                                                {type.name === "Other" ? t("other") : type.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                fullWidth
                                                size={"small"}
                                                label={t("link")}
                                                value={socialMedia.id}
                                                onChange={(e: any) => handleContactMediaId(index, e)}
                                                error={socialMedia.id === ""}
                                            />
                                        </Grid>
                                        <Grid item xs={'auto'} md={1} textAlign={"center"}>
                                            <Tooltip title={t("contact_media_delete")} placement={"top"} arrow>
                                                <IconButton
                                                    size={"small"}
                                                    color={"error"}
                                                    onClick={() => deleteContactMedia(index)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}
import React from "react"
// mui
import {Paper, Typography} from "@mui/material";
import {YouTube} from "@mui/icons-material";
import {parseYoutubeVideoUrl} from "../../../../../functions/functions";

export default function YoutubeVideoSection(props: any) {

    const {styles, t, templateData, vcardData, preview} = props;

    let youtubeEmbeddingCode: any = null

    if (vcardData.information.medias.youtubeUrl && vcardData.information.medias.youtubeUrl !== "") {
        youtubeEmbeddingCode = parseYoutubeVideoUrl(vcardData.information.medias.youtubeUrl)
    } else if (templateData.information.medias.youtubeUrl && templateData.information.medias.youtubeUrl !== "") {
        youtubeEmbeddingCode = parseYoutubeVideoUrl(templateData.information.medias.youtubeUrl)
    }

    // section only displays out of the Preview mode to avoid enormous calls to youtube API
    if (youtubeEmbeddingCode && !preview) {
        return (
            <>
                <Paper sx={styles.paper}>
                    <Typography component={"h3"} sx={styles.sectionTitles} align={"center"} gutterBottom>
                        <YouTube sx={styles.sectionIcons} />
                        {t("vcard_youtube_video")}
                    </Typography>
                    <iframe width="100%" height="auto"
                            src={`https://www.youtube-nocookie.com/embed/${youtubeEmbeddingCode}`}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </Paper>
            </>
        )
    }

    return <></>
}


const styles = {
    base: {
        p: "32px 16px"
    },
    avatarWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        my: 1
    },
    avatar: {
        margin: "auto",
        width: 81,
        height: 81,
        backgroundColor: "#efefef"
    }
}

export {styles}

import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    label?: string;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, label, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${label}-${index}`}
            aria-labelledby={`${label}-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

export function a11yProps(index: number, label: string) {
    return {
        id: `${label}-${index}`,
        'aria-controls': `${label}-${index}`,
    };
}
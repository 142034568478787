import React from "react"
// mui
import {Paper, Typography} from "@mui/material";
// components
import {SectionCollapse} from "../../index";

export default function Biography(props: any) {

    const {styles, t, vcardData, plain, minimalist, templateData, biography} = props;

    if (biography || vcardData.information.biography) {
        if (minimalist) {
            return (
                <Paper sx={styles.paper}>
                    {!!biography ? <div style={styles.text} dangerouslySetInnerHTML={{__html: biography}}></div> : <Typography sx={styles.text}>{vcardData.information.biography}</Typography>}
                </Paper>
            )
        } else {
            return (
                <Paper sx={styles.paper}>
                    <SectionCollapse
                        type={"biography"}
                        title={templateData.templateType === "PRODUCT" ? t( "product_biography") : t("vcard_biography")}
                        templateType={templateData.templateType}
                        {...{styles, plain, t}}
                    >
                        {!!biography ? <div style={styles.text} dangerouslySetInnerHTML={{__html: biography}} ></div> : <Typography sx={styles.text}>{vcardData.information.biography}</Typography>}
                    </SectionCollapse>
                </Paper>
            )
        }
    }

    return <></>
}
import React from "react"
// mui
import {Avatar, Box, Button, Divider, Paper, Stack, Typography} from "@mui/material";
import {Person, QrCode2} from "@mui/icons-material";
// components
import {VCardExternalLinksMap, VCardSeparator} from "../../index";


export default function MainCard(props: any) {

    const {
        styles,
        t,
        templateData,
        vcardData,
        template,
        preview,
        minimalist,
        noSeparator
    } = props;

    return (
        <Paper sx={{...styles.paper, p: 0, mt: 0}} className={"mainPaper"}>

            <Box>
                <Box sx={styles.banner} />
                {
                    !noSeparator && <VCardSeparator type={template.separator} color={template.backgroundInt.backgroundColor} />
                }
            </Box>

            <Box sx={styles.bannerStack}>
                <Stack>
                    <Avatar
                        sx={styles.avatar}
                        src={vcardData.picture}
                    >
                        {/* FALLBACK */}
                        <Person sx={styles.fallbackAvatar} />
                    </Avatar>
                    <Typography component={"h1"} align={"center"} sx={styles.name}>
                        {(vcardData.title !== "not_displayed" && vcardData.title !== "none" && vcardData.title) && t(vcardData.title)} {vcardData.firstName} {vcardData.lastName}
                    </Typography>
                    <Typography component={"h2"} align={"center"} sx={styles.title}>
                        {vcardData.jobTitle}
                    </Typography>
                    {
                        vcardData.jobTitleAdditional &&
                        <Typography component={"h2"} align={"center"} sx={styles.title}>
                            {vcardData.jobTitleAdditional}
                        </Typography>
                    }
                    {
                        !!vcardData.businessEntity &&
                        <Typography component={"h2"} align={"center"} sx={styles.title}>
                            {vcardData.businessEntity}
                        </Typography>
                    }
                    <Divider sx={styles.mainCardDivider} />
                </Stack>
            </Box>

            <Box>
                <Stack spacing={1}>
                    <Box>
                        <VCardExternalLinksMap
                            templateLinks={templateData.information.socialNetworks}
                            vcardLinks={vcardData.information.socialNetworks}
                            styles={styles}
                            preview={preview}
                        />
                    </Box>
                    {
                        (!minimalist) &&
                        <>
                            <Box sx={{...styles.alignButtonCenter, mb: 3}}>
                                <Button
                                    sx={{...styles.buttons, ...styles.topQRCodeButton}}
                                    startIcon={<QrCode2 />}
                                >
                                    {t("vcard_my_qrcode")}
                                </Button>
                            </Box>

                            <Button
                                fullWidth
                                sx={{...styles.buttons, ...styles.contactButton}}
                            >
                                {t("vcard_contact_me")}
                            </Button>
                            <Button
                                fullWidth
                                size={"large"}
                                sx={{...styles.buttons, ...styles.addMeButton}}
                            >
                                {t("vcard_add_me")}
                            </Button>
                        </>
                    }
                </Stack>
            </Box>
        </Paper>
    )
}
import {TextField as MaterialTextField} from '@mui/material';

type Props = {
    id?: string
    name?: string
    className?: string
    onChange?: any
    value?: any
    sx?: any
    label?: string
    fullWidth?: boolean
    disabled?: boolean
    variant?: 'standard' | 'filled' | 'outlined'
    size?: any
    required?: boolean
    multiline?: boolean
    rows?: string | number
    color?: any
    InputProps?: any,
    error?: boolean,
    helperText?: any
}

export default function TextField(props: Props) {
    const {
        id,
        name,
        className,
        onChange,
        value,
        sx,
        label,
        fullWidth,
        disabled,
        variant,
        size,
        required,
        multiline,
        rows,
        color,
        InputProps,
        error,
        helperText
    } = props;

    return (
        <MaterialTextField
            id={id}
            name={name}
            className={className}
            onChange={onChange}
            value={value}
            sx={sx}
            label={label}
            fullWidth={fullWidth}
            disabled={disabled}
            variant={variant}
            size={size}
            required={required}
            multiline={multiline}
            rows={rows}
            color={color}
            InputProps={InputProps}
            error={error}
            {...{helperText}}
        />
    )
}

import React, {useState} from "react"
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";

export default function BouyguesEntitiesAndPerimeters(props: any) {

    const entitiesList = [
        {
            entity: "BOUYGUES CONSTRUCTION",
            perimeters: [
                { label: "BOUYGUES CONSTRUCTION", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION IT", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION CONCESSIONS", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION MATERIEL", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION PURCHASING", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION AIRPORTS", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "DISTRIMO", template: "577cc9cd-e267-4df0-97c3-a15ae6b91622" },
            ]
        },
        {
            entity: "BOUYGUES BATIMENT FRANCE",
            perimeters: [
                { label: "ACIEROID", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "CYNEO", template: "d31cf359-faa8-4a2d-99b0-c931b34c4e46" },
                { label: "BOUYGUES BATIMENT BELGIUM", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT CENTRE SUD OUEST", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT CONSTRUCTION PRIVEE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT EQUIPEMENTS PUBLICS", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT GRAND OUEST", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT HABITAT RESIDENCIEL", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT HABITAT SOCIAL", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT ILE DE FRANCE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT INDUSTRIE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT NORD EST", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT RENOVATION PRIVEE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT SUD EST", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BREZILLION", template: "f0a0b134-20ae-4879-85b1-acac846d9573" },
                { label: "LINKCITY", template: "b82a733a-7137-42a3-a4ff-18efbb737bd0" },
                { label: "ELAN", template: "505ff824-d581-4cbc-9392-cf6754e137be" },
                { label: "RICHELMI MONACO", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
            ]
        },
        {
            entity: "BOUYGUES BATIMENT INTERNATIONAL",
            perimeters: [
                { label: "AMERICARIBE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "AW EDWARDS", template: "fe89b43c-bbe9-4889-a0ca-bb8c353c6e42" },
                { label: "BOUYGUES BATIMENT ARABE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES BATIMENT INTERNATIONAL", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES THAI", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES TURKMEN", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES UK", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BYMARO", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "DRAGAGES HONG KONG", template: "182748f2-5aa0-47d2-9bf2-492607425c06" },
                { label: "DRAGAGES SINGAPORE", template: "d5cd1288-d597-4076-992a-4c2e8976022c" },
                { label: "KARMAR", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "LOSINGER MARAZZI", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "SETAO", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "VCES", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
            ]
        },
        {
            entity: "BOUYGUES TRAVAUX PUBLICS",
            perimeters: [
                { label: "BOUYGUES CONSTRUCTION AUSTRALIA", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION CANADA", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES CONSTRUCTION EXPERTISES NUCLEAIRES", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES TRAVAUX PUBLICS", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "BOUYGUES TRAVAUX PUBLICS REGIONS FRANCE", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "CBNA", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
                { label: "DRAGAGES HONG KONG", template: "182748f2-5aa0-47d2-9bf2-492607425c06" },
                { label: "DTP", template: "e0bd81be-b725-4ecf-bef4-cce80fe41211" },
                { label: "PRADER LOSINGER", template: "9002c108-65a1-43af-b050-c8affbe68ccb" },
                { label: "SICMA", template: "ec8eb627-6e1d-4e64-9e73-4471a33da576" },
            ]
        },
        {
            entity: "VSL",
            perimeters: [
                { label: "VSL INTERNATIONAL", template: "caf21256-5944-4532-8e8b-148ed490766d" },
                { label: "VSL FRANCE", template: "caf21256-5944-4532-8e8b-148ed490766d" },
            ]
        }
    ]



    const {
        entity, setEntity,
        perimeter, setPerimeter,
        template, setTemplate,
        step, setStep, t
    } = props

    const [localEntity, setLocalEntity] = useState<any>("")

    const handleEntityChange = (val: any) => {
        setEntity(val)
        let fullEntity = entitiesList.filter((ent: any) => ent.entity === val)
        setLocalEntity(fullEntity[0])
        setPerimeter("")
        setTemplate("")
    }

    const handlePerimeterChange = (val: any) => {
        setPerimeter(val)
        let fullPerimeter = localEntity.perimeters.filter((per: any) => per.label === val)
        setTemplate(fullPerimeter[0].template)
    }

    const handleNext = () => {
        setStep(step+1)
    }

    return (
        <Box>
            <Grid container justifyContent={"flex-start"} spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        {t("select_entity_and_perimeter")}
                    </Typography>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id={"entite"}>{t("entity")}</InputLabel>
                        <Select
                            label={t("entity")}
                            labelId={"entite"}
                            fullWidth
                            value={entity}
                            placeholder={t("select_entity")}
                            onChange={(e) => handleEntityChange(e.target.value)}
                        >
                            {entitiesList.map((ent: any) => (
                                <MenuItem value={ent.entity}>{ent.entity}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id={"perimetre"}>{t("perimeter")}</InputLabel>
                        <Select
                            label={t("perimeter")}
                            labelId={"perimetre"}
                            fullWidth
                            disabled={!localEntity || !entity}
                            value={perimeter}
                            placeholder={t("select_perimeter")}
                            onChange={(e) => handlePerimeterChange(e.target.value)}
                        >
                            {!!localEntity.perimeters && localEntity.perimeters.map((per: any) => (<MenuItem value={per.label}>{per.label}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size={"large"}
                        variant={"contained"}
                        disabled={!entity || !perimeter || !template || step !== 0}
                        color={"primary"}
                        onClick={() => handleNext()} endIcon={<ChevronRight />}
                    >
                        {t("next")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

import React from "react"
// mui
import {Paper, Typography} from "@mui/material";
// components
import {SectionAction, SectionCollapse} from "../../index";

export default function PersonalInformation(props: any) {

    const {styles, t, vcardData, plain, defaultExpanded, templateData} = props;

    if (vcardData.information.contact && (
            vcardData.information.contact.phone !== "" ||
            vcardData.information.contact.secondaryPhone !== "" ||
            vcardData.email !== ""
    )) {
        return (
            <Paper sx={styles.paper}>
                <SectionCollapse
                    type={"personal"}
                    title={templateData.templateType === "PRODUCT" ? t( "product_information") : t("vcard_personal_informations")}
                    templateType={templateData.templateType}
                    {...{styles, plain, defaultExpanded, t}}
                >
                    <SectionAction
                        styles={styles}
                        dataName={t("phone")}
                        action={() => {return}}
                        dataContent={vcardData.information.contact.secondaryPhone}
                    />
                    <SectionAction
                        styles={styles}
                        dataName={t("phone")}
                        action={() => {return}}
                        dataContent={vcardData.information.contact.phone}
                    />
                    <SectionAction
                        styles={styles}
                        dataName={t("email")}
                        action={() => {return}}
                        dataContent={vcardData.email}
                    />
                    {
                        (
                            vcardData.information.contact.other &&
                            vcardData.information.contact.other.length > 0
                        ) && vcardData.information.contact.other.map((contact: any, index: number) => (
                            <div key={index}>
                                <Typography sx={styles.actionTitle}>
                                    {contact.name}
                                </Typography>
                                <Typography sx={styles.text}>
                                    <b>{contact.id}</b>
                                </Typography>
                            </div>
                        ))

                    }
                </SectionCollapse>
            </Paper>
        )
    }

    return <></>
}

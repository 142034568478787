import React from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Box, Button,
    ButtonBase, Chip,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {Check, Close, CopyAll, Delete, DriveFileRenameOutline, Edit} from "@mui/icons-material";
// misc
import {capitalizeFirstLetter} from "../../../functions/functions";


export default function TemplateCard(props: any) {

    const {
        name,
        base,
        uuid,
        customisation,
        coverPicture,
        templateType
    } = props.template
    const {t, onDelete, onDuplicate, onRename} = props

    const [editName, setEditName] = React.useState<any>({ editing: false, name: name })

    React.useEffect(() => {
        if (props) { setEditName({ editing: false, name: props.template.name }) }
    }, [props])

    const returnBaseImage = () => {
        switch (base) {
            case "modern":
                return "/assets/templates/thumbnails/modern.jpg";
            case "classic":
                return "/assets/templates/thumbnails/classic.jpg";
            case "tower":
                return "/assets/templates/thumbnails/tower.jpg";
            case "minimalist":
                return "/assets/templates/thumbnails/minimalist.jpg";
            default:
                return undefined;
        }
    }

    const returnTemplateName = () => {
        if (editName.editing) {
            return (
                <>
                    <Grid container justifyContent={"center"} spacing={1}>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                size={"small"}
                                hiddenLabel
                                value={editName.name}
                                onChange={(e) => setEditName({ ...editName, name: e.target.value})}
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }

        return (
            <>
                <Typography variant={"body1"} fontWeight={700} align={"center"}>
                    {name}
                    <Tooltip title={t("rename")} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={() => setEditName({ ...editName, editing: true })}>
                            <DriveFileRenameOutline fontSize={"inherit"} />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </>
        )
    }

    const returnEditionUrl = () => {
        if (templateType === "PRODUCT") return `/template/edit?id=${uuid}&type=product`
        else return `/template/edit?id=${uuid}`
    }

    const styles = {
        base: {
            width: "100%",
            flexDirection: "column",
            borderRadius: "4px",
            transition: "0.2s ease-in-out",
            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            border: "2px solid white",
            minHeight: 450,
            "&:hover": {
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                borderColor: "#afc928",
                "& .previewOpacity": {
                    opacity: "1!important"
                }
            }
        },
        bannerWrapper: {
            width: "100%",
            flexDirection: "column",
            transition: "0.2s ease-in-out",
            "&:hover": {
                filter: "brightness(90%)",
            }
        },
        banner: {
            background: `center / cover no-repeat url('${returnBaseImage()}')`,
            height: 350,
            width: "100%",
            borderRadius: "3px 3px 0 0",
            position: "relative"
        },
        paper: {
            width: "100%",
            p: 2,
            borderRadius: "0 0 4px 4px "
        },
        divider: {
            my: 1
        },
        actionWrapper: {
            width: "100%",
            textAlign: "center"
        },
        colorPinWrapper: {
            position: "absolute",
            bottom: 5,
            right: 5,
            display: "flex",
            flexDirection: "column",
        },
        colorPin: {
            borderRadius: "50%",
            backgroundColor: "white",
            border: "1px solid white"
        },
        previewWrapper: {
            background:
                customisation.backgroundExt.isGradient ?
                    `linear-gradient(${customisation.backgroundExt.gradientColor1}, ${customisation.backgroundExt.gradientColor2})`
                    : customisation.backgroundExt.backgroundColor,
            width: "100%",
            height: 400,
            padding: "0 12px",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            opacity: "0.7",
            transition: "0.25s ease-in-out"
        },
        previewPaper: {
            borderRadius: `${customisation.templateOption.angles}px`,
            border: `1px solid ${customisation.templateOption.anglesColor}`,
            backgroundColor: customisation.backgroundInt.backgroundColor,
            textAlign: "center",
            pb: 2,
            "& img": {
                objectFit: "cover",
                borderRadius: `${customisation.templateOption.angles}px ${customisation.templateOption.angles}px 0 0`
            }
        },
        previewSectionPaper: {
            borderRadius: `${customisation.templateOption.angles}px`,
            backgroundColor: customisation.backgroundInt.backgroundColor,
            borderLeft: base === "classic" ? `5px solid ${customisation.templateOption.anglesColor}` : "inherit",
            textAlign: "center",
            pt: 1,
            pb: 2
        },
        previewBanner: {
            objectFit: "cover"
        },
        previewTitle: {
            fontFamily: customisation.fontNameHolder.fontName,
            textAlign: "center",
            color: customisation.fontNameHolder.fontColor
        },
        previewSection: {
            fontFamily: customisation.fontCardTitles.fontName,
            textAlign: "center",
            color: customisation.fontCardTitles.fontColor,
        },
        previewButton: {
            fontFamily: customisation.buttons.fontName,
            color: `${customisation.buttons.fontColor}!important`,
            backgroundColor: customisation.buttons.backgroundColor,
            "&:hover": {
                backgroundColor: customisation.buttons.backgroundColor,
                boxShadow: "none"
            }
        },
        productChip: {
            position: "absolute",
            top: 10,
            right: 10
        }
    }

    const renderPreview = () => {

        const previewHeader = () => {
            return (
                <>
                    <img src={coverPicture ? coverPicture : "/assets/templates/banners/banner-workspace.jpg"} height={130} width={"100%"} alt={""} />
                    <Typography variant={"h5"} component={"p"} sx={styles.previewTitle}>
                        {name}
                    </Typography>
                </>
            )
        }

        const previewSection = () => {
            return (
                <>
                    <div>
                        <Typography variant={"h6"} component={"p"} sx={styles.previewSection}>
                            {capitalizeFirstLetter(base)}
                        </Typography>
                        <Button sx={styles.previewButton} variant={"contained"} disableElevation>
                            {t("common:edit")}
                        </Button>
                    </div>
                </>
            )
        }

        return (
            <Paper elevation={0} sx={styles.previewWrapper} className={"previewOpacity"}>
                { templateType === "PRODUCT" && <Chip label={"Product"} size={"small"} color={"secondary"} sx={styles.productChip} /> }
                <Grid container spacing={2}>
                    {
                        base === "classic" || base === "modern" ?
                            <>
                                <Grid item xs={12}>
                                    <Paper sx={styles.previewPaper}>
                                        {previewHeader()}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={styles.previewSectionPaper}>
                                        {previewSection()}
                                    </Paper>
                                </Grid>
                            </>
                        :
                            <>
                                <Grid item xs={12}>
                                    <Paper sx={{...styles.previewPaper, height: 296}}>
                                        {previewHeader()}
                                        <Divider sx={{ my: "20px" }} />
                                        {previewSection()}
                                    </Paper>
                                </Grid>
                            </>
                    }
                </Grid>
            </Paper>
        )
    }

    return (
        <Paper sx={styles.base}>
            <ButtonBase component={Link} to={returnEditionUrl()} sx={styles.bannerWrapper}>
                {renderPreview()}
            </ButtonBase>
            <Paper sx={styles.paper} elevation={0}>
                {returnTemplateName()}

                <Divider sx={styles.divider} />

                <Box sx={styles.actionWrapper}>
                    {
                        editName.editing ?
                        <>
                            <IconButton size={"small"} onClick={() => setEditName({ editing: false, name: name })}>
                                <Close fontSize={"inherit"} />
                            </IconButton>
                            <IconButton size={"small"} onClick={() => onRename(editName.name)}>
                                <Check fontSize={"inherit"} />
                            </IconButton>
                        </>
                        :
                        <>
                            <Tooltip title={t("edit_template")} placement={"top"} arrow>
                                <IconButton size={"small"} component={Link} to={returnEditionUrl()}>
                                    <Edit fontSize={"inherit"} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("duplicate_template")} placement={"top"} arrow>
                                <IconButton size={"small"} onClick={onDuplicate}>
                                    <CopyAll fontSize={"small"} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("delete_template")} placement={"top"} arrow>
                                <IconButton size={"small"} onClick={onDelete}>
                                    <Delete fontSize={"inherit"} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </Box>
            </Paper>
        </Paper>
    )
}
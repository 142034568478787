import React from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {Add, Close} from "@mui/icons-material";

export default function NewDepartmentDialog(props: any) {

    const {open, onClose, onNew, disabled, name, onChange, styles, t} = props

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={"sm"}
            fullWidth
        >
            <DialogTitle>
                <Box display={"flex"} alignContent={"center"}>
                    <Box flexGrow={1}>
                        {t("create_department")}
                    </Box>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t("create_department_text")}
                </Typography>
                <br/>
                <TextField
                    value={name}
                    onChange={onChange}
                    label={t("department_name")}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    sx={styles.defaultButton}
                >
                    {t("cancel")}
                </Button>
                <Button
                    onClick={onNew}
                    variant={"contained"}
                    disableElevation
                    startIcon={<Add />}
                    disabled={disabled}
                >
                    {t("create")}
                </Button>

            </DialogActions>
        </Dialog>
    )
}
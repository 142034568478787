import { createRoot } from 'react-dom/client';
// Translation
import './i18n';
// app
import App from './App';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(<App />);


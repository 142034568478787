import React from "react"
// MUI
import {Alert, Collapse, Button, Divider, Grid, Paper, Typography} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
// CSS
import {styles} from "./styles"
// Translation
import {useTranslation} from "react-i18next";
// components
import {DocumentDropzone, TextField} from "../../utils";
// api
import {APIPostFile} from "../../../api/calls";
// context
import {useUser} from "../../../provider/UserProvider";
// regexes
import {isYoutubeUrl, regexValidUrl} from "../../../functions/functions";

const {REACT_APP_DASHBOARD_API_URL} = process.env

export default function LegacyCustomMediasSection(props: any) {

    const {data, setData} = props
    const {t} = useTranslation(["templateEditor", "common"])
    const token = useUser().token

    const [medias, setMedias] = React.useState<any[]>(data.custom)
    // Upload Error
    const [mediaUploadError, setMediaUploadError] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (data.custom && data.custom !== medias) {
            setMedias(data.custom)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    React.useEffect(() => {
        if (medias !== data.custom) {
            setData({...data, custom: medias})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medias])



    const handleAddMedia = () => {
        if (medias !== null) {
            setMedias([
                ...medias,
                {
                    title: "",
                    fileName: "",
                    customUrl: "",
                    mediaGroupName: ""
                }
            ])
        } else {
            setMedias([
                {
                    title: "",
                    fileName: "",
                    customUrl: "",
                    mediaGroupName: ""
                }
            ])
        }
    }

    const handleDeleteMedia = (si: number) => {
        let _custom = medias
        _custom.splice(si, 1)
        setMedias([..._custom])
    }

    const handleMediaTitle = (index: number, event: any) => {
        let _custom = medias
        _custom.splice(index, 1, {
            title: event.target.value,
            fileName: _custom[index].fileName,
            customUrl: _custom[index].customUrl,
            mediaGroupName: _custom[index].mediaGroupName
        })
        setMedias([..._custom])
    }

    const handleMediaCustomUrl = (index: number, event: any) => {
        let _custom = medias
        _custom.splice(index, 1, {
            title: _custom[index].title,
            fileName: "",
            customUrl: event.target.value,
            mediaGroupName: _custom[index].mediaGroupName
        })
        setMedias([..._custom])
    }

    const handleMediaGroupName = (index: number, event: any) => {
        let _custom = medias
        _custom.splice(index, 1, {
            title: _custom[index].title,
            fileName: _custom[index].fileName,
            customUrl: _custom[index].customUrl,
            mediaGroupName: event.target.value
        })
        setMedias([..._custom])
    }

    const handleDocumentUpload = (document: any, section: any) => {
        setMediaUploadError(false)
        APIPostFile<any>(REACT_APP_DASHBOARD_API_URL + "/asset/upload/document?vcard=true", document[0], token)
            .then((data) => {
                if (data.status === 200) {
                    let _custom = medias
                    _custom.splice(section, 1, {
                        title: _custom[section].title,
                        fileName: data.parsedBody.asset_url,
                        customUrl: "",
                        mediaGroupName: _custom[section].mediaGroupName
                    })
                    setMedias([..._custom])
                } else {
                    setMediaUploadError(false)
                }
            })
            .catch(() => {
                setMediaUploadError(false)
            })
    }

    const clearImportedMedia = (section: any) => {
        let _custom = medias
        _custom.splice(section, 1, {
            title: _custom[section].title,
            fileName: "",
            customUrl: "",
            mediaGroupName: _custom[section].mediaGroupName
        })
        setMedias([..._custom])
    }

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                {/* CUSTOM MEDIAS */}
                <Grid item xs={12}>
                    <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                        {t("custom_medias")}
                    </Typography>

                    <Typography variant={"body2"}>
                        {t("custom_medias_intro")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color={"secondary"}
                        startIcon={<Add />}
                        variant={"contained"}
                        disableElevation
                        size={"small"}
                        onClick={handleAddMedia}
                    >
                        {t("custom_media_add")}
                    </Button>
                </Grid>

                {
                    medias && medias.map((media: any, si: number) => {
                        return (
                            <Grid item xs={12} key={si}>
                                <Paper variant={"outlined"} sx={styles.customMediaSectionPaper}>
                                    <Grid container justifyContent={{ xs: "center", md: "space-between" }} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                label={t("name")}
                                                value={media.title}
                                                onChange={(e: any) => handleMediaTitle(si, e)}
                                            />
                                        </Grid>
                                        <Grid item flexDirection={"column"} justifyContent={"center"} alignContent={"center"} display={"flex"}>
                                            <div>
                                                <Button
                                                    onClick={() => handleDeleteMedia(si)}
                                                    size={"small"}
                                                    color={"error"}
                                                    variant={"contained"}
                                                    disableElevation
                                                    startIcon={<Delete />}
                                                >
                                                    {t("custom_media_delete")}
                                                </Button>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                label={t("custom_media_url")}
                                                onChange={(e: any) => handleMediaCustomUrl(si, e)}
                                                value={media.customUrl}
                                                error={!regexValidUrl(media.customUrl)}
                                                helperText={isYoutubeUrl(media.customUrl) ? t("custom_media_youtube_url_helper") : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DocumentDropzone
                                                onDrop={handleDocumentUpload}
                                                section={si}
                                                label={t("common:import_your_files")}
                                                success={media.fileName}
                                                onClear={() => clearImportedMedia(si)}
                                            />
                                            <br/>
                                            <Collapse in={mediaUploadError}>
                                                <Alert severity={"error"}>
                                                    {t("common:file_upload_error")}
                                                </Alert>
                                            </Collapse>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant={"body2"}>
                                                {t("custom_media_group_name_intro")}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t("custom_media_group_name")}
                                                onChange={(e: any) => handleMediaGroupName(si, e)}
                                                value={media.mediaGroupName}
                                            />
                                        </Grid>


                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}
import React from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
// MUI
import {
    Container,
    Grid,
    Paper,
    Tabs,
    Tab,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Alert
} from "@mui/material";
import {ArrowLeft} from "@mui/icons-material";
// CSS
import "./TemplateEditor.css"
// Translation
import {useTranslation} from "react-i18next";
// Components
import {
    TemplateBasePanel,
    TemplateDesignPanel,
    TemplateImagesPanel,
    TemplateInformationsPanel,
    TemplateQRCodePanel
} from "../../components/TemplateEditor";
import TemplateWalletsPanel from "../../components/TemplateEditor/WalletsPanel/TemplateWalletsPanel";
import AppHeader from "../../components/DashboardComponents/Header/Header";
import VcardsProvider from "../../components/Vcards/Provider/VcardsProvider";
// Utils & UI
import {TabPanel, a11yProps} from "../../components/utils";
// Queries
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_TEMPLATE, GET_TEMPLATE, MODIFY_TEMPLATE} from "../../api/calls";
// Types
import {TemplateTypes} from "../../types";
import {enqueueSnackbar} from "notistack";

export default function TemplateEditor() {

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id")
    const name = new URLSearchParams(search).get("name")
    const templateType = new URLSearchParams(search).get("type")
    const redirect = useNavigate()

    const {t} = useTranslation(["templateEditor", "common"]);
    const handleSuccess = (message: any) => enqueueSnackbar(message, { variant: "success", anchorOrigin: { horizontal: "center", vertical: "top" } })


    // Tab management
    const [tabValue, setTabValue] = React.useState<any>(0);
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => { setTabValue(newValue) };
    // Dismount dialog
    const [openDismountDialog, setOpenDismountDialog] = React.useState<boolean>(false)
    // hot save
    const [hotSaveId, setHotSaveId] = React.useState<string | undefined>(undefined)
    // Here we pass default design options to the main template
    const [templateDesign, setTemplateDesign] = React.useState<any>({
        backgroundExt: {
            backgroundColor: "#eeeeee",
            isGradient: false,
            gradientColor1: "#afc928",
            gradientColor2: "#36bbed"
        },
        backgroundInt: {
            backgroundColor: "#ffffff",
            isGradient: false,
            gradientColor1: "",
            gradientColor2: ""
        },
        fontNameHolder: {
            fontName: "Fjalla One",
            fontColor: "#afc928"
        },
        fontCardTitles: {
            fontName: "Fjalla One",
            fontColor: "#000000"
        },
        fontCardTexts: {
            fontName: "Roboto",
            fontColor: "#000000"
        },
        buttons: {
            font: {
                fontName: "Roboto",
                fontColor: "#ffffff"
            },
            backgroundColor: "#afc928",
            backgroundOverColor: "#899e20",
            textOverColor: "#ffffff",
            displaySendContactButton: templateType !== "product",
            displayAddMe: templateType !== "product",
            displayWallet: true,
            displayFloatingAddMe: false,
        },
        icons: {
            socialNetworkColor: "#36bbed",
            sectionColor: "#afc928",
            actionIconColor: "#afc928",
            actionIconOverColor: "#899e20"
        },
        templateOption: {
            angles: "10",
            anglesColor: "#afc928",
            shadow: true,
            shadowColor: "#949494",
            displayProfilePicture: true,
        },
        applePassBackgroundColor: "#efefef",
        applePassForegroundColor: "#afc928",
        googlePassBackgroundColor: "#efefef",
        googlePassFavIcon: "",
        separator: 3
    })
    const [templateData, setTemplateData] = React.useState<any>({
        company: {
            name: "",
            address: "",
            address2: "",
            city: "",
            postalCode: "",
            country: "",
            state: "",
            phone: "",
            website: "",
            email: ""
        },
        office: {
            address: "",
            address2: "",
            city: "",
            postCode: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            website: ""
        },

        // New data set
        sections: [
            {
                name: "",
                profiles: [
                    {
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        title: "none",
                        jobTitle: "",
                        link: "",
                        description: ""
                    }
                ]
            }
        ],

        youtubeVideo: "",
        custom: [],
        socialNetworks: [{ name: "Other", externalUrl: "https://unitag.io" }]
    })
    // Template root
    const [template, setTemplate] = React.useState<TemplateTypes.Template>({
        name: name ? name : "",
        qrcodeTemplateUuid: "",
        qrcodeDomainUuid: "",
        base: "modern",
        customisation: templateDesign,
        templateType: templateType === "product" ? "PRODUCT" : "BUSINESS",
        information: {
            office: templateData.office,
            company: templateData.company,
            sections: templateData.sections,
            medias: {
                youtubeUrl: "",
                custom: templateData?.custom
            },
            socialNetworks: templateData.socialNetworks,
        },
        companyLogo: "",
        coverPicture: "",
    });

    // Editing switch
    const [editing, setEditing] = React.useState<boolean>(false);
    // Loading lock
    const [loadingLock, setLoadingLock] = React.useState<boolean>(false)

    // QUERIES & MUTATIONS
    // Edited template data
    const [getTemplate, {
        error: templateResError,
        loading: templateResLoading,
    }] = useLazyQuery(GET_TEMPLATE, {
        variables: {
            templateUuid: id
        },
        fetchPolicy: "no-cache"
    })
    // Edited template saving
    const [saveEditedTemplate, {
        error: saveEditedTemplateError,
        loading: saveEditedTemplateLoading,
    }] = useMutation(MODIFY_TEMPLATE)
    // savingTemplate
    const [saveNewTemplate, {
        loading: saveNewTemplateLoading,
        error: saveNewTemplateError
    }] = useMutation(CREATE_TEMPLATE)

    // Check if editing a template based on route
    React.useEffect(() => {
        if (!id && !name) {
            redirect("/?tab=design")
        }
        // If ID is detected within the URL, switch to editing
        if (id && id !== "") {
            setEditing(true);
            fetchTemplate(id);
            return;
        }
        // If NAME is detected within the URL, set template name and begin creation process
        if (name) {
            setTemplate({...template, name: name})
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, name])

    // Hydrate template when design is modified
    React.useEffect(() => {
        hydrateTemplate().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDesign, templateData, tabValue])

    const fetchTemplate = (id: any) => {
        getTemplate({
            variables: { templateUuid: id },
        }).then((data: any) => {
            let res = data.data.getTemplate
            setTemplate({
                ...template,
                name: res.name,
                base: res.base,
                companyLogo: res.companyLogo,
                coverPicture: res.coverPicture,
                qrcodeTemplateUuid: res.qrcodeTemplateUuid,
                information: {
                    medias: {
                        custom: res.information.medias.custom,
                        youtubeUrl: res.information.medias.youtubeUrl
                    },
                    sections: res.information.sections,
                    office: res.information.office,
                    company: res.information.company,
                    socialNetworks: res.information.socialNetworks,
                }
            })
            setTemplateDesign({
                ...res.customisation,
                googlePassBackgroundColor: !!res.customisation.googlePassBackgroundColor ? res.customisation.googlePassBackgroundColor : "#afc928"
            })
            setTemplateData({
                ...templateData,
                office: res.information.office,
                company: res.information.company,
                socialNetworks: res.information.socialNetworks,
                sections: res.information.sections,
                custom: res.information.medias.custom,
            })
        })
    }

    const hydrateTemplate = async () => {
        setTemplate({
            ...template,
            customisation: templateDesign,
            information: {
                office: templateData.office,
                company: templateData.company,
                sections: templateData.sections,
                medias: {
                    ...template.information.medias,
                    custom: templateData.custom
                },
                socialNetworks: templateData.socialNetworks
            }
        })
    }

    const saveTemplate = (quit?: string) => {
        setLoadingLock(true)
        // Hydrate main state with substates
        hydrateTemplate().then(() => {
            if (editing) {
                saveEditedTemplate({ variables: {
                    template: {
                        uuid: !!hotSaveId ? hotSaveId : id,
                        ...template
                    }
                    }}).then(() => {
                        if (quit) redirect("/?tab=design&snackbar=template-saved")
                        else handleSuccess(t("template_saved"))
                }).catch(() => {
                    setLoadingLock(false)
                })
                setLoadingLock(false)
                return;
            }

            saveNewTemplate({ variables: { newTemplate: { ...template }}})
                .then((res: any) => {
                    if (quit) redirect("/?tab=design&snackbar=template-created")
                    else {
                        handleSuccess(t("template_saved"))
                        fetchTemplate(res.data.createTemplate.uuid)
                        setEditing(true)
                        setHotSaveId(res.data.createTemplate.uuid)
                    }
                })
                .catch(() => { setLoadingLock(false) })
            setLoadingLock(false)
            return;

        }).catch(() => {
            setLoadingLock(false)
        })
    }

    const renderDismountConfirmationDialog = () => {
        return (
            <Dialog open={openDismountDialog} maxWidth={"md"} fullWidth>
                <DialogTitle>
                    {t("dismount_dialog_title")}
                </DialogTitle>
                <DialogContent>
                    {t("dismount_dialog_content")}
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: "black", mr: 1 }} onClick={() => setOpenDismountDialog(false)}>
                        {t("dismount_continue_creation")}
                    </Button>
                    <Button variant={"contained"} color={"error"} disableElevation component={Link} to={"/?tab=design"}>
                        {t("dismount_give_up_creation")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Container maxWidth={"xl"} className={"wrapper"}>

                <AppHeader
                    paths={[{name: "common:Vcards", path: "/"}]}
                    positionName={editing ? "template_editor" : "template_creation"}
                    title={editing ? "template_editor" : "template_creation"}
                    subTitle={editing ? template.name : ""}
                    disableCreditsSummary
                />
                <Grid container justifyContent={"center"} spacing={3}>

                    {/*  ACTIONS  */}
                    <Grid item xs={12}>
                        <Grid container justifyContent={{ xs: "center", md: "space-between" }}>
                            <Grid item>
                                <Button
                                    onClick={() => setOpenDismountDialog(true)}
                                    variant={"outlined"}
                                    color={"secondary"}
                                    startIcon={<ArrowLeft />}
                                    disabled={loadingLock || saveEditedTemplateLoading || saveNewTemplateLoading || templateResLoading}
                                >
                                    {t("common:back")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    disableElevation
                                    className={"saveButton"}
                                    onClick={() => saveTemplate()}
                                    disabled={loadingLock || saveEditedTemplateLoading || saveNewTemplateLoading || templateResLoading}
                                >
                                    {t("save_template")}
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant={editing ? "outlined" : "contained"}
                                    disableElevation
                                    className={"saveButton"}
                                    onClick={() => saveTemplate("quit")}
                                    disabled={loadingLock || saveEditedTemplateLoading || saveNewTemplateLoading || templateResLoading}
                                >
                                    {t("save_template_and_quit")}
                                </Button>
                            </Grid>

                            {
                                (saveEditedTemplateError || saveNewTemplateError) &&
                                <Grid item xs={12}>
                                    <Alert severity={"error"} sx={{ mt: 2 }}>
                                        {t("template_save_error")}
                                    </Alert>
                                </Grid>
                            }

                            {
                                (templateResError) &&
                                <Grid item xs={12}>
                                    <Alert severity={"error"} sx={{ mt: 2 }}>
                                        {t("template_res_error")}
                                    </Alert>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    {/*  EDITION PANEL  */}
                    <Grid item xs={12} md={8}>
                        <Paper className={"editorPaper"} variant={"outlined"}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                                <Tab label={t("template_base")} {...a11yProps(0, t("template_base"))} />
                                <Tab label={t("template_design")} {...a11yProps(1, t("template_design"))} />
                                <Tab label={t("template_images")} {...a11yProps(2, t("template_images"))} />
                                <Tab label={t("template_informations")} {...a11yProps(3, t("template_informations"))} />
                                <Tab label={t("template_qrcode")} {...a11yProps(4, t("template_qrcode"))} />
                                { templateType !== "product" && <Tab label={t("template_wallets")} {...a11yProps(5, t("template_wallets"))} />}
                            </Tabs>

                            <TabPanel label={t("template_base")} value={tabValue} index={0}>
                                <TemplateBasePanel product={templateType === "product"} {...{template, setTemplate, templateDesign, setTemplateDesign}} />
                            </TabPanel>
                            <TabPanel label={t("template_design")} value={tabValue} index={1}>
                                <TemplateDesignPanel {...{templateDesign, setTemplateDesign}} />
                            </TabPanel>
                            <TabPanel label={t("template_images")} value={tabValue} index={2}>
                                <TemplateImagesPanel {...{template, setTemplate}} />
                            </TabPanel>
                            <TabPanel label={t("template_informations")} value={tabValue} index={3}>
                                <TemplateInformationsPanel
                                    usingProducts={templateType === "product"}
                                    {...{template, setTemplate, templateData, setTemplateData}}
                                />
                            </TabPanel>
                            <TabPanel label={t("template_qrcode")} value={tabValue} index={4}>
                                <TemplateQRCodePanel {...{template, setTemplate, editing}} />
                            </TabPanel>
                            <TabPanel label={t("template_wallets")} value={tabValue} index={5}>
                                <TemplateWalletsPanel {...{template, templateDesign, setTemplateDesign}} />
                            </TabPanel>
                        </Paper>
                    </Grid>

                    {/*  PREVIEW  */}
                    <Grid item xs={12} md={4}>
                        <Paper className={"editorPaper"} variant={"outlined"}>
                            <VcardsProvider
                                {...{t}}
                                preview
                                template={template}
                                loading={templateResLoading}
                                product={templateType === "product"}
                            />
                        </Paper>
                    </Grid>

                </Grid>
            </Container>

            {renderDismountConfirmationDialog()}
        </>
    )
}

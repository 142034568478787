import {Select as MUISelect, FormControl, InputLabel, FormHelperText} from "@mui/material";

interface SelectProps {
    label?: string
    labelId?: string
    children: any

    MenuProps?: any
    onChange?: any
    onClose?: any
    value?: any
    onOpen?: any
    variant?: any
    error?: boolean
    readOnly?: boolean
    disabled?: boolean
    input?: any
    renderValue?: any
    multiple?: boolean
    size?: any
    sx?: any
    fullWidth?: boolean
    placeholder?: any,
    helperText?: any,
}

export default function Select(props: SelectProps) {

    const {
        label,
        labelId,
        error,
        sx,
        input,
        fullWidth,
        multiple,
        MenuProps,
        onClose,
        onOpen,
        placeholder,
        onChange,
        readOnly,
        value,
        renderValue,
        variant,
        disabled,
        children,
        size,
        helperText
    } = props

    return (
        <FormControl fullWidth size={size ? size : "medium"}>
            <InputLabel id={label}>{label}</InputLabel>
            <MUISelect
                {...{
                    label,
                    labelId,
                    error,
                    disabled,
                    onChange,
                    sx,
                    fullWidth,
                    multiple,
                    MenuProps,
                    input,
                    onClose,
                    placeholder,
                    readOnly,
                    value,
                    renderValue,
                    variant,
                    onOpen
                }}
            >
                {children}
            </MUISelect>
            {
                helperText &&
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            }
        </FormControl>
    )
}
import React from "react"
// MUI
import {Grid, IconButton, MenuItem} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
// Components
import Select from "../Select/Select";

export default function Paginator(props: any) {

    const {
        pageNumber, setPageNumber,
        perPage, setPerPage,
        isLastPage,
        t
    } = props

    const handlePageChange = (page: number) => {
        setPageNumber(page)
    }

    return (
        <>
            <Grid item xs={"auto"}>
                <IconButton onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber <= 1}>
                    <ChevronLeft />
                </IconButton>&nbsp;&nbsp;
                page {pageNumber}
                &nbsp;&nbsp;
                <IconButton onClick={() => handlePageChange(pageNumber + 1)} disabled={isLastPage}>
                    <ChevronRight />
                </IconButton>
            </Grid>
            <Grid item xs={"auto"}>
                <Select
                    label={t("per_page")}
                    value={perPage}
                    onChange={(e: any) => setPerPage(e.target.value)}
                    sx={{ minWidth: 150 }}
                    size={"small"}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </Grid>
        </>
    )
}
import React from "react"
import {
    Autocomplete,
    Box,
    Button, createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {Close, Delete} from "@mui/icons-material";
import {styles} from "../../Main/VcardPanel/styles";
import {useLazyQuery} from "@apollo/client";
import {GET_CONTACTS, GET_VCARDS} from "../../../api/calls";
import {ContactTypes} from "../../../types";

export default function DeleteVcardDialog(props: any) {

    const {open, onClose, onDelete, deletionError, deletionLoading, toDelete, t} = props

    const [vcardsAbridged, setVcardsAbridged] = React.useState<any>([])
    const [selectedVcard, setSelectedVcard] = React.useState<any>(undefined)
    // Contacts
    const [contacts, setContacts] = React.useState<ContactTypes.ConactList[]>([])
    const [hasContacts, setHasContacts] = React.useState<boolean>(false)
    // Users autocomplete
    const [autocompleteInput, setAutocompleteInput] = React.useState<any>("")

    const [getVcardsList, {
        data: vcardsListData,
        loading: vcardsListLoading,
        error: vcardsListError
    }] = useLazyQuery(GET_VCARDS, {
        fetchPolicy: "no-cache"
    })

    const [getContactsList, {
        data: contactsData,
        loading: contactsLoading,
    }] = useLazyQuery(GET_CONTACTS, { fetchPolicy: "no-cache" })

    React.useEffect(() => {
       if (open) fetchVcards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    React.useEffect(() => {
        if (!!vcardsListData && vcardsListData.getVcards.values.length > 0) {
            let data = vcardsListData.getVcards.values
            let _vcardsAbridged: any[] = []

            data.forEach((vcard: any) => {
                _vcardsAbridged.push({ uuid: vcard.uuid, name: vcard.user.firstName + " " + vcard.user.lastName})
            })

            setVcardsAbridged(_vcardsAbridged)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vcardsListData])

    React.useEffect(() => {
        getContactsList({ variables: { page: 1, perPage: 100}}).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (contactsData && contacts !== contactsData && !!toDelete) {
            setContacts(contactsData.getContacts.values)

            let _hasContacts = contactsData.getContacts.values.filter((contact: any) => contact.vcardUuid === toDelete)
            if (!!_hasContacts && !!_hasContacts[0]) {
                setHasContacts(true)
            } else {
                setHasContacts(false)
                setSelectedVcard(undefined)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsData, toDelete])

    // Data fetching
    const fetchVcards = () => {
        getVcardsList({
            variables: {
                page: 1,
                perPage: 999,
            }
        }).then()
    }

    // Renders
    const renderTransferSection = () => {

        // Autocomplete specific
        const userFilterOptions = createFilterOptions({
            matchFrom: "any",
            stringify: (option: any) => option.email
        })

        if (vcardsListLoading) return <Typography align={"center"}>{t("loading")}</Typography>
        if (!!vcardsListError) return <Typography align={"center"}>{t("vcards_loading_error")}</Typography>
        if (!hasContacts) return <></>

        return (
            <>
                {
                    (!!vcardsAbridged && vcardsAbridged.length >= 2) ?
                    <Autocomplete
                        value={selectedVcard}
                        onChange={(_e, value) => {
                            if (!value?.uuid || !value) setSelectedVcard(undefined)
                            else setSelectedVcard(value.uuid)
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t("new_contact_holder")}
                        />}
                        renderOption={(props, option) => {
                            if (option.uuid === toDelete) return null
                            return (<Box component={"li"} {...props}>{option.name}</Box>)
                        }}
                        getOptionLabel={(option) => {
                            if (!option || !selectedVcard) return ""
                            if (typeof option === "string") {
                                let _vcard = vcardsAbridged.filter((vcard: any) => vcard.uuid === option)
                                if (!!_vcard) return _vcard[0].name
                                else return ""
                            }
                            return option.name
                        }}
                        noOptionsText={t("no_member_found")}
                        inputValue={autocompleteInput}
                        onInputChange={(_e, value) => {
                            if (!value) setAutocompleteInput("")
                            setAutocompleteInput(value)
                        }}
                        filterOptions={userFilterOptions}
                        options={vcardsAbridged}
                        ListboxProps={{sx: { maxHeight: 200 }}}
                        autoHighlight
                    />
                : <Typography align={"center"} color={"error"}>{t("no_vcards_to_transfer")}</Typography>}
            </>
        )
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
                <DialogTitle>
                    <Box display={"flex"} alignContent={"center"}>
                        <Box flexGrow={1}>
                            <>
                                {t("vcard_deletion_dialog_title")}
                                {deletionError}
                            </>
                        </Box>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={"body2"} sx={{ pb: 2 }}>
                        {t("vcard_deletion_dialog_intro")}
                    </Typography>

                    {renderTransferSection()}
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2}}>
                    <Button onClick={onClose} sx={styles.classicButton} disabled={deletionLoading}>
                        {t("back")}
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"error"}
                        disableElevation
                        onClick={() => onDelete(selectedVcard)}
                        startIcon={<Delete />}
                        disabled={deletionLoading || (!selectedVcard && hasContacts) || vcardsListLoading || contactsLoading}
                    >
                        {t("delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
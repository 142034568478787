import React from "react"
// MUI
import {
    Container,
    Grid,
} from "@mui/material";
// Translation
import {useTranslation} from "react-i18next";
// Components
import {VCardContactForm, VCardQRCodeDialog} from "../../Components";
import {
    Biography,
    CompanyLogo,
    CompanySection, HelpersSection,
    MainCard, MediasSection, MyQrCodeSection,
    OfficeSection,
    PersonalInformation, YoutubeVideoSection
} from "../../Components/Sections";

interface TemplateProps {
    preview?: boolean
    template?: any
    templateData?: any
    vcardData?: any
    adminData?: any
    formType?: any
    uuid?: any
    qrcodeUrl?: any
    product?: boolean
    biography?: any
}

export default function TemplateModern(props: TemplateProps) {

    const {preview, template, vcardData, templateData, uuid, qrcodeUrl, product, biography} = props;
    const {t} = useTranslation(["common"])

    const styles = {

        centeredDisplay: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column"
        },
        globalWrapper: {
            minHeight: "800px",
            py: 5,
            background:
                template.backgroundExt.isGradient ?
                    `linear-gradient(${template.backgroundExt.gradientColor1}, ${template.backgroundExt.gradientColor2})`
                : template.backgroundExt.backgroundColor
        },
        paper: {
            background: template.backgroundInt.backgroundColor,
            borderRadius: `${template.templateOption.angles}px`,
            border: `1px solid ${template.templateOption.anglesColor}`,
            transition: "0.25s ease-in-out",
            p: 3,
            mt: 3,
            boxShadow:
                template.templateOption.shadow ? `0px 0px 10px 1px ${template.templateOption.shadowColor}`
                : "none",
            "&:hover": {
                boxShadow:
                    template.templateOption.shadow ? `0px 0px 10px 5px ${template.templateOption.shadowColor}`
                    : "none"
            },
            "&.mainPaper": {
                mt: 3
            }
        },
        companyLogoWrapper: {
            width: "100%",
            textAlign: "center",
            mb: 3
        },
        companyLogo: {
            width: "auto",
            maxWidth: "100%",
            maxHeight: "110px"
        },
        banner: {
            backgroundImage: templateData.coverPicture ? `url(${templateData.coverPicture})` : product ? "url(/assets/templates/banners/banner-product.png)" : "url(/assets/templates/banners/banner-workspace.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            borderRadius: `${template.templateOption.angles}px ${template.templateOption.angles}px 0 0 `,
            height: 250
        },
        bannerStack: {
            marginTop: template.separator === 5 ? "-225px" : "-105px",
            px: 3,
        },
        avatar: {
            display: template.templateOption.displayProfilePicture === false ? "none" : "flex",
            mx: "auto",
            width: 150,
            height: 150,
            my: 2,
            border: `3px solid ${template.backgroundInt.backgroundColor}`,
            transition: "0.25s ease-in-out",
            "&:hover": {
                boxShadow: "5px 5px 20px rgba(0,0,0,0.3)"
            }
        },
        fallbackAvatar: {
            fontSize: 64
        },
        name: {
            marginTop: template.templateOption.displayProfilePicture === false ? "120px" : "0",
            fontSize: "36px",
            color: template.fontNameHolder.fontColor,
            fontFamily: `${template.fontNameHolder.fontName}, Roboto`,
            overflowWrap: "break-word",
            zIndex: 3
        },
        nameBottom: {
            fontSize: "36px",
            color: template.fontCardTitles.fontColor,
            fontFamily: `${template.fontNameHolder.fontName}, Roboto`,
            overflowWrap: "break-word"
        },
        title: {
            fontSize: "1.25rem",
            color: template.fontCardTexts.fontColor,
            fontFamily: `${template.fontCardTexts.fontName}, Roboto`,
            overflowWrap: "break-word",
            zIndex: 3
        },
        socialMediaIcons: {
            fill: `${template.icons.socialNetworkColor}`,
            fontSize: 24,
            "& path": {
                fill: `${template.icons.socialNetworkColor} !important`
            }
        },
        mainCardDivider: {
            my: 2,
            mx: 4
        },
        buttons: {
            backgroundColor: template.buttons.backgroundColor,
            color: template.buttons.font.fontColor,
            fontFamily: `${template.buttons.font.fontName}, Roboto`,
            "&:hover": {
                backgroundColor: template.buttons.backgroundOverColor,
                color: template.buttons.textOverColor,
            },
        },
        contactButton: {
            display: (template.buttons.displaySendContactButton || template.buttons.displaySendContactButton === null || template.buttons.displaySendContactButton === undefined) ? "inline-flex" : "none"
        },
        addMeButton: {
            display: (template.buttons.displayAddMe || template.buttons.displayAddMe === null || template.buttons.displayAddMe === undefined) ? "inline-flex" : "none",
            borderRadius: `0 0 ${template.templateOption.angles}px ${template.templateOption.angles}px`
        },
        topQRCodeButton: {
            marginBottom: (!template.buttons.displayAddMe) ? "16px!important" : 0
        },
        sectionCollapseDivider: {
            my: 2
        },
        sectionTitles: {
            fontSize: 19,
            color: template.fontCardTitles.fontColor,
            fontFamily: `${template.fontCardTitles.fontName}, Roboto`
        },
        sectionIcons: {
            mr: 1,
            fill: template.icons.sectionColor,
            mb: "-4px"
        },
        sectionAction: {
            my: 1
        },
        actionButtonWrapper: {
            textAlign: "right",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
        },
        actionButton: {
            p: "4px",
            mt: "4px"
        },
        actionButtonSvg: {
            fontSize: "32px",
            fill: template.icons.actionIconColor,
            transition: "0.25s ease-in-out",
            "&:hover": {
                fill: template.icons.actionIconOverColor
            }
        },
        actionTitle: {
            lineHeight: "unset",
            fontFamily: `${template.fontCardTexts.fontName}, Roboto`,
            color: template.fontCardTexts.fontColor
        },
        text: {
            color: template.fontCardTexts.fontColor,
            fontFamily: `${template.fontCardTexts.fontName}, Roboto`,
            wordWrap: "break-word"
        },
        alignButtonCenter: {
            width: "100%",
            textAlign: "center"
        }
    }

    const [showQrCode, setShowQrCode] = React.useState<boolean>(false)
    const [showContactForm, setShowContactForm] = React.useState<boolean>(false)

    const vcardSectionProps = {
        styles: styles,
        t: t,
        templateData: templateData,
        vcardData: vcardData,
        template: template,
        preview: preview
    }

    const vcardResponsiveProps = () => {
        if (preview) return { xs: 11, sm: 8, md: 12,}
        else return { xs: 11, sm: 8, md: 6, lg: 3 }
    }

    return (
        <>
            <Container maxWidth={false} sx={styles.globalWrapper}>
                <Grid container justifyContent={"center"}>
                    <Grid item {...vcardResponsiveProps()}>
                        <CompanyLogo {...vcardSectionProps} />
                        <MainCard {...vcardSectionProps} {...{setShowQrCode, setShowContactForm }} />
                        <Biography {...vcardSectionProps} {...{biography}} />
                        <PersonalInformation defaultExpanded {...vcardSectionProps} />
                        <CompanySection {...vcardSectionProps} />
                        <OfficeSection {...vcardSectionProps} />
                        <HelpersSection {...vcardSectionProps} />
                        <MediasSection {...vcardSectionProps} />
                        <YoutubeVideoSection {...vcardSectionProps} />
                        <MyQrCodeSection {...vcardSectionProps} {...{setShowQrCode}} />
                    </Grid>
                </Grid>
            </Container>

            {/* DIALOGS */}
            <VCardQRCodeDialog
                open={showQrCode}
                onClose={() => setShowQrCode(false)}
                src={qrcodeUrl}
            />

            <VCardContactForm
                open={showContactForm}
                onClose={() => setShowContactForm(false)}
                isSimple={(!preview && templateData && templateData.company.options.contactFormat === "basic")}
                vcardUuid={preview ? "" : uuid}
            />
        </>
    )
}
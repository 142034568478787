import React from "react"
import {Box, Grid, IconButton, Typography} from "@mui/material";
import {ArrowCircleRightOutlined} from "@mui/icons-material";

export default function SectionAction(props: any) {

    const {styles, action, dataName, dataContent, disabled, forceDisplay} = props;

    if (((!dataContent || dataContent === "") && !forceDisplay) || disabled) {
        return null
    }

    return (
        <>
            <Grid container justifyContent={ action ? "space-between" : "flex-start" } sx={styles.sectionAction}>
                <Grid item xs={10} sx={styles.centeredDisplay}>
                    {
                        (dataName && typeof dataName !== "string") ? dataName :
                            <Typography sx={styles.actionTitle}>
                                {dataName}
                            </Typography>
                    }
                    {
                        (dataContent && typeof dataContent !== "string") ? dataContent :
                            <Typography sx={styles.text}>
                                <b>{dataContent}</b>
                            </Typography>
                    }
                </Grid>
                {
                    action &&
                    <Grid item xs={2} sx={styles.actionButtonWrapper}>
                        <Box>
                            <IconButton
                                onClick={action ? action : () => {
                                    return
                                }}
                                aria-label={dataName}
                                sx={styles.actionButton}
                                size={"large"}
                            >
                                <ArrowCircleRightOutlined sx={styles.actionButtonSvg} fontSize={"inherit"} />
                            </IconButton>
                        </Box>
                    </Grid>
                }
            </Grid>
        </>
    )
}
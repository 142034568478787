import React from "react"
// MUI
import {
    Alert,
    FormControl,
    FormControlLabel,
    Grid, List, ListItemButton, ListItemIcon, ListItemText,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
// Components
import {Loading} from "../../utils";
// Translation
import {useTranslation} from "react-i18next";
// Css
import {styles} from "./styles"
// API
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_COMPANY, MODIFY_COMPANY} from "../../../api/calls";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";


export default function AdminVcardsSettingsPanel() {

    const {t} = useTranslation(["administration", "common"])
    const [company, setCompany] = React.useState<any>()

    const [getCompany, {
        data: companyData,
        loading: companyLoading,
        error: companyError
    }] = useLazyQuery(GET_COMPANY, { fetchPolicy: "no-cache" })

    const [modifyCompany, {
        loading: modifyCompanyLoading,
        error: modifyCompanyError
    }] = useMutation(MODIFY_COMPANY)

    React.useEffect(() => {
        getCompany().then()
    }, [])

    React.useEffect(() => {
        if (companyData) setCompany(companyData.getCompany)
    }, [companyData])

    const handleChangeContactFormType = (value: any) => {
        modifyCompany({ variables: {
            company: {
                name: company.name,
                options: { contactFormat: value }
            }
        }}).then(() => getCompany().then())
    }

    const handleChangeContactFormComments = () => {
        modifyCompany({ variables: {
            company: {
                name: company.name,
                options: {contactFormComment: !company.options.contactFormComment}
            }
        }}).then(() => getCompany().then())
    }

    const returnErrorStatus = () => {
        if (companyError || modifyCompanyError) return (
            <Grid item xs={12}>
                <Alert severity={"error"}>
                    {t("form_type_error")}
                </Alert>
            </Grid>
        )
    }

    if (!company) return <Loading />

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "start" }}>

                {returnErrorStatus()}

                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={styles.paper}>
                        <Typography variant={"h6"} component={"p"}>
                            {t("contact_form_format")}
                        </Typography>
                        <Typography variant={"body2"}>
                            {t("contact_form_format_intro")}
                        </Typography>

                        <FormControl disabled={modifyCompanyLoading || companyLoading}>
                            <RadioGroup value={company.options.contactFormat} onChange={(e) => handleChangeContactFormType(e.target.value)}>
                                <FormControlLabel control={<Radio />} label={t("contact_form_simple")} value={"basic"} />
                                <FormControlLabel control={<Radio />} label={t("contact_form_complete")} value={"full"} />
                            </RadioGroup>
                        </FormControl>

                        <List>
                            <ListItemButton
                                onClick={() => handleChangeContactFormComments()}>
                                <ListItemIcon>
                                    {company.options.contactFormComment ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={t("contact_allow_comments")}
                                    secondary={t("contact_allow_comments_subtitle")}
                                />
                            </ListItemButton>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default function returnDefaultTemplate(label: string, usingProducts: boolean) {
    return {
        name: label,
        qrcodeTemplateUuid: "",
        qrcodeDomainUuid: "",
        base: "modern",
        customisation: {
            backgroundExt: {
                backgroundColor: "#eeeeee",
                isGradient: false,
                gradientColor1: "#afc928",
                gradientColor2: "#36bbed"
            },
            backgroundInt: {
                backgroundColor: "#ffffff",
                isGradient: false,
                gradientColor1: "",
                gradientColor2: ""
            },
            fontNameHolder: {
                fontName: "Fjalla One",
                fontColor: "#afc928"
            },
            fontCardTitles: {
                fontName: "Fjalla One",
                fontColor: "#000000"
            },
            fontCardTexts: {
                fontName: "Roboto",
                fontColor: "#000000"
            },
            buttons: {
                font: {
                    fontName: "Roboto",
                    fontColor: "#ffffff"
                },
                backgroundColor: "#afc928",
                backgroundOverColor: "#899e20",
                textOverColor: "#ffffff",
                displaySendContactButton: !usingProducts,
                displayAddMe: !usingProducts,
                displayWallet: true,
                displayFloatingAddMe: false,
            },
            icons: {
                socialNetworkColor: "#36bbed",
                sectionColor: "#afc928",
                actionIconColor: "#afc928",
                actionIconOverColor: "#899e20"
            },
            templateOption: {
                angles: "10",
                anglesColor: "#afc928",
                shadow: true,
                shadowColor: "#949494",
                displayProfilePicture: true,
            },
            applePassBackgroundColor: "#efefef",
            applePassForegroundColor: "#afc928",
            googlePassBackgroundColor: "#efefef",
            googlePassFavIcon: "",
            separator: 3
        },
        templateType: usingProducts ? "PRODUCT" : "BUSINESS",
        information: {
            office: {
                address: "",
                address2: "",
                city: "",
                postCode: "",
                state: "",
                country: "",
                phone: "",
                email: "",
                website: ""
            },
            company: {
                name: "",
                address: "",
                address2: "",
                city: "",
                postalCode: "",
                country: "",
                state: "",
                phone: "",
                website: "",
                email: ""
            },
            sections: [
                {
                    name: "",
                    profiles: [
                        {
                            firstName: "",
                            lastName: "",
                            phone: "",
                            email: "",
                            title: "none",
                            jobTitle: "",
                            link: "",
                            description: ""
                        }
                    ]
                }
            ],
            medias: {
                youtubeUrl: "",
                custom: []
            },
            socialNetworks: [],
        },
        companyLogo: "",
        coverPicture: "",
    }
}
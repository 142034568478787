import React from "react"
// MUI
import {
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Paper, TextField, Tooltip,
    Typography, Alert, Skeleton
} from "@mui/material";
import {Delete, Edit, Add, Check, Close} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// API
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_DEPARTMENT, DELETE_DEPARTMENTS, GET_DEPARTMENTS, MODIFY_DEPARTMENT} from "../../../api/calls";
// Components
import {DeleteDepartmentDialog, NewDepartmentDialog} from "../../Dialogs";

export default function AdminDepartmentsSettingsPanel() {

    const {t} = useTranslation(["administration", "common"])
    // Departments list
    const [departments, setDepartments] = React.useState<any>([])
    const [creating, setCreating] = React.useState<any>({ open: false, newName: "" })
    const [renaming, setRenaming] = React.useState<any>({ id: "", newName: "" })
    const [deleting, setDeleting] = React.useState<any>({ open: false, id: "" })
    // queries
    const [createDepartment, {
        loading: createLoading,
        error: createError
    }] = useMutation(CREATE_DEPARTMENT, { fetchPolicy: "no-cache" })

    const [getDepartments, {
        loading: getLoading,
        error: getError,
        data: departmentsData
    }] = useLazyQuery(GET_DEPARTMENTS, { fetchPolicy: "no-cache" })

    const [deleteDepartment, {
        loading: deleteLoading,
        error: deleteError
    }] = useMutation(DELETE_DEPARTMENTS, { fetchPolicy: "no-cache" })

    const [modifyDepartment, {
        loading: modifyLoading,
        error: modifyError
    }] = useMutation(MODIFY_DEPARTMENT, { fetchPolicy: "no-cache",
        variables: {
            department: {
                uuid: renaming.id,
                name: renaming.newName
            }
        }})

    const styles = {
        paper: { p: 2 },
        dptList: { mt: 4 },
        dptListItem: { border: "1px solid #efefef" },
        renamingField: { minWidth: 350, width: 350, maxWidth: 350 },
        defaultButton: { color: "black", mr: 1 }
    }

    React.useEffect(() => {
        fetchDepartments()
    }, [])

    React.useEffect(() => {
        if (departmentsData) {
            setDepartments(departmentsData.getDepartments.values)
        }
    }, [departmentsData])


    const fetchDepartments = () => {
        // TODO: pagination
        getDepartments({
            variables: {
                page: 1,
                perPage: 10
            }
        }).then()
    }

    const handleCreateDepartment = () => {
        createDepartment({ variables: { newDepartment: { name: creating.newName } } })
            .then(() => {
                setCreating({open: false, newName: ""})
                fetchDepartments()
            })
    }

    const handleRenameDepartment = () => {
        modifyDepartment().then(() => {
            setRenaming({ id: "", newName: ""})
            fetchDepartments()
        })
    }

    const handleDeleteDepartment = () => {
        deleteDepartment({ variables: { departmentUuids: [deleting.id] } })
            .then(() => {
                setDeleting({open: false, id: ""})
                fetchDepartments()
            })
    }

    const returnErrorStatus = () => {
        if (createError || getError || modifyError) {
            return (
                <Grid item xs={12}>
                    <Alert severity={"error"}>
                        {
                            createError ? t("department_create_error") :
                            getError ? t("department_get_error") :
                            modifyError ? t("department_modify_error") : ""
                        }
                    </Alert>
                </Grid>
            )
        }
        return <></>
    }

    return (
        <>
            <Grid container justifyContent={{ xs: "center", md: "start" }} spacing={2}>

                {returnErrorStatus()}

                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={styles.paper}>
                        <Typography variant={"h6"} component={"p"}>
                            {t("department_settings")}
                        </Typography>
                        <Typography>
                            {t("department_settings_intro")}
                        </Typography>
                    </Paper>

                    <Box sx={styles.dptList}>
                        <List>
                            <ListItemButton onClick={() => setCreating({ open: true, newName: "" })} disabled={renaming.id !== ""}>
                                <ListItemAvatar>
                                    <Add color={"primary"} />
                                </ListItemAvatar>
                                <ListItemText primaryTypographyProps={{ color: "primary", fontWeight: 700 }}>
                                    {t("create_department")}
                                </ListItemText>
                            </ListItemButton>
                            {
                                getLoading && [1].map((item: any, index: number) => (
                                    <ListItem key={index}>
                                        <Skeleton width={"100%"} height={50} />
                                    </ListItem>
                                ))
                            }
                            {
                                departments && departments.map((dpt: any, index: number) => (
                                    <ListItem key={index} sx={styles.dptListItem}>
                                        <ListItemText>
                                            {
                                                renaming.id === dpt.uuid ?
                                                    <TextField
                                                        value={renaming.newName}
                                                        onChange={(e) => setRenaming({...renaming, newName: e.target.value})}
                                                        sx={styles.renamingField}
                                                        size={"small"}
                                                    />
                                                :
                                                    <>
                                                        <b>{dpt.name}</b> / {dpt.vcards?.length} {t("vcards")}
                                                    </>
                                            }
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            {
                                                renaming.id === dpt.uuid ?
                                                    <>
                                                        <Tooltip placement={"top"} arrow title={t("rename")}>
                                                            <IconButton onClick={() => handleRenameDepartment()} disabled={renaming.newName === "" || modifyLoading}>
                                                                <Check />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip placement={"top"} arrow title={t("cancel")}>
                                                            <IconButton onClick={() => setRenaming({ id: "", newName: "" })} disabled={modifyLoading}>
                                                                <Close />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                    :
                                                    <>
                                                        <Tooltip placement={"top"} arrow title={t("rename")}>
                                                            <IconButton onClick={() => setRenaming({ id: dpt.uuid, newName: dpt.name })}>
                                                                <Edit />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip placement={"top"} arrow title={t("delete")}>
                                                            <IconButton color={"error"} onClick={() => setDeleting({open: true, id: dpt.uuid})}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                            }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                </Grid>
            </Grid>

            <NewDepartmentDialog
                open={creating.open}
                onClose={() => setCreating({ open: false, newName: "" })}
                onNew={() => handleCreateDepartment()}
                disabled={creating.newName === "" || createLoading}
                name={creating.newName}
                onChange={(e: any) => setCreating({...creating, newName: e.target.value })}
                {...{styles, t}}
            />

            <DeleteDepartmentDialog
                open={deleting.open}
                onClose={() => setDeleting({ open: false, id: "" })}
                onDelete={() => handleDeleteDepartment()}
                loading={deleteLoading}
                error={deleteError}
                {...{styles, t}}
            />
        </>
    )
}

 export const styles = {
     permissionsDialogButton: {
         width: "100%",
         borderRadius: 1
     },
     selectedPermissionsDialogButton: {
         width: "100%",
         border: "1px solid",
         borderColor: "primary.main",
         borderRadius: 1,
         backgroundColor: "#efefef"
     },
     permissionsDialogPaper: {
         width: "100%",
         p: 3,
         backgroundColor: "inherit"
     },
     permissionsDialogText: {
         mb: 2
     },
     classicButton: {
         color: "black",
         mr: 1
     },
     avatar: {
         bgcolor: "secondary.main"
     }
 }

export const styles = {
    customMediaSectionPaper: {
        backgroundColor: "#efefef",
        p: 2,
        mt: 2
    },
    customMediaPaper: {
        p: 2,
    }
}
import React, {useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
// MUI
import {Container, Paper, Grid, Tab, Tabs, AppBar} from "@mui/material";
import {
    AdminPanelSettingsOutlined,
    ContactsOutlined,
    PaletteOutlined,
    RememberMeOutlined
} from "@mui/icons-material";
// Components
import {AdminPanel, ContactsPanel, TemplatesPanel, VcardPanel} from "../../components/Main";
import {AppHeader} from "../../components/DashboardComponents";
// Utils and UI
import {a11yProps, TabPanel, Snackbar} from "../../components/utils";
// Translation
import {useTranslation} from "react-i18next";
// CSS
import "./Main.css"
// Context
import {useUser} from "../../provider/UserProvider";
import {useModule} from "../../provider/ClientModuleProvider";
import BouyguesMain from "../Modules/Bouygues/BouyguesMain";
import Bouygues from "../Modules/Bouygues/Bouygues";
import {useTour} from "../../provider/TourProvider";

export default function Main() {

    const {t} = useTranslation(["common"])
    const {onboardingTour} = useTour()
    const { search } = useLocation()
    // User
    const {user, userRoles, credits} = useUser()
    const userType = user.organisation_role
    // Modules
    const {usingModule, usingProducts} = useModule()
    // Tab management
    const [tabValue, setTabValue] = useState<number>(0);
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => { setTabValue(newValue) };

    useEffect(() => {
        handleTabQuery()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        if (!!onboardingTour && onboardingTour === 1 && !!user && userType !== userRoles.member) {
            setTabValue(1)
        }
    }, [onboardingTour, user]);

    const handleTabQuery = () => {
        if (search) {
            const query = new URLSearchParams(search)
            const _tab = query.get("tab")
            if (_tab && userRoles) {
                if (userType === userRoles.owner) {
                    switch (_tab) {
                        case "vcards": setTabValue(0); return;
                        case "design": setTabValue(1); return;
                        case "contacts": setTabValue(2); return;
                        case "admin": setTabValue(3); return;
                        default: return;
                    }
                } else if (userType === userRoles.admin) {
                    switch (_tab) {
                        case "vcards": setTabValue(0); return;
                        case "design": setTabValue(1); return;
                        case "contacts": setTabValue(2); return;
                        default: return;
                    }
                } else {
                    switch (_tab) {
                        case "vcards": setTabValue(0); return;
                        case "contacts": setTabValue(1); return;
                        default: return;
                    }
                }
            }
        }
    }

    const renderAppContent = () => {

        const renderVcardPro = () => {

            const returnTabs = () => {

                if (userType === userRoles.owner) {
                    return (
                        <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                            <Tab
                                label={t("menu_vcard")}
                                icon={<RememberMeOutlined className={"mainTabIcon"} />}
                                {...a11yProps(0, t("menu_vcard"))}
                            />
                            <Tab
                                label={t("menu_templates")}
                                icon={<PaletteOutlined className={"mainTabIcon"} />}
                                {...a11yProps(1, t("menu_templates"))}
                            />
                            <Tab
                                disabled={usingProducts && !credits}
                                label={t("menu_contacts")}
                                icon={<ContactsOutlined className={"mainTabIcon"}/>}
                                {...a11yProps(2, t("menu_contacts"))}
                            />
                            <Tab
                                label={t("menu_admin")}
                                icon={<AdminPanelSettingsOutlined className={"mainTabIcon"} />}
                                {...a11yProps(3, t("menu_admin"))}
                            />
                        </Tabs>
                    )
                } else if (userType === userRoles.admin) {
                    return (
                        <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                            <Tab
                                label={t("menu_vcard")}
                                icon={<RememberMeOutlined className={"mainTabIcon"} />}
                                {...a11yProps(0, t("menu_vcard"))}
                            />
                            <Tab
                                label={t("menu_templates")}
                                icon={<PaletteOutlined className={"mainTabIcon"} />}
                                {...a11yProps(1, t("menu_templates"))}
                            />
                            <Tab
                                disabled={usingProducts && !credits}
                                label={t("menu_contacts")}
                                icon={<ContactsOutlined className={"mainTabIcon"}/>}
                                {...a11yProps(2, t("menu_contacts"))}
                            />
                        </Tabs>
                    )
                } else {
                    return (
                        <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                            <Tab
                                label={t("menu_vcard")}
                                icon={<RememberMeOutlined className={"mainTabIcon"} />}
                                {...a11yProps(0, t("menu_vcard"))}
                            />
                            <Tab
                                disabled={usingProducts && !credits}
                                label={t("menu_contacts")}
                                icon={<ContactsOutlined className={"mainTabIcon"}/>}
                                {...a11yProps(1, t("menu_contacts"))}
                            />
                        </Tabs>
                    )
                }
            }

            return (
                <Grid container justifyContent={"center"}>

                    <Grid item xs={12}>
                        <Paper variant={"outlined"} className={"menuPaper"}>
                            <AppBar position={"static"} color={"transparent"} elevation={0}>
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item xs={12} md={10}>
                                        {returnTabs()}
                                    </Grid>
                                </Grid>
                            </AppBar>

                            {
                                (userType === userRoles.owner) ?
                                    <>
                                        <TabPanel label={t("menu_vcard")} index={0} value={tabValue}>
                                            <VcardPanel />
                                        </TabPanel>
                                        <TabPanel label={t("menu_templates")} index={1} value={tabValue}>
                                            <TemplatesPanel />
                                        </TabPanel>
                                        <TabPanel label={t("menu_contacts")} index={2} value={tabValue}>
                                            <ContactsPanel />
                                        </TabPanel>
                                        <TabPanel label={t("menu_admin")} index={3} value={tabValue}>
                                            <AdminPanel />
                                        </TabPanel>
                                    </>
                                    : (userType === userRoles.admin) ?
                                        <>
                                            <>
                                                <TabPanel label={t("menu_vcard")} index={0} value={tabValue}>
                                                    <VcardPanel />
                                                </TabPanel>
                                                <TabPanel label={t("menu_templates")} index={1} value={tabValue}>
                                                    <TemplatesPanel />
                                                </TabPanel>
                                                <TabPanel label={t("menu_contacts")} index={2} value={tabValue}>
                                                    <ContactsPanel />
                                                </TabPanel>
                                            </>
                                        </>
                                        :
                                        <>
                                            <TabPanel label={t("menu_vcard")} index={0} value={tabValue}>
                                                <VcardPanel isMember />
                                            </TabPanel>
                                            <TabPanel label={t("menu_contacts")} index={1} value={tabValue}>
                                                <ContactsPanel />
                                            </TabPanel>
                                        </>
                            }

                        </Paper>
                    </Grid>
                </Grid>
            )
        }

        const renderBouyguesModule = () => {

            const returnBouyguesTabs = () => {

                if (Bouygues().isAdmin(user.uuid)) {
                    return (
                        <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                            <Tab
                                label={t("command")}
                                icon={<RememberMeOutlined className={"mainTabIcon"} />}
                                {...a11yProps(0, t("menu_buy"))}
                            />
                            <Tab
                                label={t("menu_vcard")}
                                icon={<RememberMeOutlined className={"mainTabIcon"} />}
                                {...a11yProps(1, t("menu_vcard"))}
                            />
                            <Tab
                                label={t("menu_templates")}
                                icon={<PaletteOutlined className={"mainTabIcon"} />}
                                {...a11yProps(2, t("menu_templates"))}
                            />
                            <Tab
                                label={t("menu_contacts")}
                                icon={<ContactsOutlined className={"mainTabIcon"}/>}
                                {...a11yProps(3, t("menu_contacts"))}
                            />
                            <Tab
                                label={t("menu_admin")}
                                icon={<AdminPanelSettingsOutlined className={"mainTabIcon"} />}
                                {...a11yProps(4, t("menu_admin"))}
                            />
                        </Tabs>
                    )
                }

                return (
                    <Tabs value={tabValue} onChange={handleChange} aria-label="Vcard menus">
                        <Tab
                            label={"Commander"}
                            icon={<RememberMeOutlined className={"mainTabIcon"} />}
                            {...a11yProps(0, t("menu_buy"))}
                        />
                        <Tab
                            label={t("menu_vcard")}
                            icon={<RememberMeOutlined className={"mainTabIcon"} />}
                            {...a11yProps(1, t("menu_vcard"))}
                        />
                        <Tab
                            label={t("menu_contacts")}
                            icon={<ContactsOutlined className={"mainTabIcon"}/>}
                            {...a11yProps(3, t("menu_contacts"))}
                        />
                    </Tabs>
                )

            }

            return (
                <>
                    <Grid container justifyContent={"center"}>

                        <Grid item xs={12}>
                            <Paper variant={"outlined"} className={"menuPaper"}>
                                <AppBar position={"static"} color={"transparent"} elevation={0}>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item xs={12} md={10}>
                                            {returnBouyguesTabs()}
                                        </Grid>
                                    </Grid>
                                </AppBar>

                                {
                                    Bouygues().isAdmin(user.uuid) ?
                                        <>
                                            <TabPanel label={"Commander"} index={0} value={tabValue}>
                                                <BouyguesMain />
                                            </TabPanel>
                                            <TabPanel label={t("menu_vcard")} index={1} value={tabValue}>
                                                <VcardPanel />
                                            </TabPanel>
                                            <TabPanel label={t("menu_templates")} index={2} value={tabValue}>
                                                <TemplatesPanel />
                                            </TabPanel>
                                            <TabPanel label={t("menu_contacts")} index={3} value={tabValue}>
                                                <ContactsPanel />
                                            </TabPanel>
                                            <TabPanel label={t("menu_admin")} index={4} value={tabValue}>
                                                <AdminPanel />
                                            </TabPanel>
                                        </>
                                        :
                                        <>
                                            <TabPanel label={"Commander"} index={0} value={tabValue}>
                                                <BouyguesMain />
                                            </TabPanel>
                                            <TabPanel label={t("menu_vcard")} index={1} value={tabValue}>
                                                <VcardPanel isMember={user.organisation_role === userRoles.member} />
                                            </TabPanel>
                                            <TabPanel label={t("menu_contacts")} index={2} value={tabValue}>
                                                <ContactsPanel />
                                            </TabPanel>
                                        </>
                                }

                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )
        }

        switch(usingModule.module) {
            case "bouygues": return renderBouyguesModule()
            default: return renderVcardPro()
        }
    }

    return (
        <>
            <Container maxWidth={"xl"} className={"mainWrapper"}>

                <AppHeader
                    paths={[]}
                    positionName={"vCards"}
                    title={"vCards"}
                    subTitle={"application_menu"}
                    docComingSoon
                    docLabel={"vcards_main_doc_label"}
                    docLink={`${process.env.NEXT_PUBLIC_DOC_URL}/vcards/introduction`}
                />

                {renderAppContent()}

            </Container>

            <Snackbar />
        </>
    )
}

import administration from './administration.json'
import common from './common.json'
import contacts from './contacts.json'
import vcard from  './vcard.json';
import templateEditor from './templateEditor.json'
import vcardEditor from './vcardEditor.json'
import contactEditor from "./contactEditor.json"
import bouygues from "./bouygues.json"

const dico = {
    administration: administration,
    common: common,
    contacts: contacts,
    vcard: vcard,
    templateEditor: templateEditor,
    vcardEditor: vcardEditor,
    contactEditor: contactEditor,
    bouygues: bouygues
}

export default dico
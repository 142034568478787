import React from "react"
// MUI
import {
    Alert,
    Box,
    Collapse,
    Divider,
    Grid,
    Typography
} from "@mui/material";
// Translation
import {useTranslation} from "react-i18next";
// CSS
import "./TemplateImagesPanel.css"
// API
import {APIPostFile} from "../../../api/calls";
// Components
import ImageDropzone from "../../utils/Dropzone/ImageDropzone";
// Provider
import {useUser} from "../../../provider/UserProvider";

const {REACT_APP_DASHBOARD_API_URL} = process.env

export default function TemplateImagesPanel(props: any) {

    const {template, setTemplate} = props;
    const token = useUser().token
    const [uploadBannerError, setUploadBannerError] = React.useState<boolean>(false)
    const [uploadLogoError, setUploadLogoError] = React.useState<boolean>(false)

    const {t} = useTranslation(["templateEditor", "common"])

    // Image management
    const handleBannerUpload = (imageDrop: any) => {
        setUploadBannerError(false)
        APIPostFile<any>(REACT_APP_DASHBOARD_API_URL + "/asset/upload/image?vcard=true", imageDrop[0], token)
            .then((data) => {
                if (!data || data.status !== 200) {
                    setUploadBannerError(true)
                } else {
                    setTemplate({...template, coverPicture: data.parsedBody.asset_url})
                }
            })
            .catch(() => {
                setUploadBannerError(true)
            })
    }

    const handleLogoUpload = (imageDrop: any) => {
        setUploadLogoError(false)
        APIPostFile<any>(REACT_APP_DASHBOARD_API_URL + "/asset/upload/image?vcard=true", imageDrop[0], token)
            .then((data) => {
                if (!data || data.status !== 200) {
                    setUploadLogoError(true)
                } else {
                    setTemplate({...template, companyLogo: data.parsedBody.asset_url})
                }
            })
            .catch(() => {
                setUploadLogoError(true)
            })
    }

    const clearCoverPicture = () => {
        setTemplate({...template, coverPicture: ""})
    }

    const clearCompanyLogo = () => {
        setTemplate({...template, companyLogo: ""})
    }

    const renderBannerSection = () => {

        return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"h6"} component={"p"} color={"secondary"}>
                        {t("banner_image")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={"body2"}>
                        {t("banner_image_helper_text")}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                    <ImageDropzone
                        onDrop={handleBannerUpload}
                        label={t("common:import_your_files")}
                        success={template.coverPicture}
                        onClear={() => clearCoverPicture()}
                    />
                    <br/>
                    <Collapse in={uploadBannerError}>
                        <Alert severity={"error"}>
                            {t("common:file_upload_error")}
                        </Alert>
                    </Collapse>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </>
        )
    }

    const renderLogoSection = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"h6"} component={"p"} color={"secondary"}>
                        {t("logo_image")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={"body2"}>
                        {t("logo_image_helper_text")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>

                    <ImageDropzone
                        onDrop={handleLogoUpload}
                        label={t("common:import_your_files")}
                        success={template.companyLogo}
                        onClear={() => clearCompanyLogo()}
                    />
                    <br/>
                    <Collapse in={uploadLogoError}>
                        <Alert severity={"error"}>
                            {t("common:file_upload_error")}
                        </Alert>
                    </Collapse>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Box className={"imagesBaseWrapper"}>
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={3}>
                    {renderBannerSection()}
                    {renderLogoSection()}
                </Grid>
            </Box>
        </>
    )
}

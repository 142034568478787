import React, {createContext, useContext, useEffect} from "react"
// Apollo
import {ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
// JWT
import jwtDecode from "jwt-decode";
import {onError} from "@apollo/client/link/error";

const {
    REACT_APP_API_URL,
    REACT_APP_DASHBOARD_URL
} = process.env

const JWTContext = createContext<any>(null)

export const useClient = () => useContext(JWTContext)

export function APIProvider({ children }: any) {

    const [token, setToken] = React.useState<any>("")
    const [parsedToken, setParsedToken] = React.useState<any>(null)

    useEffect(() => {
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (token !== "") setParsedToken(jwtDecode(token))
    }, [token])

    useEffect(() => {
        if (!!parsedToken) {
            // convert dates to unix timestamp format
            let unixStartDate = Date.now() / 1000
            let unixDuration = parsedToken.exp - unixStartDate
            // convert total unix time to vanilla
            let millisecondsDuration = unixDuration * 1000
            setTimeout(refreshToken, millisecondsDuration + 10)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedToken])

    const refreshToken = () => {
        // Refresh message from dashboard
        window.top?.postMessage("refreshToken", `${REACT_APP_DASHBOARD_URL}`)
        // Treat message from dashboard
        window.addEventListener("message", messageHandler)
    }

    const messageHandler = (event: any) => {
        // we only want messages from dashboard
        if (event.origin !== `${REACT_APP_DASHBOARD_URL}`) return
        if (typeof event.data === 'string') {
            setToken(event.data)
            // remove event listener to prevent stacking
            window.removeEventListener("message", () => {
                return
            })
        }
    }

    // Apollo client initialisation
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        if (networkError) console.error(`[Network error]: ${networkError}`);
    });

    const httpLink = createHttpLink({
        uri: `${REACT_APP_API_URL}/vcards`,
    });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `${token}` : "",
            }
        }
    });

    const client: any = new ApolloClient({
        uri: `${REACT_APP_API_URL}/vcards`,
        cache: new InMemoryCache(),
        link: from([errorLink, authLink.concat(httpLink)]) ,
    });

    return (
        <ApolloProvider client={client}>
            <JWTContext.Provider value={{token}}>
                {children}
            </JWTContext.Provider>
        </ApolloProvider>

    )
}
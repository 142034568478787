import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
// TRANSLATION FILES (FR)
import indexFr from "./locales/fr"
import indexEs from "./locales/es"
import indexEn from "./locales/en"

// Namespaces linking with imported JSON files
const resources = {
    es: indexEs,
    en: indexEn,
    fr: indexFr
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            'en-EN': ['en'],
            'es-ES': ['es'],
            'fr-FR': ['en']
        },
        debug: false,
        interpolation: {
            // may not be useful with React as it escapes by default
            escapeValue: false,
        },
        resources,
        defaultNS: 'common',
        fallbackNS: 'common',
    }).then();


import React, {createContext, useContext, useState, useEffect} from "react"
import {useUser} from "./UserProvider";

const ModuleContext = createContext<any>(null)

export const useModule = () => useContext(ModuleContext)

export function ModuleProvider({children}: any) {

    const {user, productCredits} = useUser()

    const [usingModule, setUsingModule] = useState<any>({ orgUuid: null, module: null, role: null })
    const [usingProducts, setUsingProducts] = useState<boolean>(false)

    const specificClientModules = [
        // bouygues
        { orgUuid: "41b71e89-ad97-4707-a40a-606612fe592a", module: "bouygues", role: "" },
        // dev uuid
        // { orgUuid: "918cef6e-2141-4a4e-8f89-88721dea518e", module: "bouygues", role: "" }
    ]



    useEffect(() => {
        if (!!productCredits && (productCredits.credits_bought > 0 || productCredits.credits_left > 0)) setUsingProducts(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCredits])

    // fetches info from UserProvider
    useEffect(() => {
        // Check if this organisation has the access to a specific module
        if (!!user) getSpecificClientModule()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const getSpecificClientModule = () => {
        // WARNING: only checks for current organisation
        // will not manage sub-orgs - if you want to add this feature, start here
        let specificClientModule = specificClientModules.filter((mod: any) => mod.orgUuid === user.org)
        // Checking module display conditions
        if (!!specificClientModule[0]) {
            if (specificClientModule[0].role === "" || (specificClientModule[0].role !== "" && specificClientModule[0].role.toUpperCase() === user.organisation_role)) {
                setUsingModule({ orgUuid: specificClientModule[0].orgUuid, module: specificClientModule[0].module, role: user.organisation_role })
            }
        }
    }

    return (
        <ModuleContext.Provider value={{
            usingModule, usingProducts, setUsingProducts
        }}>
            {children}
        </ModuleContext.Provider>
    )
}